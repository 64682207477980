import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import AccountPage from '../features_app/dashboard/account/AccountPage';
import HomeDashboard from '../features_app/dashboard/home/HomeDashboard';
import CreateProducts from '../features_app/dashboard/products/create/CreateNewProducts';
import MainProductPage from '../features_app/dashboard/products/MainProductPage';
import ProfilePage from '../features_app/dashboard/profile/ProfilePage';
import DashboardLayout from '../utilities/DashboardLayout';
import SettingPage from '../features_app/dashboard/setting/SettingPage'
import DetailProducts from '../features_app/dashboard/products/detail/DetailProducts';
import UpdateProductPage from '../features_app/dashboard/products/update/UpdateProductPage';
import LoginPage from '../features_app/auth/login/LoginPage';
import ErrorPage from '../features_app/error404/ErrorPage';
import ForgotPage from '../features_app/auth/forgot/ForgotPage';
import PropertyType from '../features_app/dashboard/MasterData/property_type/PropertyType';
import PropertyTitle from '../features_app/dashboard/MasterData/property_title/PropertyTitle';
import UserAgents from '../features_app/dashboard/DataUsers/agents/UserAgents';
import UserBuyers from '../features_app/dashboard/DataUsers/buyers/UserBuyers';
import UserAdmins from '../features_app/dashboard/DataUsers/admins/UserAdmins';
import ExtraFacilities from '../features_app/dashboard/MasterData/extra_facilities/ExtraFacilities';
import MasterIndikator from '../features_app/dashboard/Scraping/master_indikator/MasterIndikator';
import ScrapingHouse from '../features_app/dashboard/Scraping/scraping_house/ScrapingHouse';
import ScrapingApartmet from '../features_app/dashboard/Scraping/scraping_apartment/ScrapingApartmet';
import FollowUpHouse from '../features_app/dashboard/Scraping/followup_house/FollowUpHouse';
import FollowUpApartment from '../features_app/dashboard/Scraping/followup_apartment/FollowUpApartment';
import ScrapingLand from '../features_app/dashboard/Scraping/scraping_land/ScrapingLand';
import UserReferral from '../features_app/dashboard/DataUsers/referral/UserReferral';
import WithdrawPage from '../features_app/dashboard/withdraw/WithdrawPage';
import ProductsAutoPost from '../features_app/dashboard/products/ProductsAutoPost';
import ScrapingAgents from '../features_app/dashboard/Scraping/agents/ScrapingAgents';
import Statistic from '../features_app/dashboard/statistic/Statistic';
import StatisticDetail from '../features_app/dashboard/statistic/StatisticDetail';
import Blogs from '../features_app/dashboard/Blog/Blogs';
import Survey from '../features_app/dashboard/Survey/Survey';
import BIChecking from '../features_app/dashboard/BiChecking/BIChecking';
import RegisterPage from '../features_app/auth/register/RegisterPage';
import CarikanProperti from '../features_app/dashboard/CarikanProperti/CarikanProperti';
import Leads from '../features_app/dashboard/Leads/Leads';

function AppRoute() {
  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={LoginPage} />
          <Route exact path="/register" component={RegisterPage} />
          <Route exact path="/forgot-password" component={ForgotPage} />
          <DashboardLayout exact path="/dashboard" component={HomeDashboard} />
          <DashboardLayout exact path="/dashboard/statistic" component={Statistic} />
          <DashboardLayout exact path="/dashboard/statistic/:name" component={StatisticDetail} />
          <DashboardLayout exact path="/dashboard/products" component={MainProductPage} />
          <DashboardLayout exact path="/dashboard/auto-post" component={ProductsAutoPost} />
          <DashboardLayout exact path="/dashboard/create-product" component={CreateProducts} />
          <DashboardLayout exact path="/dashboard/update-product/:slug" component={UpdateProductPage} />
          <DashboardLayout exact path="/dashboard/detail-product/:slug" component={DetailProducts} />
          <DashboardLayout exact path="/dashboard/profile" component={ProfilePage} />
          <DashboardLayout exact path="/dashboard/account" component={AccountPage} />
          <DashboardLayout exact path="/dashboard/setting" component={SettingPage} />
          <DashboardLayout exact path="/dashboard/property-type" component={PropertyType} />
          <DashboardLayout exact path="/dashboard/property-title" component={PropertyTitle} />
          <DashboardLayout exact path="/dashboard/extra-facilities" component={ExtraFacilities} />
          <DashboardLayout exact path="/dashboard/user-agents" component={UserAgents} />
          <DashboardLayout exact path="/dashboard/user-buyers" component={UserBuyers} />
          <DashboardLayout exact path="/dashboard/user-admins" component={UserAdmins} />
          <DashboardLayout exact path="/dashboard/user-referral" component={UserReferral} />
          <DashboardLayout exact path="/dashboard/scraping-indikator" component={MasterIndikator} />
          <DashboardLayout exact path="/dashboard/scraping-house" component={ScrapingHouse} />
          <DashboardLayout exact path="/dashboard/scraping-land" component={ScrapingLand} />
          <DashboardLayout exact path="/dashboard/scraping-apartement" component={ScrapingApartmet} />
          <DashboardLayout exact path="/dashboard/proceed-house" component={FollowUpHouse} />
          <DashboardLayout exact path="/dashboard/proceed-apartment" component={FollowUpApartment} />
          <DashboardLayout exact path="/dashboard/scraping-agent" component={ScrapingAgents} />
          <DashboardLayout exact path="/dashboard/blogs" component={Blogs} />
          <DashboardLayout exact path="/dashboard/bi-checking" component={BIChecking} />
          <DashboardLayout exact path="/dashboard/carikan-properti" component={CarikanProperti} />
          <DashboardLayout exact path="/dashboard/survey" component={Survey} />
          <DashboardLayout exact path="/dashboard/leads" component={Leads} />
          <DashboardLayout exact path="/dashboard/withdraw-referral" component={WithdrawPage} />
          <Route exact path="*" component={ErrorPage} />

        </Switch>
      </BrowserRouter>
    </div>
  )
}

export default AppRoute;
