import { createSlice } from '@reduxjs/toolkit';
import swal from 'sweetalert';
import { getAllScrapingHouse, postProceedHouse } from './reducers/scrapingLandReducers';


const initialState: any = {
  data: [],
  data_default : [],
  data_loaded: false,
  loading : false,
  create : false,
  loading_create : false,
  proceed : false, 
  loading_proceed: false
};

export const scrapingHouseSlice = createSlice({
  name: 'house-scraping',
  initialState,
  reducers: {},
  extraReducers: {
    //   fetch title
    [getAllScrapingHouse.pending.type] : (state) => {
        state.loading = true
        state.create = false
        state.data_loaded = false
    },
    [getAllScrapingHouse.fulfilled.type] : (state, action) => {
        state.loading = false
        state.data = action.payload.data
        state.data_default = action.payload.data
        state.data_loaded = true
    },
    [getAllScrapingHouse.rejected.type] : (state, action) => {
        state.loading = false
        swal('Error', `${action.payload}`, 'error')
    },

     //   post proceed
    [postProceedHouse.pending.type] : (state) => {
      state.loading_proceed = true
      state.proceed = false
    },
    [postProceedHouse.fulfilled.type] : (state, action) => {
        state.loading_proceed = false
        state.proceed = action.payload.data
        swal('Success', `${action.payload.message}`, 'success')
    },
    [postProceedHouse.rejected.type] : (state, action) => {
        state.loading_proceed = false
        swal('Error', `${action.payload}`, 'error')
    }
  }
});

export default scrapingHouseSlice.reducer;
