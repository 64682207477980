import { createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios'

export const getAllScrapingHouseProceed = createAsyncThunk(
    'house-proceed-scraping/fetch', 
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await Axios.get(`${process.env.REACT_APP_API_DEV}/house-processed`,{
                params : {
                    property_type : "rumah"
                }
            }
            )
            if(response.data.errors === null) { 
                return {data : response.data.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})


export const updateStatusHouse = createAsyncThunk(
    'house-proceed-scraping/put', 
    async (values: any, { rejectWithValue }) => { 
        const body = {
            status : values.status,
            note : values.note,
            commission :  values.comission,
            groperti_share : values.groperti_share,
            total_comission : values.total_comission,
            history_status: values.history_status
        }
        try {
            const response : any = await Axios.put(`${process.env.REACT_APP_API_DEV}/house-processed/${values.id}`, body)
            if(response.data.errors === null) { 
                return {data : true, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})
