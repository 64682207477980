import { createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios'

export const getAllMasterIndikator = createAsyncThunk(
    'indikator/fetch', 
    async (search:string, { rejectWithValue }) => { 
        try {
            const response : any = await Axios.get(`${process.env.REACT_APP_API_DEV}/area-master`, {
                params : {
                    name : search || ""
                }
            })
            if(response.data) { 
                return {data : response.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})


export const getMasterIndikator = createAsyncThunk(
    'indikator-master/fetch', 
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await Axios.get(`${process.env.REACT_APP_API_DEV}/area-master`)
            if(response.data) { 
                let array = []
                for(let k of response.data) {
                    array.push({
                        label : k.name,
                        value : k._id
                    })
                }
                return array
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})

export const postMasterIndikator = createAsyncThunk(
    'indikator/post', 
    async (dataValue : any, { rejectWithValue }) => {
        try {
            const body = { 
                "building_price": parseInt(dataValue.harga_bangunan),
                "land_price": parseInt(dataValue.harga_tanah),
                "apartment_price" : parseInt(dataValue.apartment_price),
                "name": dataValue.lokasi,
              }
            const response : any = await Axios.post(`${process.env.REACT_APP_API_DEV}/area-master`, body)
            if(response.data) { 
                return {data : response.data, message : "Success added master indikator"}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})


export const updateMasterIndikator = createAsyncThunk(
    'indikator/update', 
    async (dataValue : any, { rejectWithValue }) => {
        try {
            const body = { 
                "building_price": parseInt(dataValue.harga_bangunan),
                "land_price": parseInt(dataValue.harga_tanah),
                "apartment_price" : parseInt(dataValue.apartment_price),
                "name": dataValue.lokasi,

              }
            const response : any = await Axios.put(`${process.env.REACT_APP_API_DEV}/area-master/${dataValue.id}`, body)
            if(response.data) { 
                return {data : response.data, message : "Success update master indikator"}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})


export const removeMasterIndikator = createAsyncThunk(
    'indikator/remove', 
    async (dataValue : any, { rejectWithValue }) => {
        try {
            const response : any = await Axios.delete(`${process.env.REACT_APP_API_DEV}/area-master/${dataValue.id}`)
            if(response.data) { 
                return {data : response.data, message : "Success remove master indikator"}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})
