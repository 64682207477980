import { createSlice } from '@reduxjs/toolkit';
import swal from 'sweetalert';
import { getUserAgents } from './reducers/userAgentssReducers';


const initialState: any = {
  data: [],
  loading : false,
  create : false,
  loading_create : false,
};

export const userAgentsSlice = createSlice({
  name: 'user-agents',
  initialState,
  reducers: {},
  extraReducers: {
    //   fetch title
    [getUserAgents.pending.type] : (state) => {
        state.loading = true
        state.create = false
    },
    [getUserAgents.fulfilled.type] : (state, action) => {
        state.loading = false
        state.data = action.payload.data
    },
    [getUserAgents.rejected.type] : (state, action) => {
        state.loading = false
        swal('Error', `${action.payload}`, 'error')
    }
  //   // create title
  //   [postHouseTitle.pending.type] : (state) => {
  //       state.loading_create = true
  //   },
  //   [postHouseTitle.fulfilled.type] : (state, action) => {
  //       state.loading_create = false
  //       state.create = true
  //       swal('Success', `${action.payload.message}`, 'success')
  //   },
  //   [postHouseTitle.rejected.type] : (state, action) => {
  //       state.loading_create = false
  //       swal('Error', `${action.payload}`, 'error')
  //   },
  }
});

export default userAgentsSlice.reducer;
