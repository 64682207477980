import { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import BreadCrumbs from '../../../components/BreadCrumbs';
import TableData from '../../../components/TableData'
import { TableColumn } from 'react-data-table-component';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import {  useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../app/store';
import { getAllLeads, getMessageByCustomer } from './reducers/leadsReducers';
import moment from 'moment';
import { DialogContent, Skeleton } from '@mui/material';


    
function Leads() {
    const dispatch = useDispatch()
    const { data, loading, create, messages, update, loading_message, remove } = useSelector((state : RootState) => state.leads)

    const [dataLead, setdataLead] = useState<any>({
        open : false,
        data : {}
    });

    const [limit, setLimit] = useState(15);
    const [page, setPage] = useState(1);

    const handleClickOpen = (data : any) => {
        dispatch(getMessageByCustomer(data?._id))
        setdataLead({...dataLead, open : true, data : data });
    };

    const handleClose = () => {
       setdataLead({...dataLead, 
            open : false, 
            data : {}
        });
    };


    const columns: TableColumn<any>[] = [
        {
            name: 'NO',
            width: '80px',
            cell: (row, index) => (
                <p>
                { page > 1 ? 
                    <>
                    {index + 1 + (limit * (page-1))}
                    </>
                    :
                    <> 
                    {index + 1}
                    </>
                }
                </p>
            )
        },
        {
            name: 'Tanggal',
            cell: (row) => (
                <div>
                    <a href={row.url_Lead} target="_blank" rel="noopener noreferrer">
                    {moment(row.created_at).format('LLL')}
                    </a>
                </div>
            )
        },
        {
            name: 'Nama',
            cell: (row) => (
                <div>
                    <a href={row.url_Lead} target="_blank" rel="noopener noreferrer">
                    {row.name}
                    </a>
                </div>
            )
        },
        {
            name: 'WhatsApp',
            cell: (row) => (
                <div>{row.whatsapp_number}</div>
            )
        },
        {
            name: 'Properti',
            cell: (row) => (
                <div>{row.property.title.substring(0,30)}..</div>
            )
        },
        {
            name: 'Harga Properti',
            cell: (row) => (
                <div>{row.property.price.title}</div>
            )
        },
        {
            name: 'Agent',
            cell: (row) => (
                <div>{row?.agent?.name}</div>
            )
        },
        {
            name: 'Status',
            cell: (row) => (
                <Box 
                    sx={{
                        bgcolor : row.status === 'hot' ? '#c70000' : row.status === 'cold' ? '#3f51b5' : '#ffbc01',
                        color : "white",
                        padding : '5px 15px',
                        borderRadius: 4,
                        fontWeight: 'bold',
                        textTransform: 'uppercase'
                    }}
                >
                    <p>{row.status}</p>
                </Box>
            )
        },
        {
            name: 'ACTION',
            width: '200px',
            cell: (row ) => (
                <Stack direction="row" spacing={2}>
                    <Button 
                        variant="outlined" color="primary" size="small"
                        onClick={() => {
                            handleClickOpen(row)
                        }}
                    >
                     Lihat
                    </Button>
                </Stack>
            ),
        },
    ];
    
    useEffect(() => {
        handleClose()
        dispatch(getAllLeads())
        // dispatch(getAllHouseTitle())
        // eslint-disable-next-line
    },  [create, update, remove]);



    return (
        <div>
            <Box pb={2}>
                <BreadCrumbs
                    current="Leads"
                    isPage={false}
                />
            </Box>
            <Stack direction="row" justifyContent="space-between" spacing={2}>
                <h3>Leads</h3>
            </Stack>
            <Box pt={4}>
                <TableData 
                    columns={columns}
                    data={data}
                    progressPending={loading}
                    pagination
                    onChangePage={(value:any) => setPage(value)}
                    onChangeRowsPerPage={(value:any) => setLimit(value)}
                    paginationPerPage={limit}
                />
            </Box>

            <Dialog 
                open={dataLead.open} 
                fullWidth={true}
                maxWidth="sm"
            >
                    <DialogTitle>{dataLead.open && dataLead?.data?.name} | {dataLead.open && dataLead?.data?.whatsapp_number}</DialogTitle>
                    <DialogContent>
                        {loading_message ? (
                            <Box>
                                <Skeleton height={30} />
                            </Box>
                        ) : (
                            <Box>
                                {messages.length === 0 ? (
                                    <Box>
                                        <p>Belum ada riwayat message Lead</p>
                                    </Box> 
                                    
                                ) : (
                                    <Box>
                                        {messages.map((itm:any, idx:number) => (
                                            <Box sx={{border: '1px solid #ccc', padding : '5px 15px'}}>
                                                <Box display="flex" justifyContent="space-between">
                                                    <p>Date : {moment(itm.updated_at).format('lll') }</p>
                                                    <Box textTransform="uppercase" fontWeight="bold" color={itm?.status === "hot" ? "red" : itm?.status === "cold" ? "blue" : "#eaa24b"}>{itm?.status}</Box>
                                                </Box>
                                                <p>{itm?.message}</p>
                                                {itm?.image_url === "" ? null :
                                                <a href={itm?.image_url} target='_blank'>lihat attachment</a> }
                                            </Box>
                                        ))}
                                    </Box> 
                                )}
                            </Box>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="error" variant='contained'>Cancel</Button>
                    </DialogActions>
            </Dialog>
        </div>
    )   
}

export default Leads
