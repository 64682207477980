import React, { useState, useEffect } from 'react';
import { 
    Stack, 
    Paper, 
    Box, 
    Grid,
    Typography,
    TextField,
    Button,
    CircularProgress,
    Skeleton,
} from '@mui/material';
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../app/store';
import { updateProducts } from '../../reducers/productsReducers';
import swal from 'sweetalert';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ImagesItems from './../components/ImagesItems'


import AddressForm from '../../../../../components/AddressForm';
import { userCredentials } from '../../../../../utilities/config';
import { getAllHouseType } from '../../../MasterData/property_type/reducers/propertyTypeReducers';
import { getAllHouseTitle } from '../../../MasterData/property_title/reducers/propertyTitleReducers';
import { getAllExtraFacilities } from '../../../MasterData/extra_facilities/reducers/extraFacilitiesReducers';
import TextAreaEditor from '../../create/components/TextAreaEditor';

let images_items = [
    {
        image : ""
    },
    {
        image : ""
    },
    {
        image : ""
    },
    {
        image : ""
    }
]


const DetailUpdateProduct : React.FC<any> = ({ dataproduct }) => {

    const dispatch = useDispatch()
    const store_product = useSelector((state : RootState) => state.products)

    const [imagesItems, setImagesItems] = useState<any>(images_items);

    const [valueAddress, setValueAddress] = useState({
        province : "",
        province_id : "",
        error_province : false,
        city : "",
        city_id : "",
        error_city : false,
        subdistrict : "",
        subdistrict_id : "",
        error_subdistrict : false,
        village : "",
        village_id : "",
        error_village : false,
        zipcode : "",
        street : ""
    });

    const [selectTypeSell, setSelectTypeSell] = useState("jual");
    const [recomendation, setRecomendation] = useState("REGULAR");
    const [agent, setAgent] = useState({
        agent_name : "",
        agent_id : ""
    });

    const [loaded, setLoaded] = useState(false);
    const [valueDescription, setValueDescription] = useState("");
    const [errorDescription, setErrorDescription] = useState(false);
    const [templateDesc, setTemplateDesc] = useState("default");

    const [valueProduct, setValueProduct] = useState<any>({
        image : "",
        description: "", 
        price: "",
        overprice: "",
        discount: "",
        land_area : "",
        building_area : "",
        bedroom : "",
        bathroom : "",
        gmap_link: "",
        title : ""
    });

    const [optionsPropertyType, setOptionsPropertyType] = useState<any[]>([]);
    const [loadingType, setLoadingType] = useState<any>(true);
    const [selectedPropertyType, setSelectedPropertyType] = useState<any>([]);
    const [errorPropertyType, setErrorPropertyType] = useState<boolean>(false);

    const [optionsPropertyTitle, setOptionsPropertyTitle] = useState<any[]>([]);
    const [loadingTitle, setLoadingTitle] = useState<any>(true);
    const [selectedPropertyTitle, setSelectedPropertyTitle] = useState<any>([]);
    const [errorPropertyTitle, setErrorPropertyTitle] = useState<boolean>(false);

    const [optionsExtraFacility, setOptionsExtraFacility] = useState<any[]>([]);
    const [loadingExtraFacility, setLoadingExtraFacility] = useState<any>(true);
    const [selectedExtraFacility, setSelectedExtraFacility] = useState<any>([]);
    const [errorExtraFacility, setErrorExtraFacility] = useState<boolean>(false);

    const handleChangePropertyTitle = (value: any) : void => {
        setErrorPropertyTitle(false)
        setSelectedPropertyTitle(value)
    }

    const handleChangePropertyType = (value: any) : void => {
        setErrorPropertyType(false)
        setSelectedPropertyType(value)
    }

    const handleChangeExtraFacility = (value: any) : void => {
        setErrorExtraFacility(false)
        setSelectedExtraFacility(value)
    }
 

    const onchangeValue = (e : any) => {
        const { name, value } = e.target
        if(value < 0) {
            swal('Error', 'Value must be greater than 0', 'error')
        } else {
            setValueProduct({...valueProduct, [name] : value })
        }
    }

    

    const checkError = () => {
        let error = true
        if(selectedPropertyType.length === 0) {
            setErrorPropertyType(true)
            error = true
        } else if (selectedPropertyTitle.length === 0) {
            setErrorPropertyTitle(true)
            error = true
        } else if (selectedExtraFacility.length === 0) {
            setErrorExtraFacility(true)
            error = true
        } else if (imagesItems[0].image === "") {
            swal("Error","Please upload at least one image!",'error')
            error = true
        } else {
            error = false
        }
        return error
    }


    const getExtraFacility = (data: any) => {
        let extra = []
        for(let i of data) {
            extra.push(i.label)
        }
        return extra
    }

    const getImagesArray = (data: any) => {
        let images = []
        for(let i of data) {
            images.push(i.image)
        }
        let filter = images.filter(ele => ele !== "")
        return filter
    }

    const onClickSubmit = (e : any) => {
        e.preventDefault()

        let value_send = {
            id : dataproduct._id,
            body : {
                "type": selectedPropertyType.value,
                "master_trait": selectedPropertyTitle.value,
                "sell_type": selectTypeSell,
                "images": getImagesArray(imagesItems),
                "geo_location": {
                    "longitude": 100.6,
                    "latitude": 90.2323
                },
                "price": {
                    "title": "",
                    "value": parseInt(valueProduct.price)
                },
                "overPrice": {
                    "title": "",
                    "value": parseInt(valueProduct.overprice)
                }, 
                "agent_id": agent.agent_id || "",
                "agent_name": agent.agent_name || "",
                "contact_number": "6281928173",
                "short_description": "",
                "long_description": valueDescription,
                "area": {
                    "country": "Indonesia",
                    "country_id": "+62",
                    "province": valueAddress.province,
                    "province_id":valueAddress.province_id,
                    "city": valueAddress.city,
                    "city_id": valueAddress.city_id,
                    "district": valueAddress.subdistrict,
                    "district_id": valueAddress.subdistrict_id,
                    "village": valueAddress.village,
                    "village_id": valueAddress.village_id,
                    "zip_code": valueAddress.zipcode,
                    "street_number": valueAddress.street
                },
                "facilities": {
                    "land_area": parseInt(valueProduct.land_area),
                    "building_area": parseInt(valueProduct.building_area),
                    "bedroom": parseInt(valueProduct.bedroom),
                    "bathroom": parseInt(valueProduct.bathroom)
                },
                "extras": getExtraFacility(selectedExtraFacility),
                "status": "ACTIVE",
                "recomendation": recomendation,
                "gmap_link": valueProduct.gmap_link,
                "title" : valueProduct.title || ""
            }
        }
        if(!checkError()) {
            dispatch(updateProducts(value_send))
            // console.log(value_send, 'value send')
        } 
    }

    useEffect(() => {
        if(store_product.success_create) {
            setTimeout(() => {
                window.location.href = "/dashboard/products"
            }, 1000);
        }
        // eslint-disable-next-line
    }, [store_product.success_create]);

    useEffect(() => {
        if(store_product.success_update) {
            setTimeout(() => {
                window.location.href = "/dashboard/products"
            }, 1000);
        }
        
    }, [store_product.success_update]);

    useEffect(() => {
        const getHouseType = async () => {
            const house_type : any = await dispatch(getAllHouseType())
            if(house_type.payload.data) {
                let data = house_type.payload.data
                let array = []
                for(let k of data) {
                    array.push({ value : k.name, label : k.name })
                }
                setOptionsPropertyType(array)
                setLoadingType(false)
            }
        }
        const getHouseTitle = async () => {
            const house_title : any = await dispatch(getAllHouseTitle())
            if(house_title.payload.data) {
                let data = house_title.payload.data
                let array = []
                for(let k of data) {
                    array.push({ value : k.name, label : k.name })
                }
                setOptionsPropertyTitle(array)
                setLoadingTitle(false)
            }
        }
        const getHouseFacility = async () => {
            const extra_facility : any = await dispatch(getAllExtraFacilities())
            if(extra_facility.payload.data) {
                let data = extra_facility.payload.data
                let array = []
                for(let k of data) {
                    array.push({ value : k.name, label : k.name })
                }
                setOptionsExtraFacility(array)
                setLoadingExtraFacility(false)
            }
        }
        getHouseFacility()
        getHouseTitle()
        getHouseType()
        // eslint-disable-next-line
    },  []); 
    const proceedImages = (data: any) => {
        let images = []
        for(let i of data) {
            images.push({ image : i })
        }
        return images
    }

    const proceedExtraFacilities = (data: any) => {
        let facilities = []
        for(let i of data) {
            facilities.push({ value: i, label: i })
        }
        return facilities
    }


    useEffect(() => {
        if(dataproduct) {
            setImagesItems(proceedImages(dataproduct.images))
            setSelectedPropertyType({ value: dataproduct.type, label : dataproduct.type })
            setSelectedPropertyTitle({ value: dataproduct.master_trait, label : dataproduct.master_trait })
            setSelectTypeSell(dataproduct.sell_type)
            setRecomendation(dataproduct.recomendation === "REGULAR" ? "REGULAR" : dataproduct.recomendation )
            setValueProduct({...valueProduct, 
                price: dataproduct.price.value,
                title: dataproduct.title,
                overprice: dataproduct.overPrice.value,
                gmap_link : dataproduct.gmap_link,
                land_area : dataproduct.facilities.land_area,
                building_area : dataproduct.facilities.building_area,
                bedroom : dataproduct.facilities.bedroom,
                bathroom : dataproduct.facilities.bathroom,
            })
            setSelectedExtraFacility(proceedExtraFacilities(dataproduct.extras))
            setValueAddress({...valueAddress, 
                province : dataproduct.area.province,
                province_id : dataproduct.area.province_id,
                error_province : false,
                city : dataproduct.area.city,
                city_id : dataproduct.area.city_id,
                error_city : false,
                subdistrict : dataproduct.area.district,
                subdistrict_id : dataproduct.area.district_id,
                error_subdistrict : false,
                village : dataproduct.area.village,
                village_id : dataproduct.area.village_id,
                error_village : false,
                zipcode : dataproduct.area.zip_code,
                street : dataproduct.area.street_number
            })
            setValueDescription(dataproduct.long_description)
            setAgent({...agent,
                agent_id : dataproduct.agent_id,
                agent_name : dataproduct.agent_name,
            })
            // setIsRumahLelang(dataproduct.sell_type === "rumah")
            setLoaded(true)
        }
        // eslint-disable-next-line
    }, [dataproduct]);

    const resetAllField = () => {
        window.location.reload()
    }

    return (
        <Box>

            <Stack mt={4} mb={8}>
                <form onSubmit={onClickSubmit}>
                <Stack mb={2}>
                    <Paper elevation={2}>
                        <Stack p={4}>
                            <Box pb={2}><Typography variant="h6" fontWeight="600">Upload Images</Typography></Box>
                            <Grid container spacing={4}>
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Typography variant="body1" fontWeight="500">Property Images</Typography>
                                    <Typography variant="body2" fontWeight="300">Format gambar .jpg .jpeg .png dan ukuran minimum 300 x 300px </Typography>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    <ImagesItems 
                                        imagesItems={imagesItems}
                                        setImagesItems={setImagesItems}
                                    />
                                </Grid>
                            </Grid>
                        </Stack>
                    </Paper> 
                </Stack>
                <Stack mb={2} flexDirection="column" alignContent="center" justifyContent="center" >
                    <Paper elevation={2}>
                        <Stack p={4}>
                            <Box pb={2}><Typography variant="h6" fontWeight="600">Property Information</Typography></Box>
                            <Grid container columnSpacing={3} rowSpacing={2}>
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Typography variant="body1" fontWeight="500">Property Title</Typography>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    <TextField 
                                        label="Title" 
                                        size="small"
                                        type="text"
                                        name="title"
                                        onChange={(e) => onchangeValue(e)}
                                        value={valueProduct.title}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Typography variant="body1" fontWeight="500">Additional Info</Typography>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    { loadingTitle ? 
                                    <Skeleton height={50}/>
                                    :
                                    <Select
                                        placeholder="Select Title"
                                        value={selectedPropertyTitle}
                                        isSearchable={true}
                                        options={optionsPropertyTitle && optionsPropertyTitle}
                                        onChange={handleChangePropertyTitle}
                                        id="select-style-facility"
                                    /> }
                                    { errorPropertyTitle ? <div className="error-p"><p>Property Title is required</p></div> : null }
                                </Grid>
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Typography variant="body1" fontWeight="500">Property Type</Typography>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    { loadingType ? 
                                    <Skeleton height={50}/>
                                    :
                                    <Select
                                        placeholder="Select Type"
                                        value={selectedPropertyType}
                                        isSearchable={true}
                                        options={optionsPropertyType && optionsPropertyType}
                                        onChange={handleChangePropertyType}
                                        id="select-style-type"
                                    /> }
                                    { errorPropertyType ? <div className="error-p"><p>Property Type is required</p></div> : null }
                                </Grid>
                                
                                <Grid item xl={3} lg={4} xs={12}>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    <FormGroup>
                                        <Stack flexDirection="row">
                                            <Box>
                                                <FormControlLabel 
                                                    control={
                                                        <Checkbox 
                                                            checked={selectTypeSell === "jual" ? true : false} 
                                                            onChange={() => {
                                                               setSelectTypeSell("jual")
                                                            }}
                                                        />} 
                                                    label="Dijual" 
                                                />
                                            </Box>
                                            <Box>
                                                <FormControlLabel 
                                                    control={
                                                        <Checkbox 
                                                            checked={selectTypeSell === "sewa" ? true : false} 
                                                            onChange={() => {
                                                               setSelectTypeSell("sewa")
                                                            }}
                                                        />} 
                                                    label="Disewa" 
                                                />
                                            </Box>
                                            <Box>
                                                <FormControlLabel 
                                                    control={
                                                        <Checkbox 
                                                            checked={selectTypeSell === "lelang" ? true : false} 
                                                            onChange={() => {
                                                               setSelectTypeSell("lelang")
                                                            }}
                                                        />} 
                                                    label="Rumah Lelang" 
                                                />
                                            </Box>
                                        </Stack>
                                    </FormGroup>
                                </Grid>
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Typography variant="body1" fontWeight="500">Recommendation</Typography>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    <FormGroup>
                                        <Stack flexDirection="row">
                                            <Box>
                                                <FormControlLabel 
                                                    control={
                                                        <Checkbox 
                                                            checked={recomendation === "REGULAR" ? true : false} 
                                                            onChange={() => {
                                                               setRecomendation("REGULAR")
                                                            }}
                                                        />} 
                                                    label="Regular" 
                                                />
                                            </Box>
                                            <Box>
                                                <FormControlLabel 
                                                    control={
                                                        <Checkbox 
                                                            checked={recomendation === "CHOSEN" ? true : false} 
                                                            onChange={() => {
                                                               setRecomendation("CHOSEN")
                                                            }}
                                                        />} 
                                                    label="Recommended" 
                                                />
                                            </Box>
                                            <Box>
                                                <FormControlLabel 
                                                    control={
                                                        <Checkbox 
                                                            checked={recomendation === "PROMOTE" ? true : false} 
                                                            onChange={() => {
                                                               setRecomendation("PROMOTE")
                                                            }}
                                                        />} 
                                                    label="Promote" 
                                                />
                                            </Box>
                                        </Stack>
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Paper> 
                </Stack>
                <Stack mb={2} flexDirection="column" alignContent="center" justifyContent="center" >
                    <Paper elevation={2}>
                        <Stack p={4}>
                            <Box pb={2}><Typography variant="h6" fontWeight="600">Property Detail</Typography></Box>
                            <Grid container columnSpacing={3} rowSpacing={2}>
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Typography variant="body1" fontWeight="500">Fair Price</Typography>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    <TextField 
                                        label="Price" 
                                        size="small"
                                        type="number"
                                        name="price"
                                        onChange={(e) => onchangeValue(e)}
                                        value={valueProduct.price}
                                        fullWidth
                                        required
                                    />
                                </Grid>

                                <Grid item xl={3} lg={4} xs={12}>
                                    <Typography variant="body1" fontWeight="500">Over Price</Typography>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    <TextField 
                                        label="Over Price"
                                        size="small"
                                        type="number"
                                        name="overprice"
                                        onChange={(e) => onchangeValue(e)}
                                        value={valueProduct.overprice}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Typography variant="body1" fontWeight="500">Facility</Typography>
                                </Grid>
                                <Grid item xl={2} lg={2} xs={12}>
                                    <TextField 
                                        label="Luas Tanah in m2"
                                        size="small"
                                        type="number"
                                        name="land_area"
                                        onChange={(e) => onchangeValue(e)}
                                        value={valueProduct.land_area}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xl={2} lg={2} xs={12}>
                                    <TextField 
                                        label="Luas Bangunan in m2"
                                        size="small"
                                        type="number"
                                        name="building_area"
                                        onChange={(e) => onchangeValue(e)}
                                        value={valueProduct.building_area}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xl={2} lg={2} xs={12}>
                                    <TextField 
                                        label="Kamar Tidur"
                                        size="small"
                                        type="number"
                                        name="bedroom"
                                        onChange={(e) => onchangeValue(e)}
                                        value={valueProduct.bedroom}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xl={2} lg={2} xs={12}>
                                    <TextField 
                                        label="Kamar Mandi"
                                        size="small"
                                        type="number"
                                        name="bathroom"
                                        onChange={(e) => onchangeValue(e)}
                                        value={valueProduct.bathroom}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Typography variant="body1" fontWeight="500">Extra Facility</Typography>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    { loadingExtraFacility ? 
                                    <Skeleton height={50}/>
                                    :
                                    <Select
                                        placeholder="Select Extra Facility"
                                        value={selectedExtraFacility}
                                        isSearchable={true}
                                        isMulti
                                        options={optionsExtraFacility && optionsExtraFacility}
                                        onChange={handleChangeExtraFacility}
                                        id="select-style-facility"
                                    /> }
                                    { errorExtraFacility ? <div className="error-p"><p>Extra Facility is required</p></div> : null }
                                </Grid>
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Typography variant="body1" fontWeight="500">Google Map Link</Typography>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    <TextField 
                                        label="Link" 
                                        size="small"
                                        type="text"
                                        name="gmap_link"
                                        onChange={(e) => onchangeValue(e)}
                                        value={valueProduct.gmap_link}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Typography variant="body1" fontWeight="500">Template Description</Typography>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    <FormGroup>
                                        <Stack flexDirection="row">
                                            <Box>
                                                <FormControlLabel 
                                                    control={
                                                        <Checkbox 
                                                            checked={templateDesc === "default" ? true : false} 
                                                            onChange={() => {
                                                               setTemplateDesc("default")
                                                               setValueDescription("")
                                                            }}
                                                        />} 
                                                    label="Free Text" 
                                                />
                                            </Box>
                                            <Box>
                                                <FormControlLabel 
                                                    control={
                                                        <Checkbox 
                                                            checked={templateDesc === "template" ? true : false} 
                                                            onChange={() => {
                                                               setTemplateDesc("template")
                                                               setValueDescription(template_Desc)
                                                            }}
                                                        />} 
                                                    label="Template" 
                                                />
                                            </Box>
                                        </Stack>
                                    </FormGroup>
                                </Grid>
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Typography variant="body1" fontWeight="500">Description</Typography>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    { loaded && 
                                    <TextAreaEditor 
                                        value={loaded && valueDescription}
                                        setValue={setValueDescription}
                                        error={errorDescription}
                                        setError={setErrorDescription}
                                        placeholder="Type description here..."
                                        loaded={loaded}
                                    /> }
                                </Grid>
                            </Grid>
                        </Stack>
                    </Paper> 
                </Stack>

                <Stack mb={2} flexDirection="column" alignContent="center" justifyContent="center" >
                    <Paper elevation={2}>
                        <Stack p={4}>
                            <Box pb={2}><Typography variant="h6" fontWeight="600">Property Address</Typography></Box>
                            <AddressForm 
                                valueAddres={valueAddress}
                                setValueAddress={setValueAddress}
                            />
                        </Stack>
                    </Paper> 
                </Stack>
                <Stack >
                    <Stack pt={4} flexDirection="row" justifyContent="space-between">
                        <Button 
                            variant='outlined' color="error" 
                            onClick={resetAllField}
                        >
                          Reset Field
                        </Button>
                        <Button variant='contained' color="primary" type="submit" disabled={store_product.loading_update ? true : false}>
                           { store_product.loading_update ? <span>Loading.. <CircularProgress color='inherit' size={20}/></span> : "Edit Property" } 
                        </Button>
                    </Stack>
                </Stack>
                </form>
            </Stack>
        </Box>
    )
}

const template_Desc = "<h3>Rumah Dijual …</h3><ul><li>Lokasi Strategis</li><li>&nbsp;</li></ul><p>✅Keunggulan Rumah:&nbsp;</p><ul><li>&nbsp;</li></ul><p>✅Kelebihan Area:&nbsp;</p><ul><li>&nbsp;</li></ul><p>✅Spesifikasi Rumah</p><ul><li>&nbsp;</li></ul><p>✅Spesifikasi</p><ul><li>&nbsp;</li></ul><p>✅Lokasi:&nbsp;</p><ul><li>&nbsp;</li></ul><p>✅Akses:</p><ul><li>&nbsp;</li></ul><p><br>Simak tutorial berikut ini<br>✅https://groperti.com/blog/sebelum-musim-hujan-atasi-atap-rumahmu-dengan-cara-ini<br>✅https://groperti.com/blog/cara-merawat-rumah-agar-tidak-lembab-dan-berjamur</p>"

export default DetailUpdateProduct;
