import React, { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import BreadCrumbs from '../../../components/BreadCrumbs';
import TableData from '../../../components/TableData'
import { TableColumn } from 'react-data-table-component';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import {  useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../app/store';
import { getAllWithdraw, updateWithdraw } from './reducers/withdrawReducers';
import swal from 'sweetalert';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { Divider, Skeleton } from '@mui/material';
import moment from 'moment';
import { userCredentials } from '../../../utilities/config';
import { uploadFileToS3 } from '../../../utilities/helper';

const Input = styled('input')({
    display: 'none',
  });
    
function WithdrawPage() {
    const dispatch = useDispatch()
    const { data, loading, update, loading_update } = useSelector((state : RootState) => state.withdraw)

    const [valueInput, setValueInput] = useState<any>({
        name : "",
        id: "",
    });

    const [dataDetail, setDataDetail] = useState<any>([]);
    const [loaded, setLoaded] = useState(false);
    const [imageUrl, setImageUrl] = useState("");
    const [loadingImg, setLoading] = useState(false);

    const [open, setOpen] = useState({
        open : false,
        name : ""
    });

    const [limit, setLimit] = useState(15);
    const [page, setPage] = useState(1);

    const handleClickOpen = (name:string) => {
        setOpen({
            open : true,
            name : name
        });
    }

    const handleClose = () => {
        setOpen({...open, open : false, name : "" });
        setLoaded(false)
        setValueInput({...valueInput, name : "" });
    };

    const onClickProof = () => {
        if(imageUrl === "") {
            swal('Error', 'Bukti transfer harus di upload!', 'error')
        } else  {
            let data = {
                body : {
                    payment_proof : {
                        url_image : imageUrl,
                        created_at : new Date (),
                        created_by : userCredentials.name
                    },
                    status : "WITHDRAWED"
                },
                id : dataDetail._id
            }
            dispatch(updateWithdraw(data))
        }
    }

    const onChangeValueFile = async (e : any) => {
        setLoading(true)
        const imageUrl = await uploadFileToS3(e.target.files[0], 'images')
        if(imageUrl !== undefined) {
            setImageUrl(imageUrl)
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    useEffect(() => {
        dispatch(getAllWithdraw())
        handleClose()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update]);

    const columns: TableColumn<any>[] = [
        {
            name: 'NO',
            width: '80px',
            cell: (row, index) => (
                <p>
                { page > 1 ? 
                    <>
                    {index + 1 + (limit * (page-1))}
                    </>
                    :
                    <> 
                    {index + 1}
                    </>
                }
                </p>
            )
        },
        {
            name: 'NAMA REFERRAL',
            cell: (row) => (
                <div>{row.name}</div>
            )
        },
        {
            name: 'REFERAL CODE',
            cell: (row) => (
                <div>{row.referral_code}</div>
            )
        },
        {
            name: 'TANGGAL REQUEST',
            cell: (row) => (
                <div>{moment(row.request_date).format('LL')}</div>
            )
        },
        {
            name: 'Bank',
            cell: (row) => (
                <div>{row.bank_name}</div>
            )
        },
        {
            name: 'No REK',
            cell: (row) => (
                <div>{row.bank_number}</div>
            )
        },
        {
            name: 'Total',
            cell: (row) => (
                <div>Rp. {row.total.toLocaleString()}</div>
            )
        },
        {
            name: 'STATUS',
            cell: (row) => (
                <div>{row.status === "WITHDRAWED" ? "Paid" : row.status}</div>
            )
        },
        {
            name: 'ACTION',
            width: '200px',
            cell: (row ) => (
                <Stack direction="row" spacing={2}>
                    <Button 
                        variant="outlined" color="primary" size="small"
                        disabled={row.status === "WITHDRAWED" ? true : false}
                        onClick={() => {
                            setDataDetail(row)
                            setLoaded(true)
                            handleClickOpen("Edit")
                        }}
                    >
                     Proses
                    </Button>
                </Stack>
            ),
        },
    ];
    

    return (
        <div>
            <Box pb={2}>
                <BreadCrumbs
                    current="Withdraw Referral"
                    isPage={false}
                />
            </Box>
            <Stack direction="row" justifyContent="space-between" spacing={2}>
                <h3>Withdraw Referral</h3>
            </Stack>
            <Box pt={4}>
                <TableData 
                    columns={columns}
                    data={data}
                    progressPending={loading}
                    pagination
                    onChangePage={(value) => setPage(value)}
                    onChangeRowsPerPage={(value) => setLimit(value)}
                    paginationPerPage={limit}
                />
            </Box>

            <Dialog 
                open={open.open} 
                fullWidth={true}
                maxWidth="sm"
                
            >
                <DialogTitle>Detail Withdraw</DialogTitle>
                <Stack sx={{px : 3}}>
                    <Box pb={2}>
                        <Box>
                            Total Uang perlu ditransfer : {loaded && dataDetail.total.toLocaleString()}
                        </Box>
                        <Box>
                            Rekening Bank : {loaded && dataDetail.bank_name}
                        </Box>
                        <Box>
                            No Rekening Bank : {loaded && dataDetail.bank_number}
                        </Box>
                        <Box>
                            Nama Penerima : {loaded && dataDetail.bank_account}
                        </Box>
                    </Box>
                    <Divider />
                    <Box pt={2}>Upload Bukti Transfer</Box>
                    <Box >
                        { loadingImg ? 
                        <Skeleton 
                            variant="rectangular" 
                            width={50} height={50} 
                            sx={{ borderRadius: '4px' }}
                        /> :
                        <Box>
                            { imageUrl === "" ?
                            <Box pt={1}>
                                <label htmlFor="icon-button-files" style={{ border: '1px solid #ccc', padding: '10px', }}>
                                    <Input accept="image/*" id="icon-button-files" type="file"  onChange={(e:any) => onChangeValueFile(e)} />
                                        <IconButton color="primary" aria-label="upload picture" component="span">
                                        <PhotoCamera />
                                    </IconButton>
                                </label>
                            </Box>
                            :
                            <Stack flexDirection="column"  >
                                <Box 
                                    component="img"
                                    src={imageUrl}
                                    sx={{
                                        width: '100px',
                                        borderRadius: '4px',
                                        border: '1px solid #ccc',
                                        padding: '5px'
                                    }}
                                />
                                {/* <Box
                                    sx={{
                                        color: 'blue',
                                        cursor: 'pointer',
                                        fontSize: 12,
                                        pt: 1
                                    }}
                                    onClick={() => onChangeValue(}
                                >Change</Box> */}
                            </Stack> }
                        </Box> 
                        }
                    </Box>
                </Stack>
                <DialogActions>
                    <Button onClick={handleClose} color="warning">Cancel</Button>
                    <Button type="submit" variant="outlined" onClick={onClickProof}>{ loading_update ? "Loading.." : "Submit" }</Button>
                </DialogActions>
            </Dialog>
        </div>
    )   
}

export default WithdrawPage
