import { createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios'

export const getUserBuyers = createAsyncThunk(
    'user-buyers/fetch', 
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await Axios.get(`${process.env.REACT_APP_API_DEV}/user/buyer`)
            if(response.data.errors === null) { 
                return {data : response.data.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})

export const postHouseTitle = createAsyncThunk(
    'user-buyers/post', 
    async (name : string, { rejectWithValue }) => {
        try {
            let body = {
                name : name
            }
            const response : any = await Axios.post(`${process.env.REACT_APP_API_DEV}/master-trait`, body)
            if(response.data.errors === null) { 
                return {data : response.data.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})

