import { createSlice } from '@reduxjs/toolkit';
import swal from 'sweetalert';
import { deletePropertyScrapingHouse, getAllScrapingData, getAllScrapingHouse, postProceedHouse } from './reducers/scrapingHouseReducers';


const initialState: any = {
  data: [],
  data_default : [],
  data_loaded: false,
  loading : false,
  create : false,
  loading_create : false,
  proceed : false, 
  loading_proceed: false,
  data_scraping : [],
  total_data : 0,
  loading_data_scraping : false,
  remove : false, 
  loading_remove : false,
};

export const scrapingHouseSlice = createSlice({
  name: 'house-scraping',
  initialState,
  reducers: {},
  extraReducers: {
    //   fetch title
    [getAllScrapingHouse.pending.type] : (state) => {
        state.loading = true
        state.create = false
        state.data_loaded = false
        state.remove = false
    },
    [getAllScrapingHouse.fulfilled.type] : (state, action) => {
        state.loading = false
        state.data = action.payload.data
        state.data_default = action.payload.data
        state.data_loaded = true
    },
    [getAllScrapingHouse.rejected.type] : (state, action) => {
        state.loading = false
        swal('Error', `${action.payload}`, 'error')
    },

    //   fetch all new data
    [getAllScrapingData.pending.type] : (state) => {
      state.loading_data_scraping = true
    },
    [getAllScrapingData.fulfilled.type] : (state, action) => {
        state.loading_data_scraping = false
        state.data_scraping = action.payload.data
        state.total_data = action.payload.document_count
    },
    [getAllScrapingData.rejected.type] : (state, action) => {
        state.loading_data_scraping = false
        swal('Error', `${action.payload}`, 'error')
    },

     //   post proceed
    [postProceedHouse.pending.type] : (state) => {
      state.loading_proceed = true
      state.proceed = false
    },
    [postProceedHouse.fulfilled.type] : (state, action) => {
        state.loading_proceed = false
        state.proceed = action.payload.data
        swal('Success', `${action.payload.message}`, 'success')
    },
    [postProceedHouse.rejected.type] : (state, action) => {
        state.loading_proceed = false
        swal('Error', `${action.payload}`, 'error')
    },

    // delete scarping house
    [deletePropertyScrapingHouse.pending.type] : (state) => {
      state.loading_remove = true
      state.remove = false
    },
    [deletePropertyScrapingHouse.fulfilled.type] : (state, action) => {
        state.loading_remove = false
        state.remove = action.payload.data
        swal('Success', `${action.payload.message}`, 'success')
    },
    [deletePropertyScrapingHouse.rejected.type] : (state, action) => {
        state.loading_remove = false
        swal('Error', `${action.payload}`, 'error')
    }
  }
});

export default scrapingHouseSlice.reducer;
