import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';


export const getStatisticData = createAsyncThunk(
    'get/statistic',
    async (data:any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_DEV}/house-master/statistic`, {
                params : {
                    price_low : data.price_low,
                    price_high : data.price_high,
                    property_type : data.property_type
                }
            })
            if(response.data.errors === null) {
                return {data : response.data.data}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            if (!err.response) {
              throw err
            }
            return rejectWithValue(err.response)
        }
    }
);


export const getStatisticDataArea = createAsyncThunk(
    'get/statistic-area',
    async (data:any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_DEV}/house-master/total-by-price/statistic`, {
                params : {
                    location : data.location
                }
            })
            if(response.data.errors === null) {
                return {data : response.data.data}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            if (!err.response) {
              throw err
            }
            return rejectWithValue(err.response)
        }
    }
);
