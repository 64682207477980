import { createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios'

export const getUserAdmins = createAsyncThunk(
    'user-admins/fetch', 
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await Axios.get(`${process.env.REACT_APP_API_DEV}/user/admin`)
            if(response.data.errors === null) { 
                return {data : response.data.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})

export const postHouseTitle = createAsyncThunk(
    'user-admins/post', 
    async (name : string, { rejectWithValue }) => {
        try {
            let body = {
                name : name
            }
            const response : any = await Axios.post(`${process.env.REACT_APP_API_DEV}/admin`, body)
            if(response.data.errors === null) { 
                return {data : response.data.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})

