import { createSlice } from '@reduxjs/toolkit';
import swal from 'sweetalert';
import { getUserReferral } from './reducers/userReferralReducers';


const initialState: any = {
  data: [],
  loading : false,
  create : false,
  loading_create : false,
};

export const userReferralSlice = createSlice({
  name: 'referral',
  initialState,
  reducers: {},
  extraReducers: {
    //   fetch title
    [getUserReferral.pending.type] : (state) => {
        state.loading = true
        state.create = false
    },
    [getUserReferral.fulfilled.type] : (state, action) => {
        state.loading = false
        state.data = action.payload.data
    },
    [getUserReferral.rejected.type] : (state, action) => {
        state.loading = false
        swal('Error', `${action.payload}`, 'error')
    }
  }
});

export default userReferralSlice.reducer;
