import { useEffect, useState } from 'react';
import {
    Container,
    CssBaseline,
    Box,
    TextField,
    Button, 
    Grid, 
    Link, 
    CircularProgress, 
    Stack
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../app/store';
import { loginAction } from './reducers/loginReducers';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import React from 'react'

const Login = () => {
  const dispatch = useDispatch()
  const auth = useSelector((state : RootState) => state.login )
  const [showPassword, setShowPassword] = useState(false);
  const [data, setData] = useState({
    email: "",
    password : ""
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  
  const onSubmit = (e : any): void => {
    e.preventDefault()
    dispatch(loginAction(data))
  }

  function onChange(e:any) {
    setData({...data, [e.target.name] : e.target.value})
    
  }


  /* istanbul ignore next */
  useEffect(() => {
    if(auth.login) {
      setTimeout(() => {
        window.location.href = '/dashboard'
      }, 1000);
    }
    // eslint-disable-next-line
  }, [auth.login]);

  
  return (
    <>
      <div className='container-auth' >
      <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 12,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box color="secondary">
              <Stack flexDirection="column" alignItems="center">
              <Box className='title-daftar'>
                <h1>Masuk Admin Groperti</h1>
                <p>Silahkan masukan data akun kamu.</p>
              </Box>
              </Stack>
            </Box>

            <Box className='container-form'>
            <form onSubmit={onSubmit}>
              <Box pt={2} className='title-label'>Email</Box>
              <TextField
                margin="dense"
                onChange={onChange}
                value={data.email}
                fullWidth
                id="email"
                placeholder="Email"
                name="email"
                autoComplete="email"
                size='small'
                required
              />
              <Box pt={2} className='title-label'>Password</Box>
              <TextField
                margin="dense"
                fullWidth
                name="password"
                onChange={onChange}
                value={data.password}
                placeholder='******'
                type={showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="current-password"
                InputProps={{
                  endAdornment : (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                size='small'
                required
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                size="medium"
                color="primary"
              >
                Masuk
                
                { /* istanbul ignore next */
                  auth.loading && <CircularProgress  size={30} color="inherit" style={{marginLeft: 10}} /> }
              </Button>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Link href="/forgot-password">
                    Lupa password?
                  </Link>
                </Grid>
              </Grid>
            </form>
            </Box>
          </Box>
        </Container>
      </div>
    </>
    )
}

export default Login;
