import React,{ useEffect, useState } from 'react';
import { Avatar, Button, Dialog, DialogActions, DialogTitle, Grid, Skeleton, Stack, TextField } from '@mui/material';
import { Box } from '@mui/system'
import { TableColumn } from 'react-data-table-component';
import BreadCrumbs from '../../../components/BreadCrumbs'
import DataTableBase from '../../../components/TableData'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { useHistory } from 'react-router-dom';
import { getAllProperty, removeProduct, updateSoldProducts, updateStatusProperty, updateVerifyProperty } from './reducers/productsReducers';
import Swal from 'sweetalert2';
import CloseIcon from '@mui/icons-material/Close';
// @ts-ignore
import Select from 'react-select'
import swal from 'sweetalert';
import { getUserAgents } from '../DataUsers/agents/reducers/userAgentssReducers';
import axios from 'axios';
import moment from 'moment';
import SearchComponent from '../../../components/SearchComponent';
import { createNewLead } from '../Leads/reducers/leadsReducers';

const optionsStatus : any = [
    {
        value : "ACTIVE",
        label : "ACTIVE",
    },
    {
        value : "DRAFT",
        label : "DRAFT",
    },
    {
        value : "SOLD",
        label : "SOLD",
    }
]

const ProductPage = () => {

    const history = useHistory()
    const dispatch = useDispatch()
    const state_products = useSelector((state : RootState) => state.products)
    const store_user_agents = useSelector((state : RootState) => state.user_agents)
    const { create, loading_create } = useSelector((state : RootState) => state.leads)


    const [limit, setLimit] = useState(15);
    const [page, setPage] = useState(1);

    const [open, setOpen] = useState(false);
    const [dataRow, setDataRow] = useState<any>({});
    const [loaded, setLoaded] = useState(false);

    const [kodeReferal, setKodeReferal] = useState("");
    const [valueSearch, setValueSearch] = useState<string>("");

    const [openLead, setOpenLead] = useState(false);
    const [formLeads, setFormLeads] = useState({
        name : "",
        whatsapp_number: "",
        requestCusts : "",
        agent_id : "",
        property_id: "",
        status : "warm"
    });

    const [openSold, setOpenSold] = useState(false);
    const [selectedProvince, setSelectedProvince] = useState<any>([]);
    const [optionsProvince, setOptionsProvince] = useState<any>([]);
    const [loadingProvince, setLoadingProvince] = useState(false);


    const [optionsSoldBy] = useState<any[]>([
        { value: "Groperti", label : "Groperti"},
        { value: "Platform Lain", label : "Platform Lain"},
        { value: "Referal Groperti", label : "Referal Groperti"}
    ]);

    const [selectedStatus, setSelectedStatus] = useState<any>([]);

    const [selectedAgent, setSelectedAgent] = useState<any>([]);

    const handleChangeStatus = (value:any) => {
        setSelectedStatus(value)
    }

    const handleChangeAgent = (value:any) => {
        setSelectedAgent(value)
    }

    const onChangeSelectArea = (value:any) => {
        setSelectedProvince(value)
    }

    const clearAllFilter = () => {
        setSelectedAgent([])
        setSelectedStatus([])
        setSelectedProvince([])
    }

    const [selectedSoldBy, setSelectedSoldBy] = useState<any>([]);
    const [errorSoldBy, setErrorSoldBy] = useState<boolean>(false);

    const handleChangeSoldBy = (value: any) : void => {
        setErrorSoldBy(false)
        setSelectedSoldBy(value)
    }

    const handleClickOpen = (row:any) => {
        setDataRow(row)
        setLoaded(true)
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false)
        setOpenSold(false)
        setOpenLead(false)
    };

    const onChangePerPage = (page:any) => {
        setPage(page)
    }

    const onClickSubmitLead = (e:any) => {
        e.preventDefault()
        dispatch(createNewLead(formLeads))
    }

    const onClickSubmitSold = (row:any) => {
        if(selectedSoldBy.value === "Referal Groperti") {
            if(kodeReferal === "") {
                swal("Error", "Kode referal tidak boleh kosong", "error")
            } else {
                let data = {
                   body : {
                    "status": "SOLD",
                    "soldByReferral": kodeReferal
                   }, 
                   id : row._id
                }
                dispatch(updateSoldProducts(data))
            }
        } else {
            let data = {
                body : {
                    "status": "SOLD",
                    "soldByReferral": selectedSoldBy.value
                }, 
                id : row._id
            }
            dispatch(updateSoldProducts(data))
        }
    }

    const getFetchData = () => {
        const data = {
            params : {
                limit,
                page,
                status : selectedStatus.value,
                agent_id : selectedAgent.value,
                area : selectedProvince.value
            },
            body : {
                "slug" : valueSearch
            }
        }
        dispatch(getAllProperty(data))
    }

    useEffect(() => {
        getFetchData()
        handleClose()
        setOpenSold(false)
        // eslint-disable-next-line
    }, [page, limit, state_products.sold, selectedStatus, selectedAgent, selectedProvince, valueSearch]);

    useEffect(() => {
        if(state_products.success_remove) {
            handleClose()
            getFetchData()
        }
        // eslint-disable-next-line
    }, [state_products.success_remove]);

    useEffect(() => {
        dispatch(getUserAgents())
        getDataProvince()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(create) {
            setOpenLead(false)
        }
    }, [create]);

    const onClickUpdate = (data: any) => {
        history.push({
            pathname : `/dashboard/products`,
            search: `?page=edit`, 
            state : {
                product : data,
                slug : `${data.slug}`,
            }
        })
    }

    const onClickDetail = (data: any) => {
        history.push({
            pathname : `/dashboard/products`,
            search: `?page=detail`, 
            state : {
                product : data,
                slug : `${data.slug}`,
            } 
        })
    }

    const onClickLead = (data: any) => {
        setOpenLead(true)
        setFormLeads({...formLeads,
            agent_id : data.agent_id,
            property_id : data._id
        })
    }

    const onClickPublish = (data: any) => {
        handleClose()
        let value = {
            id: data._id,
            body : {
                status : data.status === "ACTIVE" ? "DRAFT" : "ACTIVE"
            }
        }
        Swal.fire({
            title : "Confirmation",
            text: `Are you sure want to ${data.status === "ACTIVE" ? "UnPublish" : "Publish"} property ${data.title}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: 'primary',
            cancelButtonColor: '#d33',
            confirmButtonText: `Yes, ${data.status === "ACTIVE" ? "UnPublish" : "Publish"} It`,
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return dispatch(updateStatusProperty(value))
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
          if (result.isConfirmed) {
            getFetchData()
          }
        })
    }

    const onClickVerify = (data: any) => {
        handleClose()
        let value = {
            id: data._id,
            body : {
                "isAgentVerified" : true
            }
        }
        Swal.fire({
            title : "Confirmation",
            text: `Are you sure want to publish Verify ${data.title}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: 'primary',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Verify It',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return dispatch(updateVerifyProperty(value))
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
          if (result.isConfirmed) {
            getFetchData()
          }
        })
    }


    const onClickRemove = (id:string) => {
        handleClose()
        Swal.fire({
            title : "Confirmation",
            text: `Are you sure want to Remove this property?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: 'primary',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Remove It',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return dispatch(removeProduct(id))
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
          if (result.isConfirmed) {
            getFetchData()
          }
        })
    }

    const proceedProvince = (params : any) => {
        let province = []
        for(let prov of params) {
            province.push({
                value : prov.nama,
                label : prov.nama,
            })
        }
        let sortBy = province.sort((a:any, b:any) => {
            return b.label - a.label;
        });
        setOptionsProvince(sortBy)
        setLoadingProvince(false)
    }

    const getDataProvince = async () => {
        setLoadingProvince(true)
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_DEV}/provinces`)
            if(response.data) {
                proceedProvince(response.data.data)
            } else {
                swal('Error', `${response.error}`, 'error')
                setLoadingProvince(false)
            } 
            
        } catch (error) {
            swal('Error', `${error}`, 'error')
            setLoadingProvince(false)
        }
    }

    const handleClickView = (row:any) => {
        window.open(`https://groperti.com/detail/${row.slug}`, '_blank')
    }


    const columns: TableColumn<any>[] = [
        {
            name: 'NO',
            width: '70px',
            cell: (row, index) => (
                <p>
                { page > 1 ? 
                    <>
                    {index + 1 + (limit * (page-1))}
                    </>
                    :
                    <> 
                    {index + 1}
                    </>
                }
                </p>
            )
        },
        {
            name: 'Date',
            width: '120px',
            cell: (row) => (
                <p>{moment(row.created_at).format('ll')}</p>
            )
        },
        {
            name: 'NAME',
            width: '250px',
            cell: (row) => (
                <Stack flexDirection="row" alignContent="center" >
                    <Box>
                        <Avatar
                            alt={row.title}
                            src={row.images[0]}
                            sx={{ width: 35, height: 35 }}
                            variant="square"
                        />
                    </Box>
                    { row.title.length > 20 ? 
                    <Box pl={1} pt={1}>
                    <a href={`https://groperti.com/detail/${row.slug}`} target='_blank'  rel="noopener noreferrer">
                       {row.title.substring(0,20)} ..
                       </a>
                    </Box>
                    :
                    <Box pl={1} pt={1}>
                        <a href={`https://groperti.com/detail/${row.slug}`} target='_blank'  rel="noopener noreferrer">
                       {row.title}
                       </a>
                    </Box>
                    }
                </Stack>
            )
        },
        {
            name: 'AGENT',
            cell: (row) => (
                <p>{row.agent_name}</p>
            )
        },
        {
            name: 'PRICE',
            cell: (row) => (
                <p>Rp. {row.price.title}</p>
            )
        },
        {
            name: 'Type Property',
            cell: (row) => (
                <Box sx={{ textTransform : 'capitalize' }} >{row.type} di {row.sell_type}</Box>
            )
        },
        {
            name: 'FACILITY',
            cell: (row) => (
                <p>{row.facilities.land_area} LT, {row.facilities.building_area} LB, {row.facilities.bedroom} KT, {row.facilities.bathroom} KM, </p>
            )
        },
        {
            name: 'CITY',
            cell: (row) => (
                <p>{row.area.city}</p>
            )
        },
        {
            name: 'STATUS',
            cell: (row) => (
                <p>{row.status}</p>
            )
        },
        {
            name: 'VERIFIED',
            cell: (row) => (
                <p>{row.isAgentVerified && row.isAgentVerified ? "Yes" : "No"}</p>
            )
        },
        {
            name: 'ACTION',
            width: "190px",
            cell: (row) => (
                <Stack direction="row" spacing={2}>
                    <Box>
                        <Button
                            variant="contained" color="error" size="small"
                            onClick={() => handleClickView(row)}
                        >
                            View
                        </Button>
                    </Box>
                    <Box>
                        <Button
                            variant="contained" color="primary" size="small"
                            onClick={() => handleClickOpen(row)}
                        >
                            Action
                        </Button>
                    </Box>
                </Stack>
            ),
        },
    ];

    return (
        <Box sx={{pt:2, pl:3, pr:3}}>
            <BreadCrumbs
                isPage={false}
                current="Property Listing"
            />
            <Stack direction="row" justifyContent="space-between" pt={3} >
                <Box>
                    <h3>Property Listing</h3>
                </Box>
                <Box>
                    <Button
                        onClick={() => {
                            history.push({
                                pathname: "/dashboard/products",
                                search: `?page=create`, 
                            }) 
                        }}
                        variant="contained" color="primary" size="small">
                        Add New Property
                    </Button>
                </Box>
            </Stack>

            <SearchComponent 
                valueSearch={valueSearch}
                setValueSearch={setValueSearch}
                placeholder="Search by detail url ..."
            />

            <Box sx={{ pt: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xl={3} lg={3} md={3} sm={6} xs={12} >
                        {loadingProvince ? <Skeleton height={40} /> :
                        <Select 
                            options={optionsProvince && optionsProvince} 
                            value={selectedProvince}
                            onChange={onChangeSelectArea}
                            placeholder="Filter by province"
                        /> }
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={6} xs={12} >
                        {store_user_agents.loading ? <Skeleton height={40} /> :
                        <Select 
                            options={store_user_agents && store_user_agents.data.map((item:any) => ({ value : item.auth_id, label : item.name }))} 
                            value={selectedAgent || {label: "Select Agent", value:""}}
                            onChange={handleChangeAgent}
                            placeholder="Filter by Agent"
                        /> }
                    </Grid>
                    <Grid item xl={2} lg={2} md={2} sm={6} xs={12} >
                        <Select 
                            options={optionsStatus} 
                            value={selectedStatus }
                            onChange={handleChangeStatus}
                            placeholder="Sort by status"
                        />
                    </Grid>

                    <Grid item xl={2} lg={2} md={2} sm={6} xs={12} >
                        <Button
                            variant="contained" 
                            color="secondary"
                            fullWidth
                            onClick={() => clearAllFilter()}
                        >
                            Clear Filter 
                        </Button>
                    </Grid>
                </Grid>
            </Box>

            <Box sx={{pt:3}}>
                <DataTableBase 
                    columns={columns} 
                    data={state_products.data}
                    progressPending={state_products?.loading}
                    pagination
                    paginationServer
                    onChangePage={(value) => onChangePerPage(value)}
                    onChangeRowsPerPage={(value) => setLimit(value)}
                    paginationTotalRows={state_products.count_listing}
                    paginationPerPage={limit}
                    paginationDefaultPage={page}
                />
            </Box>

            <Dialog 
                open={open} 
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle>
                    <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                        <Box>Detail Action </Box>
                        <Box>
                            <CloseIcon sx={{ color: 'red', cursor: 'pointer', fontSize: 30 }} onClick={handleClose}/>
                        </Box>
                    </Stack>
                </DialogTitle>
                <Stack sx={{px : 3, mb: 3}}>
                    <Grid flexDirection="row" justifyContent="space-around" spacing={2} columnSpacing={2} flexWrap="wrap">
                        <Button 
                            variant="contained" color="primary" size="small"
                            sx={{mr:1, mb:1}}
                            onClick={() => onClickLead(dataRow)}
                        >
                            Tambah Lead
                        </Button>
                        <Button 
                            variant="outlined" color="primary" size="small"
                             sx={{mr:1, mb:1}}
                            onClick={() => onClickDetail(dataRow)}
                        >
                            Detail
                        </Button>
                        <Button 
                             sx={{mr:1, mb:1}}
                            variant="outlined" color="primary" size="small"
                            onClick={() => onClickUpdate(dataRow)}
                        >
                            Edit
                        </Button>
                        
                        <Button 
                            variant="outlined" 
                             sx={{mr:1, mb:1}}
                            size="small"
                            onClick={() => onClickPublish(dataRow)}
                            color={loaded && dataRow.status !== "ACTIVE" ? "success" : "error"}
                        >
                            {loaded && dataRow.status === "ACTIVE" ? "Un Publish" : "Publish"}
                        </Button>
                        <Button 
                            variant="contained"
                             sx={{mr:1, mb:1}}
                            size="small"
                            color="error"
                            onClick={() => onClickRemove(dataRow._id)}
                        >
                            Remove 
                        </Button> 
                        <Button 
                            variant="outlined"  
                             sx={{mr:1, mb:1}}
                            size="small"
                            onClick={() => onClickVerify(dataRow)}
                            disabled={loaded && dataRow.isAgentVerified}
                            color="success"
                        >
                            Verify
                        </Button> 
                        <Button 
                            variant="contained"
                             sx={{mr:1, mb:1}}
                            size="small"
                            color="success"
                            disabled={loaded && dataRow.status === "SOLD" ? true : false}
                            onClick={() => {
                                handleClose()
                                setOpenSold(true)
                            }}
                        >
                            Mark as Sold
                        </Button> 
                    </Grid>
                </Stack>
            </Dialog>

            <Dialog 
                open={openSold} 
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle>
                    <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                        <Box>Detail Sold By </Box>
                        <Box>
                            <CloseIcon sx={{ color: 'red', cursor: 'pointer', fontSize: 30 }} onClick={handleClose}/>
                        </Box>
                    </Stack>
                </DialogTitle>
                <Stack sx={{px : 3, mb: 3, height: 150}}>
                    <Select
                        placeholder="Pilih Terjual Oleh Siapa"
                        value={selectedSoldBy}
                        isSearchable={true}
                        options={optionsSoldBy && optionsSoldBy}
                        onChange={handleChangeSoldBy}
                        id="select-style-title"
                    /> 
                    { errorSoldBy ? <div className="error-p"><p>Silahkan pilih terjual oleh siapa !</p></div> : null }
                    
                    { selectedSoldBy.value === "Referal Groperti"  ?
                    <Box pt={2}>
                        <TextField 
                            label="Masukan Kode Referal" 
                            size="small"
                            type="text"
                            name="referal_code"
                            onChange={(e) => setKodeReferal(e.target.value)} 
                            fullWidth
                            required
                        />
                    </Box> : null }
                </Stack>
                <DialogActions>
                    {  loaded && dataRow ? 
                    <Box my={2} mr={2}>
                        <Button 
                            variant="contained"
                            size="small"
                            color="success"
                            disabled={selectedSoldBy.length === 0 ? true : false}
                            onClick={() => {
                                onClickSubmitSold(dataRow)
                            }}
                            >
                            { state_products.loading_sold ? "Loading.." : "Submit" }
                        </Button> 
                    </Box> : null }
                </DialogActions>
            </Dialog>


            <Dialog 
                open={openLead} 
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle>
                    <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                        <Box>Masukan Data Lead </Box>
                        <Box>
                            <CloseIcon sx={{ color: 'red', cursor: 'pointer', fontSize: 30 }} onClick={handleClose}/>
                        </Box>
                    </Stack>
                </DialogTitle>
                <form onSubmit={onClickSubmitLead} >
                <Stack sx={{px : 3, mb: 3, height: 150}}>
                   
                    <Box pt={2}>
                        <TextField 
                            label="Nama Customer" 
                            size="small"
                            type="text"
                            name="name"
                            onChange={(e) => setFormLeads({...formLeads, name : e.target.value})} 
                            fullWidth
                            required
                        />
                    </Box>
                    <Box pt={2}>
                        <TextField 
                            label="WhatsApp" 
                            size="small"
                            type="number"
                            name="name"
                            onChange={(e) => setFormLeads({...formLeads, whatsapp_number : e.target.value})} 
                            fullWidth
                            required
                        />
                    </Box>
                    <Box pt={2}>
                        <TextField 
                            label="Request Customer" 
                            size="small"
                            type="text"
                            name="name"
                            onChange={(e) => setFormLeads({...formLeads, requestCusts : e.target.value})} 
                            fullWidth
                            required
                        />
                    </Box>
                </Stack>
                <DialogActions>
                    <Box my={2} mr={2}>
                        <Button 
                            variant="contained"
                            size="medium"
                            color="success"
                            type='submit'
                            disabled={loading_create}
                            >
                            { loading_create ? "Loading.." : "Submit" }
                        </Button> 
                    </Box>
                </DialogActions>
                </form>
            </Dialog>

        </Box>
    )
}



export default ProductPage
