import { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import BreadCrumbs from '../../../components/BreadCrumbs';
import TableData from '../../../components/TableData'
import { TableColumn } from 'react-data-table-component';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import {  useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../app/store';
import { getAllBiChecking, updateBiChecking } from './reducers/carikanPropertiReducers';
import React from 'react'
import { DialogContent } from '@mui/material';

function CarikanProperti() {
    const dispatch = useDispatch()
    const { data, loading,  update, loading_update } = useSelector((state : RootState) => state.carikan_properti)

    const [dataDetail, setDataDetail] = useState<any>({});
    const [open, setOpen] = useState(false);
    const [score, setScore] = useState("");

    const [limit, setLimit] = useState(15);
    const [page, setPage] = useState(1);

    const handleClose = () => {
       setOpen(false)
    };

    const onSubmit = (e: any): void => {
        e.preventDefault()

        let data_send = {
            id : dataDetail?._id,
            body : {
                score : score,
                status : 'done'
            }
        }
        dispatch(updateBiChecking(data_send))
    }

    const columns: TableColumn<any>[] = [
        {
            name: 'NO',
            width: '80px',
            cell: (row, index) => (
                <p>
                { page > 1 ? 
                    <>
                    {index + 1 + (limit * (page-1))}
                    </>
                    :
                    <> 
                    {index + 1}
                    </>
                }
                </p>
            )
        },
        {
            name: 'Nama',
            cell: (row) => (
                <div>
                    <a href={row.url_blog} target="_blank" rel="noopener noreferrer">
                    {row.name}
                    </a>
                </div>
            )
        },
        {
            name: 'Whatsapp',
            cell: (row) => (
                <div>{row.phone_number}</div>
            )
        },
        {
            name: 'Lokasi',
            cell: (row) => (
                <div>{row.location}</div>
            )
        },
        {
            name: 'Tipe',
            cell: (row) => (
                <div>{row.type}</div>
            )
        },
        {
            name: 'Range Harga',
            cell: (row) => (
                <div>Rp. {row.price_min} - Rp. {row.price_max}</div>
            )
        },
        {
            name: 'ACTION',
            width: '200px',
            cell: (row ) => (
                <Stack direction="row" spacing={2}>
                    <Button 
                        variant="outlined" color="primary" size="small"
                        onClick={() => {
                            setDataDetail(row)
                            setOpen(true)
                        }}
                    >
                     Detail
                    </Button>
                </Stack>
            ),
        },
    ];
    
    useEffect(() => {
       if(update) {
        handleClose()
        dispatch(getAllBiChecking())
       }
        // eslint-disable-next-line
    },  [update]);

    useEffect(() => {
         dispatch(getAllBiChecking())
         // eslint-disable-next-line
     },  []);



    return (
        <div>
            <Box pb={2}>
                <BreadCrumbs
                    current="Carikan Properti"
                    isPage={false}
                />
            </Box>
                <h3>Carikan Properti</h3>
            <Box pt={2}>
                <TableData 
                    columns={columns}
                    data={data}
                    progressPending={loading}
                    pagination
                    onChangePage={(value:any) => setPage(value)}
                    onChangeRowsPerPage={(value:any) => setLimit(value)}
                    paginationPerPage={limit}
                />
            </Box>

            <Dialog 
                open={open} 
                fullWidth={true}
                maxWidth="sm"
            >
                <form onSubmit={onSubmit} >
                    <DialogTitle>Detail Data Carikan Properti</DialogTitle>
                    <DialogContent>
                        <Box>
                            <Box>Nama : <strong> {dataDetail?.name}</strong></Box>
                            <Box>Email : <strong> {dataDetail?.email}</strong></Box>
                            <Box>Whatsapp : <strong> {dataDetail?.phone_number}</strong></Box>

                            <Box pt={2}>Range Harga : <strong> Rp. {dataDetail.price_min} - Rp. {dataDetail.price_max}</strong></Box>
                            <Box >Lokasi : <strong> {dataDetail?.location}</strong></Box>
                            <Box>Kamar Mandi : <strong> {dataDetail?.bathroom}</strong></Box>
                            <Box>Kamar Tidur : <strong> {dataDetail?.bedroom}</strong></Box>
                            <Box>Range Luas Bangunan : <strong> {dataDetail?.building_area_min} - {dataDetail?.building_area_max}</strong></Box>
                            <Box>Range Luas Tanah : <strong> {dataDetail?.land_area_min} - {dataDetail?.land_area_max}</strong></Box>
                            <Box>Fasilitas Lain : <strong> {dataDetail?.other_specification}</strong></Box>
                            
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="warning">Close</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    )   
}

export default CarikanProperti
