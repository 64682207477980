import { createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios'

export const getAllWithdraw = createAsyncThunk(
    'withdraw/fetch', 
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await Axios.get(`${process.env.REACT_APP_API_DEV}/withdrawal/admin`)
            if(response.data.errors === null) { 
                return {data : response.data.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})

export const updateWithdraw = createAsyncThunk(
    'withdraw/update', 
    async (data : any, { rejectWithValue }) => {
        try {
            const response : any = await Axios.put(`${process.env.REACT_APP_API_DEV}/withdrawal/${data.id}`, data.body)
            if(response.data.errors === null) { 
                return {data : true, message : "Success proses withdraw"}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})