import React, { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import BreadCrumbs from '../../../../components/BreadCrumbs';
import TableData from '../../../../components/TableData'
import { TableColumn } from 'react-data-table-component';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import {  useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../app/store';
import { getUserAgentScrapping } from './reducers/userAgentssReducers';
import Select from 'react-select'
import LoaderBackdrop from '../../../../components/LoaderBackdrop';
import { getAllMasterIndikator } from '../master_indikator/reducers/masterIndikatorReducers';
// @ts-ignore
import ReactExport from "react-export-excel";
import moment from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  
const DownloadExcel : React.FC<any> = ({ data }) => {
    return (
        <Box py={1}>
            <ExcelFile 
                filename={`Download Scraping Agent on ${moment().format('ll')}`}  
                element={(
                <Button
                    variant="contained"
                    color="success" 
                    size="small"
                >Export Excel</Button>
                )}>
                <ExcelSheet data={data} name="scraping">
                    <ExcelColumn label="Agent" value="agent" style={{ alignment: {wrapText: true, horizontal: 'center', vertical: 'top'} }}/>
                    <ExcelColumn label="No Agent" value="telephone"/>
                </ExcelSheet>
            </ExcelFile>
        </Box>
    );
}


function ScrapingAgents() {
    const dispatch = useDispatch()
    const store_user_agents = useSelector((state : RootState) => state.user_scraping)
    const { 
        loading_filter_area,
    } = useSelector((state : RootState) => state.products)
    const { data } = useSelector((state : RootState) => state.indikator)
    
    const [valueInput, setValueInput] = useState<any>({
        name : ""
    });

    const [limit, setLimit] = useState(15);
    const [page, setPage] = useState(1);

    const [open, setOpen] = useState({
        open : false,
        name : ""
    });

    const [optionsFilterArea, setOptionsFilterArea] = useState<any[]>([]);
    const [selectedFilterArea, setSelectedFilterArea] = useState<any>([]);

    const handleClose = () => {
        setOpen({...open, open : false, name : "" });
        setValueInput({...valueInput, name : "" });
    };

    const onSubmit = (e: any): void => {
        e.preventDefault()
        // dispatch(postHouseTitle(valueInput.name))
    }
    
    const handleChangeFilterArea = (value: any) : void => {
        setSelectedFilterArea(value)
    }

    useEffect(() => {
        if(data.length !== 0) {
            let data_area : any = []
            for(let k of data) {
                data_area.push({
                    label : k.name.toLowerCase(),
                    value : k._id
                })
            }
            setOptionsFilterArea(data_area)
        }
    }, [data]);


    const columns: TableColumn<any>[] = [
        {
            name: 'NO',
            width: '80px',
            cell: (row, index) => (
                <p>
                { page > 1 ? 
                    <>
                    {index + 1 + (limit * (page-1))}
                    </>
                    :
                    <> 
                    {index + 1}
                    </>
                }
                </p>
            )
        },
        {
            name: 'NAME',
            cell: (row) => (
                <div>{row.agent}</div>
            )
        },
        {
            name: 'HANDPHONE',
            cell: (row) => (
                <div>{row.telephone}</div>
            )
        },
       
        // {
        //     name: 'ACTION',
        //     width: '200px',
        //     cell: (row ) => (
        //         <Stack direction="row" spacing={2}>
        //             <Button 
        //                 variant="outlined" color="primary" size="small"
        //                 onClick={() => {
        //                     setValueInput({...valueInput, value : row.value})
        //                     handleClickOpen("Edit")
        //                 }}
        //             >
        //              Edit
        //             </Button>
        //         </Stack>
        //     ),
        // },
    ];
    
    useEffect(() => {
        const local :any= localStorage.getItem('filter_area_agent')
        const data = {
            area_id : local ? JSON.parse(local).area_id : "",
        }
        if(local) {
            setSelectedFilterArea({value: JSON.parse(local).area_id, label: JSON.parse(local).area_id})
        }
        dispatch(getUserAgentScrapping(data))
        // eslint-disable-next-line
    },  []);


    const onClickSearch = () => {
        const data = {
            area_id : selectedFilterArea.label,
        }
        localStorage.setItem('filter_area_agent', JSON.stringify(data))
        dispatch(getUserAgentScrapping(data))
        // localStorage.setItem('filter_area', JSON.stringify(data))
        // dispatch(getAllPropertyScraping(data))
    }

    useEffect(() => {
        dispatch(getAllMasterIndikator(""))
        // eslint-disable-next-line
    }, []);


    return (
        <div>
            <Box pb={2}>
                <BreadCrumbs
                    current="User Agents"
                    isPage={false}
                />
            </Box>
            <Stack direction="row" justifyContent="space-between" spacing={2}> 
                <h3>User Agents</h3>
                {/* <Button 
                    variant="contained" color="primary" size="small" 
                    onClick={() => {
                        handleClickOpen("Create")
                    }}
                >
                    Create New
                </Button>  */}
            </Stack>
            { loading_filter_area ? <LoaderBackdrop loading={loading_filter_area} />  :
                <Stack direction="row" justifyContent="space-between" sx={{pt:2}}>
                    {/* <Box>Pilih Area</Box> */}
                    <Box sx={{width : '100%'}}>
                        <Select
                            placeholder="Pilih Area"
                            value={selectedFilterArea}
                            isSearchable={true}
                            options={optionsFilterArea && optionsFilterArea}
                            onChange={handleChangeFilterArea}
                            id="select-style-type"
                        /> 
                    </Box>

                    

                    <Box pl={2}>
                        <Button variant="contained" onClick={onClickSearch} disabled={selectedFilterArea.length === 0 ? true : false}>Cari</Button>
                    </Box>
                </Stack>
            }
            <Box pt={2}>
                <Stack direction="row" justifyContent="space-between" >
                    <Box pb={2}>Total Data : {store_user_agents.data_count}</Box>
                    <Box>
                        <DownloadExcel  data={store_user_agents.data}/>
                    </Box>
                </Stack>
                <TableData 
                    columns={columns}
                    data={store_user_agents.data}
                    progressPending={store_user_agents.loading}
                    pagination
                    onChangePage={(value) => setPage(value)}
                    onChangeRowsPerPage={(value) => setLimit(value)}
                    paginationPerPage={limit}
                />
            </Box>

            <Dialog 
                open={open.open} 
                fullWidth={true}
                maxWidth="sm"
                
            >
                <form onSubmit={onSubmit} >
                    <DialogTitle>{open.name} Title</DialogTitle>
                    <div className="box-modal-create">
                        <TextField
                            margin="normal"
                            size="small"
                            fullWidth
                            id="name"
                            label="Name"
                            name="name"
                            type="text"
                            value={valueInput.name}
                            onChange={(e) => setValueInput({...valueInput, name : e.target.value})}
                        />
                    </div>
                    <DialogActions>
                        <Button onClick={handleClose} color="warning">Cancel</Button>
                        <Button type="submit" variant="outlined">{ store_user_agents.loading_create ? "Loading.." : "Save" }</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    )   
}

export default ScrapingAgents
