import React, { useState, useEffect } from 'react';
import { 
    Stack, 
    Paper, 
    Box, 
    Grid,
    Typography,
    TextField,
    Button,
    CircularProgress,
    Skeleton
} from '@mui/material';
import BreadCrumbs from '../../../../components/BreadCrumbs';
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { postCreateProducts, removeProduct } from '../reducers/productsReducers';
import swal from 'sweetalert';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ImagesItems from './components/ImagesItems'


import AddressForm from '../../../../components/AddressForm';
import { userCredentials } from '../../../../utilities/config';
import { getAllHouseType } from '../../MasterData/property_type/reducers/propertyTypeReducers';
import { getAllHouseTitle } from '../../MasterData/property_title/reducers/propertyTitleReducers';
import { getAllExtraFacilities } from '../../MasterData/extra_facilities/reducers/extraFacilitiesReducers';
import { useHistory } from 'react-router-dom';
import { postProceedHouse } from '../../Scraping/scraping_land/reducers/scrapingLandReducers';
import Swal from 'sweetalert2';
import TextAreaEditor from './components/TextAreaEditor';

let images_items = [
    {
        image : ""
    },
    {
        image : ""
    },
    {
        image : ""
    },
    {
        image : ""
    }
]


const CreateNewProperty = () => {

    const dispatch = useDispatch()
    const history : any = useHistory()
    const store_product = useSelector((state : RootState) => state.products) 


    const [dataProperty, setDataProperty] = useState<any>({});

    const [imagesItems, setImagesItems] = useState<any>(images_items);

    const [valueAddress, setValueAddress] = useState({
        province : "",
        province_id : "",
        error_province : false,
        city : "",
        city_id : "",
        error_city : false,
        subdistrict : "",
        subdistrict_id : "",
        error_subdistrict : false,
        village : "",
        village_id : "",
        error_village : false,
        zipcode : "",
        street : ""
    });

    const [selectTypeSell, setSelectTypeSell] = useState("jual");
    const [recomendation, setRecomendation] = useState("REGULAR");

    const [valueProduct, setValueProduct] = useState<any>({
        image : "",
        description: "",
        price: "",
        overprice: "",
        gmap_link : "",
        discount: "",
        land_area : "",
        building_area : "",
        bedroom : "",
        bathroom : "",
    });


    const [loaded] = useState(true);
    const [valueDescription, setValueDescription] = useState("");
    const [errorDescription, setErrorDescription] = useState(false);



    const [optionsPropertyType, setOptionsPropertyType] = useState<any[]>([]);
    const [loadingType, setLoadingType] = useState<any>(true);
    const [selectedPropertyType, setSelectedPropertyType] = useState<any>([]);
    const [errorPropertyType, setErrorPropertyType] = useState<boolean>(false);

    const [optionsPropertyTitle, setOptionsPropertyTitle] = useState<any[]>([]);
    const [loadingTitle, setLoadingTitle] = useState<any>(true);
    const [selectedPropertyTitle, setSelectedPropertyTitle] = useState<any>([]);
    const [errorPropertyTitle, setErrorPropertyTitle] = useState<boolean>(false);

    const [optionsExtraFacility, setOptionsExtraFacility] = useState<any[]>([]);
    const [loadingExtraFacility, setLoadingExtraFacility] = useState<any>(true);
    const [selectedExtraFacility, setSelectedExtraFacility] = useState<any>([]);
    const [errorExtraFacility, setErrorExtraFacility] = useState<boolean>(false);

    const handleChangePropertyTitle = (value: any) : void => {
        setErrorPropertyTitle(false)
        setSelectedPropertyTitle(value)
    }

    const handleChangePropertyType = (value: any) : void => {
        setErrorPropertyType(false)
        setSelectedPropertyType(value)
    }

    const handleChangeExtraFacility = (value: any) : void => {
        setErrorExtraFacility(false)
        setSelectedExtraFacility(value)
    }
 

    const onchangeValue = (e : any) => {
        const { name, value } = e.target
        if(value < 0) {
            swal('Error', 'Value must be greater than 0', 'error')
        } else {
            setValueProduct({...valueProduct, [name] : value })
        }
    }

    

    const checkError = () => {
        let error = true
        if(selectedPropertyType.length === 0) {
            setErrorPropertyType(true)
            error = true
        } else if (selectedPropertyTitle.length === 0) {
            setErrorPropertyTitle(true)
            error = true
        } else if (selectedExtraFacility.length === 0) {
            setErrorExtraFacility(true)
            error = true
        } else if (imagesItems[0].image === "") {
            swal("Error","Please upload at least one image!",'error')
            error = true
        } else {
            error = false
        }
        return error
    }


    const getExtraFacility = (data: any) => {
        let extra = []
        for(let i of data) {
            extra.push(i.label)
        }
        return extra
    }

    const getImagesArray = (data: any) => {
        let images = []
        for(let i of data) {
            images.push(i.image)
        }
        let filter = images.filter(ele => ele !== "")
        return filter
    }

    const onClickSubmit = (e : any) => {
        e.preventDefault()

        let data_submit = {
            "type": selectedPropertyType.value,
            "master_trait": selectedPropertyTitle.value,
            "sell_type": selectTypeSell,
            "images": getImagesArray(imagesItems),
            "geo_location": {
                "longitude": 100.6,
                "latitude": 90.2323
            },
            "price": {
                "title": "",
                "value": parseInt(valueProduct.price)
            },
            "overPrice": {
                "title": "",
                "value": parseInt(valueProduct.overprice)
            }, 
            "title": "",
            "agent_id": "admin",
            "agent_name": userCredentials.name,
            "contact_number": "6281928173",
            "short_description": "",
            "long_description": valueDescription,
            "area": {
                "country": "Indonesia",
                "country_id": "+62",
                "province": valueAddress.province,
                "province_id":valueAddress.province_id,
                "city": valueAddress.city,
                "city_id": valueAddress.city_id,
                "district": valueAddress.subdistrict,
                "district_id": valueAddress.subdistrict_id,
                "village": valueAddress.village,
                "village_id": valueAddress.village_id,
                "zip_code": valueAddress.zipcode,
                "street_number": valueAddress.street
            },
            "facilities": {
                "land_area": parseInt(valueProduct.land_area), 
                "building_area": parseInt(valueProduct.building_area),
                "bedroom": parseInt(valueProduct.bedroom),
                "bathroom": parseInt(valueProduct.bathroom)
            },
            "extras": getExtraFacility(selectedExtraFacility),
            "status": "ACTIVE",
            "gmap_link": valueProduct.gmap_link,
            "recomendation": recomendation,
        }
        if(!checkError()) {
            onClickProceedFollowUp(dataProperty)
            dispatch(postCreateProducts(data_submit))
        } 
    }

    useEffect(() => { 
        if(store_product.success_create) {
            setTimeout(() => {
                window.location.href = "/dashboard/products"
            }, 1000);
        }
        // eslint-disable-next-line
    }, [store_product.success_create]);

    useEffect(() => {
        if(history.location.state){
            setDataProperty(history.location.state.data)
        } else {
            window.location.href = "/dashboard/products"
        }
    }, [history.location.state]);

    useEffect(() => {
        if(history.location.state) {
            const state_data : any = history.location.state.data
            setSelectedPropertyType({ value: "rumah", label : "Rumah" })
            setValueProduct({...valueProduct, 
                description: state_data.short_description,
                price: state_data.price.value,
                overprice: state_data.overPrice.value,
                gmap_link : "",
                land_area : state_data.facilities.land_area,
                building_area : state_data.facilities.building_area,
                bedroom : state_data.facilities.bedroom,
                bathroom : state_data.facilities.bathroom,
            })
            setValueAddress(state_data.area)
        }
        // eslint-disable-next-line
    }, [history.location.state]);


    useEffect(() => {
        const getHouseType = async () => {
            const house_type : any = await dispatch(getAllHouseType())
            if(house_type.payload.data) {
                let data = house_type.payload.data
                let array = []
                for(let k of data) {
                    array.push({ value : k.name, label : k.name })
                }
                setOptionsPropertyType(array)
                setLoadingType(false)
            }
        }
        const getHouseTitle = async () => {
            const house_title : any = await dispatch(getAllHouseTitle())
            if(house_title.payload.data) {
                let data = house_title.payload.data
                let array = []
                for(let k of data) {
                    array.push({ value : k.name, label : k.name })
                }
                setOptionsPropertyTitle(array)
                setLoadingTitle(false)
            }
        }
        const getHouseFacility = async () => {
            const extra_facility : any = await dispatch(getAllExtraFacilities())
            if(extra_facility.payload.data) {
                let data = extra_facility.payload.data
                let array = []
                for(let k of data) {
                    array.push({ value : k.name, label : k.name })
                }
                setOptionsExtraFacility(array)
                setLoadingExtraFacility(false)
            }
        }
        getHouseFacility()
        getHouseTitle()
        getHouseType()
        // eslint-disable-next-line
    },  []);


    const onClickProceedFollowUp = (row:any) : void => {
        const body : any = []
        body.push({ 
            address: row.address,
            agent: row.agent,
            bathroom: parseInt(row.bathroom),
            bedroom: parseInt(row.bedroom),
            building_area: row.building_area,
            images: row.images[0],
            land_area: row.land_area,
            linrow: row.linrow,
            location: row.location,
            price_title: row.price_title,
            price_value: parseInt(row.price_value),
            property_type: row.property_type,
            source: row.source,
            telephone: row.telephone,
            status : "FOLLOW_UP",
            title: row.title,
            _id: row._id,
            note : "",
            history_status : [
                { 
                    "status" : "FOLLOW_UP",
                    "note" : "Property is follow up by autopost",
                }
            ]
        })
        dispatch(postProceedHouse(body))
    }

    const onClickProceed = (row:any) : void => {
        Swal.fire({
            title : "Confirmation",
            text: `Are you sure this property ${row.short_description} is not found?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: 'primary', 
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Remove it!',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return dispatch(removeProduct(row._id))
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/dashboard/auto-post"
          }
        })
    }

    const resetAllField = () => {
        window.location.reload()
    }

    return (
        <Box sx={{pt:2, pl:3, pr:3}}>
            <BreadCrumbs
                isPage={true}
                link="/dashboard/auto-post"
                page="Listing Autopost"
                current="Edit Autopost"
            />
            <Stack direction="row" justifyContent="space-between" pt={3} >
                <Box>
                    <h3>Edit Autopost</h3>
                </Box>
            </Stack>

             { dataProperty.status && 
            <Stack mt={4} mb={8}>
                <form onSubmit={onClickSubmit}>
                <Stack mb={2}>
                    <Paper elevation={2}>
                        <Stack p={4}>
                            <Box pb={2}><Typography variant="h6" fontWeight="600">Data Properti</Typography></Box>
                            <Grid container spacing={4}>
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Typography variant="body1" fontWeight="500">Link Properti</Typography>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    <a href={dataProperty.link} target="_blank" rel=" noopener noreferrer">{dataProperty.link}</a>
                                    <Box 
                                        pt={1}
                                        sx={{ cursor: "pointer", color: '#c24770' }}
                                        onClick={() => onClickProceed(dataProperty)}
                                    >Klik disini jika properti tidak ditemukan</Box>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Paper> 
                </Stack>
                <Stack mb={2}>
                    <Paper elevation={2}>
                        <Stack p={4}>
                            <Box pb={2}><Typography variant="h6" fontWeight="600">Upload Images</Typography></Box>
                            <Grid container spacing={4}>
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Typography variant="body1" fontWeight="500">Property Images</Typography>
                                    <Typography variant="body2" fontWeight="300">Format gambar .jpg .jpeg .png dan ukuran minimum 300 x 300px </Typography>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    <ImagesItems 
                                        imagesItems={imagesItems}
                                        setImagesItems={setImagesItems}
                                    />
                                </Grid>
                            </Grid>
                            <Box pt={1}><a href={dataProperty.images} target="_blank" rel=" noopener noreferrer">{dataProperty.images}</a></Box>
                        </Stack>
                    </Paper> 
                </Stack>
                <Stack mb={2} flexDirection="column" alignContent="center" justifyContent="center" >
                    <Paper elevation={2}>
                        <Stack p={4}>
                            <Box pb={2}><Typography variant="h6" fontWeight="600">Property Informatiosssn</Typography></Box>
                            <Grid container columnSpacing={3} rowSpacing={2}>
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Typography variant="body1" fontWeight="500">Property Type</Typography>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    { loadingType ? 
                                    <Skeleton height={50}/>
                                    :
                                    <Select
                                        placeholder="Select Type"
                                        value={selectedPropertyType}
                                        isSearchable={true}
                                        options={optionsPropertyType && optionsPropertyType}
                                        onChange={handleChangePropertyType}
                                        id="select-style-type"
                                    /> }
                                    { errorPropertyType ? <div className="error-p"><p>Property Type is required</p></div> : null }
                                </Grid>
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Typography variant="body1" fontWeight="500">Property Title</Typography>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    { loadingTitle ? 
                                    <Skeleton height={50}/>
                                    :
                                    <Select
                                        placeholder="Select Title"
                                        value={selectedPropertyTitle}
                                        isSearchable={true}
                                        options={optionsPropertyTitle && optionsPropertyTitle}
                                        onChange={handleChangePropertyTitle}
                                        id="select-style-title"
                                    /> }
                                    { errorPropertyTitle ? <div className="error-p"><p>Property Title is required</p></div> : null }
                                </Grid>
                                <Grid item xl={3} lg={4} xs={12}>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    <FormGroup>
                                        <Stack flexDirection="row">
                                            <Box>
                                                <FormControlLabel 
                                                    control={
                                                        <Checkbox 
                                                            checked={selectTypeSell === "jual" ? true : false} 
                                                            onChange={() => {
                                                               setSelectTypeSell("jual")
                                                            }}
                                                        />} 
                                                    label="Jual" 
                                                />
                                            </Box>
                                            <Box>
                                                <FormControlLabel 
                                                    control={
                                                        <Checkbox 
                                                            checked={selectTypeSell === "sewa" ? true : false} 
                                                            onChange={() => {
                                                               setSelectTypeSell("sewa")
                                                            }}
                                                        />} 
                                                    label="Sewa" 
                                                />
                                            </Box>
                                        </Stack>
                                    </FormGroup>
                                </Grid>
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Typography variant="body1" fontWeight="500">Recommendation</Typography>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    <FormGroup>
                                        <Stack flexDirection="row">
                                            <Box>
                                                <FormControlLabel 
                                                    control={
                                                        <Checkbox 
                                                            checked={recomendation === "REGULAR" ? true : false} 
                                                            onChange={() => {
                                                               setRecomendation("REGULAR")
                                                            }}
                                                        />} 
                                                    label="Regular" 
                                                />
                                            </Box>
                                            <Box>
                                                <FormControlLabel 
                                                    control={
                                                        <Checkbox 
                                                            checked={recomendation === "CHOSEN" ? true : false} 
                                                            onChange={() => {
                                                               setRecomendation("CHOSEN")
                                                            }}
                                                        />} 
                                                    label="Recommended" 
                                                />
                                            </Box>
                                        </Stack>
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Paper> 
                </Stack>
                <Stack mb={2} flexDirection="column" alignContent="center" justifyContent="center" >
                    <Paper elevation={2}>
                        <Stack p={4}>
                            <Box pb={2}><Typography variant="h6" fontWeight="600">Property Detail</Typography></Box>
                            <Grid container columnSpacing={3} rowSpacing={2}>
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Typography variant="body1" fontWeight="500">Fair Price</Typography>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    <TextField 
                                        label="Fair Price"
                                        size="small"
                                        type="number"
                                        name="price"
                                        onChange={(e) => onchangeValue(e)}
                                        value={valueProduct.price}
                                        fullWidth
                                        required
                                    />
                                </Grid> 
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Typography variant="body1" fontWeight="500">Over Price</Typography>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    <TextField 
                                        label="Over Price"
                                        size="small"
                                        type="number"
                                        name="overprice"
                                        onChange={(e) => onchangeValue(e)}
                                        value={valueProduct.overprice}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Typography variant="body1" fontWeight="500">Facility</Typography>
                                </Grid>
                                <Grid item xl={2} lg={2} xs={12}>
                                    <TextField 
                                        label="Luas Tanah in m2"
                                        size="small"
                                        type="number"
                                        name="land_area"
                                        onChange={(e) => onchangeValue(e)}
                                        value={valueProduct.land_area}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xl={2} lg={2} xs={12}>
                                    <TextField 
                                        label="Luas Bangunan in m2"
                                        size="small"
                                        type="number"
                                        name="building_area"
                                        onChange={(e) => onchangeValue(e)}
                                        value={valueProduct.building_area}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xl={2} lg={2} xs={12}>
                                    <TextField 
                                        label="Kamar Tidur"
                                        size="small"
                                        type="number"
                                        name="bedroom"
                                        onChange={(e) => onchangeValue(e)}
                                        value={valueProduct.bedroom}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xl={2} lg={2} xs={12}>
                                    <TextField 
                                        label="Kamar Mandi"
                                        size="small"
                                        type="number"
                                        name="bathroom"
                                        onChange={(e) => onchangeValue(e)}
                                        value={valueProduct.bathroom}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Typography variant="body1" fontWeight="500">Extra Facility</Typography>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    { loadingExtraFacility ? 
                                    <Skeleton height={50}/>
                                    :
                                    <Select
                                        placeholder="Select Extra Facility"
                                        value={selectedExtraFacility}
                                        isSearchable={true}
                                        isMulti
                                        options={optionsExtraFacility && optionsExtraFacility}
                                        onChange={handleChangeExtraFacility}
                                        id="select-style-facility"
                                    /> }
                                    { errorExtraFacility ? <div className="error-p"><p>Extra Facility is required</p></div> : null }
                                </Grid>
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Typography variant="body1" fontWeight="500">Google Map Link</Typography>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    <TextField 
                                        label="Link" 
                                        size="small"
                                        type="text"
                                        name="gmap_link"
                                        onChange={(e) => onchangeValue(e)}
                                        value={valueProduct.gmap_link}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Typography variant="body1" fontWeight="500">Description</Typography>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    <TextAreaEditor 
                                        value={loaded && valueDescription}
                                        setValue={setValueDescription}
                                        error={errorDescription}
                                        setError={setErrorDescription}
                                        placeholder="Type description here..."
                                        loaded={loaded}
                                    />
                                </Grid>
                            </Grid>
                        </Stack>
                    </Paper> 
                </Stack>

                <Stack mb={2} flexDirection="column" alignContent="center" justifyContent="center" >
                    <Paper elevation={2}>
                        <Stack p={4}>
                            <Box pb={2}><Typography variant="h6" fontWeight="600">Property Address</Typography></Box>
                            <AddressForm 
                                valueAddres={valueAddress}
                                setValueAddress={setValueAddress} 
                            />
                        </Stack>
                    </Paper> 
                </Stack>
                <Stack >
                    <Stack pt={4} flexDirection="row" justifyContent="space-between">
                        <Button 
                            variant='outlined' color="error" 
                            onClick={resetAllField}
                        >
                          Reset Field
                        </Button>
                        <Button variant='contained' color="primary" type="submit" disabled={store_product.loading_create ? true : false}>
                           { store_product.loading_create ? <span>Loading.. <CircularProgress color='inherit' size={20}/></span> : "Post Property" } 
                        </Button>
                    </Stack>
                </Stack>
                </form>
            </Stack> }
        </Box>
    )
}

export default CreateNewProperty;
