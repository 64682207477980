import { createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios'

export const getAllScrapingHouse = createAsyncThunk(
    'house-scraping/fetch', 
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await Axios.get(`${process.env.REACT_APP_API_DEV}/house-master`, {
                params: {
                  property_type : "rumah",
                  area_id : "61977010e211097d872972ed",
                  perPage: 10,
                }}
            )
            if(response.data) { 
                return {data : response.data.data.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})



export const getAllScrapingData = createAsyncThunk(
    'house-scraping/fetch', 
    async (param : any, { rejectWithValue }) => { 
        try {
            const response : any = await Axios.get(`${process.env.REACT_APP_API_DEV}/house-master`, {
                params: {
                  property_type : "rumah",
                  area_id : param.area_id,
                  location : param.area_name,
                  perPage: param.limit,
                  page : param.page,
                  search_keyword : param.search
                }}
            )
            if(response.data) { 
                return  response.data.data
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})

export const postProceedHouse = createAsyncThunk(
    'house-scraping/post', 
    async (body : any, { rejectWithValue }) => { 
        try {
            const response : any = await Axios.post(`${process.env.REACT_APP_API_DEV}/house-processed`, body )
            if(response.data.errors === null) { 
                return {data : true, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})


export const deletePropertyScrapingHouse = createAsyncThunk(
    'house-scraping/delete', 
    async (id:string, { rejectWithValue }) => { 
        try {
            const response : any = await Axios.delete(`${process.env.REACT_APP_API_DEV}/house-master/${id}`)
            if(response.data) { 
                return {data : true, message : "Delete scraping house master is successfully!"}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})

