import { createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios'

export const getAllScrapingHouse = createAsyncThunk(
    'house-scraping/fetch', 
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await Axios.get(`${process.env.REACT_APP_API_DEV}/house-master`, {
                params: {
                  property_type : "tanah",
                }}
            )
            if(response.data) { 
                return {data : response.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})


export const postProceedHouse = createAsyncThunk(
    'house-scraping/post', 
    async (body : any, { rejectWithValue }) => { 
        try {
            const response : any = await Axios.post(`${process.env.REACT_APP_API_DEV}/house-processed`, body )
            if(response.data.errors === null) { 
                return {data : true, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})
