import { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import BreadCrumbs from '../../../../components/BreadCrumbs';
import TableData from '../../../../components/TableData'
import { TableColumn } from 'react-data-table-component';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import {  useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../app/store';
import {  getAllMasterIndikator, postMasterIndikator, removeMasterIndikator, updateMasterIndikator } from './reducers/masterIndikatorReducers';
import React from 'react'
import SearchComponent from '../../../../components/SearchComponent';

    
function MasterIndikator() {
    const dispatch = useDispatch()
    const store_master_indikator = useSelector((state : RootState) => state.indikator)
    
    const [dataValue, setDataValue] = useState<any>({
        lokasi : "",
        harga_tanah : null,
        harga_bangunan : null,
        apartment_price: null,
        id : ''
    });

    const [valueSearch, setValueSearch] = useState<string>("");
    

    const onChangeValue = (event:any) => {
        const { name, value } = event.target
        setDataValue({...dataValue, [name] : value})
    }

    const [limit, setLimit] = useState(15);
    const [page, setPage] = useState(1);

    const [open, setOpen] = useState({
        open : false,
        name : ""
    });

    const handleClickOpen = (name : string) => {
        setOpen({...open, open : true, name : name });
    };

    const handleClose = () => {
        setOpen({...open, open : false, name : "" });
        setDataValue({...dataValue, 
            lokasi : "",
            harga_tanah : null,
            harga_bangunan : null,
            id : ''
        })
    };  

    const onSubmit = (e: any): void => {
        e.preventDefault()
        if(open.name === "Edit") {
            dispatch(updateMasterIndikator(dataValue))
        } else {
            dispatch(postMasterIndikator(dataValue))
        }
    }

    const columns: TableColumn<any>[] = [
        {
            name: 'NO',
            width: '80px',
            cell: (row, index) => (
                <p>
                { page > 1 ? 
                    <>
                    {index + 1 + (limit * (page-1))}
                    </>
                    :
                    <> 
                    {index + 1}
                    </>
                }
                </p>
            )
        },
        {
            name: 'LOCATION',
            cell: (row) => (
                <div>{row.name}</div>
            )
        },
        {
            name: 'LAND PRICE',
            cell: (row) => (
                <div>Rp. {row.land_price && row.land_price.toLocaleString()}</div>
            )
        },
        {
            name: 'BUILDING PRICE',
            cell: (row) => (
                <div>Rp. {row.building_price && row.building_price.toLocaleString()}</div>
            )
        },
        {
            name: 'APARTMENT PRICE',
            cell: (row) => (
                <div>Rp. { row.apartment_price ? row.apartment_price.toLocaleString() : 0 }</div>
            )
        },
        {
            name: 'TOTAL SCRAPING',
            cell: (row) => (
                <div>0</div>
            )
        },
        {
            name: 'ACTION',
            width: '200px',
            cell: (row ) => (
                <Stack direction="row" spacing={2}>
                    <Button 
                        variant="outlined" color="primary" size="small"
                        onClick={() => {
                            setDataValue({
                                ...dataValue, 
                                lokasi : row.name,
                                harga_tanah : row.land_price,
                                harga_bangunan : row.building_price,
                                apartment_price : row.apartment_price,
                                id : row._id,
                            })
                            handleClickOpen("Edit")
                        }}
                    >
                     Update
                    </Button>
                </Stack>
            ),
        },
    ];

    function getFetchData(params:string) {
        dispatch(getAllMasterIndikator(params))
    }
    
    useEffect(() => {
        handleClose()
        getFetchData(valueSearch)
        // eslint-disable-next-line
    },  [store_master_indikator.create, store_master_indikator.update, store_master_indikator.remove, valueSearch]);
    

    return (
        <div>
            <Box pb={2}>
                <BreadCrumbs
                    current="Master Indikator"
                    isPage={false}
                />
            </Box>
            <Stack direction="row" justifyContent="space-between" spacing={2}>
                <h3>Master Indikator</h3>
                <Button 
                    variant="contained" color="primary" size="small" 
                    onClick={() => {
                        handleClickOpen("Create")
                    }}
                >
                    Create New
                </Button> 
            </Stack>
            <SearchComponent 
                valueSearch={valueSearch}
                setValueSearch={setValueSearch}
                placeholder="Cari nama lokasi..."
            />
            <Box pt={4}>
                <TableData 
                    columns={columns}
                    data={store_master_indikator.data}
                    progressPending={store_master_indikator.loading}
                    pagination
                    onChangePage={(value) => setPage(value)}
                    onChangeRowsPerPage={(value) => setLimit(value)}
                    paginationPerPage={limit}
                />
            </Box>

            <Dialog 
                open={open.open} 
                fullWidth={true}
                maxWidth="sm"
                
            >
                <form onSubmit={onSubmit} >
                    <DialogTitle>{open.name} Indikator</DialogTitle>
                    <div className="box-modal-create">
                        <TextField
                            autoFocus
                            margin="normal"
                            size="small"
                            label="Lokasi"
                            type="text"
                            fullWidth
                            name="lokasi"
                            onChange={onChangeValue}
                            value={dataValue.lokasi}
                            required
                        />
                        <TextField
                            margin="normal"
                            size="small"
                            label="Harga Tanah"
                            type="number"
                            fullWidth
                            name="harga_tanah"
                            onChange={onChangeValue}
                            value={dataValue.harga_tanah}
                            required
                        />
                        <TextField
                            margin="normal"
                            size="small"
                            label="Harga Bangunan"
                            type="number"
                            fullWidth
                            name="harga_bangunan"
                            onChange={onChangeValue}
                            value={dataValue.harga_bangunan}
                            required
                        />
                        <TextField
                            margin="normal"
                            size="small"
                            label="Harga Apartemen"
                            type="number"
                            fullWidth
                            name="apartment_price"
                            value={dataValue.apartment_price}
                            onChange={onChangeValue}
                            required
                        />
                    </div>
                    <DialogActions>
                        <Button variant="outlined" onClick={handleClose} color="warning">Cancel</Button>
                        <Button variant="outlined" color="error" onClick={() => dispatch(removeMasterIndikator(dataValue))}>{ store_master_indikator.loading_remove ? "Loading.." : "Remove" }</Button>
                        <Button type="submit" variant="outlined">{ store_master_indikator.loading_create ? "Loading.." : "Save" }</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    )   
}

export default MasterIndikator
