import { createSlice } from '@reduxjs/toolkit';
import swal from 'sweetalert';
import { createNewLead, getAllLeads, getMessageByCustomer } from './reducers/leadsReducers';


const initialState: any = {
  data: [],
  loading : false,
  create : false,
  loading_create : false,
  update : false,
  loading_message : false,
  messages : [],
  remove : false,
  loading_remove : false,
};

export const leadSlice = createSlice({
  name: 'leads',
  initialState,
  reducers: {},
  extraReducers: {
    //   fetch title
    [getAllLeads.pending.type] : (state) => {
        state.loading = true
        state.create = false
        state.update = false
    },
    [getAllLeads.fulfilled.type] : (state, action) => {
        state.loading = false
        state.data = action.payload
    },
    [getAllLeads.rejected.type] : (state, action) => {
        state.loading = false
        swal('Error', `${action.payload}`, 'error')
    },
    // create title
    [createNewLead.pending.type] : (state) => {
        state.loading_create = true
    },
    [createNewLead.fulfilled.type] : (state, action) => {
        state.loading_create = false
        state.create = true
        swal('Success', `${action.payload.message}`, 'success')
    },
    [createNewLead.rejected.type] : (state, action) => {
        state.loading_create = false
        swal('Error', `${action.payload}`, 'error')
    },
    // update data
    [getMessageByCustomer.pending.type] : (state) => {
      state.loading_message = true
    },
    [getMessageByCustomer.fulfilled.type] : (state, action) => {
        state.loading_message = false
        state.messages = action.payload
    },
    [getMessageByCustomer.rejected.type] : (state, action) => {
        state.loading_message = false
        swal('Error', `${action.payload}`, 'error')
    },
  }
});

export default leadSlice.reducer;
