import { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import BreadCrumbs from '../../../../components/BreadCrumbs';
import TableData from '../../../../components/TableData'
import { TableColumn } from 'react-data-table-component';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import {  useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../app/store';
import { getAllHouseTitle, postHouseTitle, updateHouseTitle } from './reducers/propertyTitleReducers';
import React from 'react'

    
function PropertyTitle() {
    const dispatch = useDispatch()
    const store_property_title = useSelector((state : RootState) => state.property_title)
    
    const [valueInput, setValueInput] = useState<any>({
        name : "",
        id : ""
    });

    const [open, setOpen] = useState({
        open : false,
        name : ""
    });

    const [limit, setLimit] = useState(15);
    const [page, setPage] = useState(1);

    const handleClickOpen = (name : string) => {
        setOpen({...open, open : true, name : name });
    };

    const handleClose = () => {
        setOpen({...open, open : false, name : "" });
        setValueInput({...valueInput, name : "" });
    };

    const onSubmit = (e: any): void => {
        e.preventDefault()
       
        if(open.name === "Edit") {
            dispatch(updateHouseTitle(valueInput))
        } else {
            dispatch(postHouseTitle(valueInput.name))
        }
    }

    const columns: TableColumn<any>[] = [
        {
            name: 'NO',
            width: '80px',
            cell: (row, index) => (
                <p>
                { page > 1 ? 
                    <>
                    {index + 1 + (limit * (page-1))}
                    </>
                    :
                    <> 
                    {index + 1}
                    </>
                }
                </p>
            )
        },
        {
            name: 'NAME',
            cell: (row) => (
                <div>{row.name}</div>
            )
        },
        {
            name: 'ACTION',
            width: '200px',
            cell: (row ) => (
                <Stack direction="row" spacing={2}>
                    <Button 
                        variant="outlined" color="primary" size="small"
                        onClick={() => {
                            setValueInput({...valueInput, name : row.name, id: row._id})
                            handleClickOpen("Edit")
                        }}
                    >
                     Edit
                    </Button>
                </Stack>
            ),
        },
    ];
    
    useEffect(() => {
        handleClose()
        dispatch(getAllHouseTitle())
        // eslint-disable-next-line
    },  [store_property_title.create, store_property_title.update]);



    return (
        <div>
            <Box pb={2}>
                <BreadCrumbs
                    current="Property Title"
                    isPage={false}
                />
            </Box>
            <Stack direction="row" justifyContent="space-between" spacing={2}>
                <h3>Property Title</h3>
                <Button 
                    variant="contained" color="primary" size="small" 
                    onClick={() => {
                        handleClickOpen("Create")
                    }}
                >
                    Create New
                </Button> 
            </Stack>
            <Box pt={4}>
                <TableData 
                    columns={columns}
                    data={store_property_title.data}
                    progressPending={store_property_title.loading}
                    pagination
                    onChangePage={(value) => setPage(value)}
                    onChangeRowsPerPage={(value) => setLimit(value)}
                    paginationPerPage={limit}
                />
            </Box>

            <Dialog 
                open={open.open} 
                fullWidth={true}
                maxWidth="sm"
                
            >
                <form onSubmit={onSubmit} >
                    <DialogTitle>{open.name} Title</DialogTitle>
                    <div className="box-modal-create">
                        <TextField
                            margin="normal"
                            size="small"
                            fullWidth
                            id="name"
                            label="Name"
                            name="name"
                            type="text"
                            value={valueInput.name}
                            onChange={(e) => setValueInput({...valueInput, name : e.target.value})}
                        />
                    </div>
                    <DialogActions>
                        <Button onClick={handleClose} color="warning">Cancel</Button>
                        
                        { open.name === "Edit" ?
                        <Button type="submit" variant="outlined">{ store_property_title.loading_update ? "Loading.." : "Save" }</Button> :
                        <Button type="submit" variant="outlined">{ store_property_title.loading_create ? "Loading.." : "Save" }</Button> }
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    )   
}

export default PropertyTitle
