import { createSlice } from '@reduxjs/toolkit';
import swal from 'sweetalert';
import { getAllExtraFacilities, postExtraFacilities, updateExtraFacilities } from './reducers/extraFacilitiesReducers';


const initialState: any = {
  data: [],
  loading : false,
  create : false,
  loading_create : false,
  update : false,
  loading_update : false,
};

export const extraFacilitiesSlice = createSlice({
  name: 'extra-facilities', 
  initialState,
  reducers: {},
  extraReducers: {
    //   fetch data
    [getAllExtraFacilities.pending.type] : (state) => {
        state.loading = true
        state.create = false
        state.update = false
    },
    [getAllExtraFacilities.fulfilled.type] : (state, action) => {
        state.loading = false
        state.data = action.payload.data
    },
    [getAllExtraFacilities.rejected.type] : (state, action) => {
        state.loading = false
        swal('Error', `${action.payload}`, 'error')
    },
    // create data
    [postExtraFacilities.pending.type] : (state) => {
        state.loading_create = true
    },
    [postExtraFacilities.fulfilled.type] : (state, action) => {
        state.loading_create = false
        state.create = true
        swal('Success', `${action.payload.message}`, 'success')
    },
    [postExtraFacilities.rejected.type] : (state, action) => {
        state.loading_create = false
        swal('Error', `${action.payload}`, 'error')
    },
    // update data
    [updateExtraFacilities.pending.type] : (state) => {
      state.loading_update = true
    },
    [updateExtraFacilities.fulfilled.type] : (state, action) => {
        state.loading_update = false
        state.update = true
        swal('Success', `${action.payload.message}`, 'success')
    },
    [updateExtraFacilities.rejected.type] : (state, action) => {
        state.loading_update = false
        swal('Error', `${action.payload}`, 'error')
    },
  }
});

export default extraFacilitiesSlice.reducer;
