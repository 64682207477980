import { createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios'

export const getUserAgentScrapping = createAsyncThunk(
    'scraping-agents/fetch', 
    async (data:any, { rejectWithValue }) => { 
        try {
            const response : any = await Axios.get(`${process.env.REACT_APP_API_DEV}/house-master/agent`, {
                params : {
                    location : `${data.area_id}`
                }
            })
            if(response.data.errors === null) { 
                return {data : response.data.data.data, data_count: response.data.data.document_count,  message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})

export const postUserAgentScraping = createAsyncThunk(
    'scraping-agents/post', 
    async (name : string, { rejectWithValue }) => {
        try {
            let body = {
                name : name
            }
            const response : any = await Axios.post(`${process.env.REACT_APP_API_DEV}/master-trait`, body)
            if(response.data.errors === null) { 
                return {data : response.data.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})

