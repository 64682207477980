import { useState, useEffect } from 'react';
import { Box, Grid, Button, DialogContent, Chip, TextField } from '@mui/material';
import Stack from '@mui/material/Stack';
import BreadCrumbs from '../../../../components/BreadCrumbs';
import TableData from '../../../../components/TableData'
import { TableColumn } from 'react-data-table-component';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import {  useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../app/store';
import { getAllScrapingHouseProceed, updateStatusHouse } from './reducers/followUpHouseReducers';
import Select from 'react-select'
import Axios from 'axios'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import React from 'react'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
// @ts-ignore
import ReactExport from "react-export-excel";
import moment from 'moment';
import { userCredentials } from '../../../../utilities/config';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const optionsPrice : any = [
    { value: 'lowest_price', label: 'Lowest Price' },
    { value: 'highest_price', label: 'Highest Price' },
]
  
const optionsSource : any = [
    { value: '99.co', label: '99.co' },
    { value: 'rumah123', label: 'rumah123' },
]

const optionsStatus : any = [
    { label: 'Follow Up', value: 'FOLLOW_UP' },
    { label: 'Reject', value: 'REJECT' },
    { label: 'Done', value: 'DONE' },
]

const dateNow = new Date()
  
const DownloadExcelStock : React.FC<any> = ({ data }) => {
    return (
        <Box pt={2}>
            <ExcelFile 
                filename={`Data Scraping Rumah - by ${userCredentials.name} on ${moment(dateNow).format('ll')}`}  
                element={(
                <Button
                    variant="contained"
                    color="success" 
                    size="small"
                >Export Excel</Button>
                )}>
                <ExcelSheet data={data} name="scraping">
                    <ExcelColumn label="Agent" value="agent"/>
                    <ExcelColumn label="No Agent" value="telephone"/>
                    <ExcelColumn label="Title" value="title" style={{ alignment: {wrapText: true, horizontal: 'center', vertical: 'top'} }}/>
                    <ExcelColumn label="Potential Sale"
                        value={(col:any) => col.potential ? "No" : "Yes"}/>
                    <ExcelColumn label="Price"
                        value={(col:any) => parseInt(col.price_value).toLocaleString()}/> 
                    <ExcelColumn label="Fair Price"
                        value={(col:any) => col.fairprice.toLocaleString()}/>
                    <ExcelColumn label="Location" value="location"/>
                    <ExcelColumn label="Indikator Land"
                        value={(col:any) => col.indikator_location[0].toLocaleString()}/>
                    <ExcelColumn label="Indikator Building"
                        value={(col:any) => col.indikator_location[1].toLocaleString()}/>
                    <ExcelColumn label="Bedroom" value="bedroom"/>
                    <ExcelColumn label="Bathroom" value="bathroom"/>
                    <ExcelColumn label="Land Area" value="land_area"/>
                    <ExcelColumn label="Building Area" value="building_area"/>
                    <ExcelColumn label="Url Image" value="images"/>
                    <ExcelColumn label="Url Link" value="link"/>
                </ExcelSheet>
                
            </ExcelFile>
        </Box>
    );
}

function FollowUpHouse() {
    const dispatch = useDispatch()
    const store_scraping_house = useSelector((state : RootState) => state.proceed_house)

    
    const [valueInput, setValueInput] = useState<any>({
        name : ""
    });

    const [defaultDataScraping, setDefaultDataScraping] = useState<any>([]);
    const [dataAllScraping, setDataAllScraping] = useState<any>([]);
    const [loadingData, setLoadingData] = useState(false);
    const [agentData, setAgentData] = useState<any>([]);
    const [dataDetail, setDataDetail] = useState<any>({});

    const [updateNote, setUpdateNote] = useState("");
    const [valueComission, setValueComission] = useState<any>({
        comission: "",
        sharing : "",
    });

    const [finalCommision, setFinalCommision] = useState<any>({
        price_comission: null,
        final_comission : null
    });


    const [limit, setLimit] = useState(15);
    const [page, setPage] = useState(1);

    const [open, setOpen] = useState({
        open : false,
        name : ""
    });

    const [historyDialog, setHistoryDialog] = useState({
        open : false,
        data : []
    });

    const handleCloseHistory = () => {
        setHistoryDialog({...historyDialog, open: false})
    }

    const handleClickOpen = (name : string) => {
        setOpen({...open, open : true, name : name });
    };

    const handleClose = () => {
        setOpen({...open, open : false, name : "" });
        setValueInput({...valueInput, name : "" });
        setFinalCommision({...finalCommision, 
            price_comission: null,
            final_comission : null
        })
        setValueComission({...valueComission, 
            comission: "",
            sharing : "",
        })
    };


    const [optionsLocation, setOptionsLocation] = useState<any>([]);
    const [loadedOptions, setLoadedOptions] = useState(false);

    const [valueSource, setValueSource] = useState<any>({
        data: []
    })

    const [valuePrice, setValuePrice] = useState<any>({
        data: []
    })

    const [valueAgent, setValueAgent] = useState<any>({
        data: []
    })

    const [valueLocation, setValueLocation] = useState<any>({
        data: []
    })

    const [valueStatus, setValueStatus] = useState<any>({
        data: []
    })

    const handleChangeSource = async (value:any) => {
        setValueSource({...valueSource, 
            data: value
        });    
    }; 

    const handleChangeLocation = async (value:any) => {
        setValueLocation({...valueLocation, 
            data: value
        });    
    }; 

    const handleChangePrice = (value:any) => {
        setValuePrice({...valuePrice, 
            data: value
        });    
    };
    
    const handleChangeAgent = (value:any) => {
        setValueAgent({...valueAgent, 
            data: value
        });    
    }; 

    const handleChangeStatus = async (value:any) => {
        setValueStatus({...valueStatus, 
            data: value
        });    
    }; 
    
    const onClickSubmit = () => {
        setValueSource({...valueSource, data : []})
        setValueLocation({...valueLocation, data : []})
        setValuePrice({...valuePrice, data : []})
        setValueAgent({...valueAgent, data : []})
        setDataAllScraping(defaultDataScraping)
    }

    const onClickUpdateStatus = (e:any) : void => {
        e.preventDefault()
        let history = [...dataDetail.history_status]
        history.push({ 
            status : valueStatus.data.value,
            note : updateNote,
        })
        const body = {
            status : valueStatus.data.value,
            note : updateNote,
            id : dataDetail._id, 
            history_status : history,
            comission :  parseInt(valueComission.comission),
            groperti_share : parseInt(valueComission.sharing),
            total_comission : parseInt(finalCommision.final_comission)
        }
        dispatch(updateStatusHouse(body))
    }


    useEffect(() => {
        if(store_scraping_house.update) {
            handleClose()
            setLoadingData(true)
            setUpdateNote("")
            setValueStatus({...valueStatus, data : []})
            setDataDetail([])
            dispatch(getAllScrapingHouseProceed())
        }
        // eslint-disable-next-line
    }, [store_scraping_house.update]);

    useEffect(() => {
        let copyData = [...defaultDataScraping]
        let source = valueSource.data.length === 0 ? null : valueSource.data.value
        let location = valueLocation.data.length === 0 ? null : valueLocation.data.label
        let price = valuePrice.data.length === 0 ? null : valuePrice.data.value
        let agent = valueAgent.data.length === 0 ? null : valueAgent.data.value
        if(source !== null) {
            if(location !== null) {
                if(agent !== null) {
                    if(price !== null) {
                        let agent_value =  agent === "N/A" ? "" : agent
                        let result = copyData.filter(function(item, i) {
                            return (( 
                                item.source === source && item.location === location && item.agent === agent_value
                            ));
                        })
                        if(price === 'lowest_price') {
                            const filterPrice = result.sort((aValue, bValue) => parseInt(aValue.price_value) - parseInt(bValue.price_value))
                            setDataAllScraping(filterPrice)   
                        } else {
                            const filterPrice = result.sort((aValue, bValue) => parseInt(bValue.price_value) - parseInt(aValue.price_value))
                            setDataAllScraping(filterPrice)
                        }
                    } else {
                        let agent_value =  agent === "N/A" ? "" : agent
                        let result = copyData.filter(function(item, i) {
                            return (( 
                                item.source === source && item.location === location && item.agent === agent_value
                            ));
                        })
                        setDataAllScraping(result)
                    }
                } else {
                    if(price !== null) {
                        let result = copyData.filter(function(item, i) {
                            return (( 
                                item.source === source && item.location === location
                            ));
                        })
                        if(price === 'lowest_price') {
                            const filterPrice = result.sort((aValue, bValue) => parseInt(aValue.price_value) - parseInt(bValue.price_value))
                            setDataAllScraping(filterPrice)   
                        } else {
                            const filterPrice = result.sort((aValue, bValue) => parseInt(bValue.price_value) - parseInt(aValue.price_value))
                            setDataAllScraping(filterPrice)
                        }
                    } else {
                        let result = copyData.filter(function(item, i) {
                            return (( 
                                item.source === source && item.location === location
                            ));
                        })
                        setDataAllScraping(result)
                    }
                }
            } else {
                if(price !== null) {
                    let result = copyData.filter(function(item, i) {
                        return (( 
                            item.source === source
                        ));
                    })
                    if(price === 'lowest_price') {
                        const filterPrice = result.sort((aValue, bValue) => parseInt(aValue.price_value) - parseInt(bValue.price_value))
                        setDataAllScraping(filterPrice)   
                    } else {
                        const filterPrice = result.sort((aValue, bValue) => parseInt(bValue.price_value) - parseInt(aValue.price_value))
                        setDataAllScraping(filterPrice)
                    }
                } else {
                    let result = copyData.filter(function(item, i) {
                        return (( 
                            item.source === source 
                        ));
                    })
                    setDataAllScraping(result)
                }
                
            }
        } else if (location !== null) {
            if(agent !== null) {
                if(price !== null) {
                    let agent_value =  agent === "N/A" ? "" : agent
                    let result = copyData.filter(function(item, i) {
                        return (( 
                            item.location === location && item.agent === agent_value
                        ));
                    })
                    if(price === 'lowest_price') {
                        const filterPrice = result.sort((aValue, bValue) => parseInt(aValue.price_value) - parseInt(bValue.price_value))
                        setDataAllScraping(filterPrice)   
                    } else {
                        const filterPrice = result.sort((aValue, bValue) => parseInt(bValue.price_value) - parseInt(aValue.price_value))
                        setDataAllScraping(filterPrice)
                    }
                } else {
                    let agent_value =  agent === "N/A" ? "" : agent
                    let result = copyData.filter(function(item, i) {
                        return (( 
                            item.location === location && item.agent === agent_value
                        ));
                    })
                    setDataAllScraping(result)
                }
            } else {
                if(price !== null) {
                    let result = copyData.filter(function(item, i) {
                        return (( 
                            item.location === location
                        ));
                    })
                    if(price === 'lowest_price') {
                        const filterPrice = result.sort((aValue, bValue) => parseInt(aValue.price_value) - parseInt(bValue.price_value))
                        setDataAllScraping(filterPrice)   
                    } else {
                        const filterPrice = result.sort((aValue, bValue) => parseInt(bValue.price_value) - parseInt(aValue.price_value))
                        setDataAllScraping(filterPrice)
                    }
                } else {
                    let result = copyData.filter(function(item, i) {
                        return (( 
                            item.location === location 
                        ));
                    })
                    setDataAllScraping(result)
                }
               
            }
        } else if (agent !== null) {
            if(price !== null) {
                let agent_value =  agent === "N/A" ? "" : agent
                let result = copyData.filter(function(item, i) {
                    return (( 
                        item.agent === agent_value
                    ));
                })
                if(price === 'lowest_price') {
                    const filterPrice = result.sort((aValue, bValue) => parseInt(aValue.price_value) - parseInt(bValue.price_value))
                    setDataAllScraping(filterPrice)   
                } else {
                    const filterPrice = result.sort((aValue, bValue) => parseInt(bValue.price_value) - parseInt(aValue.price_value))
                    setDataAllScraping(filterPrice)
                }
            } else {
                let agent_value =  agent === "N/A" ? "" : agent
                let result = copyData.filter(function(item, i) {
                    return (( 
                        item.agent === agent_value
                    ));
                })
                setDataAllScraping(result)
            } 
        } else if (price !== null) {
            if(price === 'lowest_price') {
                const filterPrice = copyData.sort((aValue, bValue) => parseInt(aValue.price_value) - parseInt(bValue.price_value))
                setDataAllScraping(filterPrice)   
            } else {
                const filterPrice = copyData.sort((aValue, bValue) => parseInt(bValue.price_value) - parseInt(aValue.price_value))
                setDataAllScraping(filterPrice)
            }
        }
        // eslint-disable-next-line
    }, [valueSource.data, valueLocation.data, valuePrice.data, valueAgent.data]);

   
    useEffect(() => {
        setLoadingData(true)
        dispatch(getAllScrapingHouseProceed())
        // eslint-disable-next-line
    },  []);

    const checkIndikatorPotential = (row:any, valueIndikator: any) => {
        let indikator = false
        let original_price = parseInt(row.price_value)
        let land_area = parseInt(row.land_area.split('m2')[0])
        let building_area =  row.building_area === undefined ? 0 : parseInt(row.building_area.split('m2')[0])
        let land_area_price = valueIndikator[0]
        let building_area_price = valueIndikator[1]
      
        let estimate_price = (land_area * land_area_price) + (building_area * building_area_price)
      
        if(original_price > estimate_price) {
            indikator = false
        } else {
            indikator = true
        }
      
        return indikator
    }

    const renderPricePrediction = (row : any, valueIndikator: any) => {
        let land_area = parseInt(row.land_area.split('m2')[0])
        let building_area =  row.building_area === undefined ? 0 : parseInt(row.building_area.split('m2')[0])
        let land_area_price = valueIndikator[0]
        let building_area_price = valueIndikator[1]
        let estimate_price = (land_area * land_area_price) + (building_area * building_area_price)
        return estimate_price
    }

    const renderDataProperty = (copy : any) => {
        if(loadedOptions) {
            let data_property = [...copy]
            let data_location = [...optionsLocation]
            let data_array : any = []
            for(let item of data_property) {
                for(let element of data_location) {
                    if(item.location === element.label) {
                        data_array.push({...item, 
                            indikator_location : element.value,
                            potential : checkIndikatorPotential(item, element.value),
                            fairprice : renderPricePrediction(item, element.value)
                        })
                    }
                }
            }
            let sortBy = data_array.sort((a:any,b:any) => {
                if(a.potential < b.potential) return -1
                return 1
            })
            setDefaultDataScraping(sortBy)
            setDataAllScraping(sortBy)
            setLoadingData(false)
        }
    }

    

    const renderDataAgent =  (copy : any) => {
        const array_agent : any = []
        for (let element of copy) {
            array_agent.push({
                value : element.agent === "" ? "N/A" : element.agent,
                label : element.agent === "" ? "N/A" : element.agent
            })
        }
        const uniqueValuesSet = new Set();
        const filteredArr = array_agent.filter((obj:any) => {
            const isPresentInSet = uniqueValuesSet.has(obj.value);
            uniqueValuesSet.add(obj.value);
            return !isPresentInSet;
        });
        let sortBy = filteredArr.sort((a:any,b:any) => {
            if(a.label < b.label) return -1
            return 1
        })
        setAgentData(sortBy)
    }

    useEffect(() => {
        if(store_scraping_house.data_loaded) {
            // copy array data from server
            const copy = [...store_scraping_house.data]

            // render data agent
            renderDataAgent(copy)

            // filter data property by lowest price
            const filterPrice = copy.sort((aValue, bValue) => parseInt(aValue.price_value) - parseInt(bValue.price_value))
            renderDataProperty(filterPrice)

            // set loading to false
        }
        // eslint-disable-next-line
    },  [store_scraping_house.data_loaded, loadedOptions]);

    useEffect(() => {
        const onGetDataMaster = async () => {
          try {
            const response : any = await Axios.get(`${process.env.REACT_APP_API_DEV}/area-master`)
            if(response) {
              let array = []
      
              for(let el of response.data) {
                array.push({
                  label : el.name,
                  value : [el.land_price, el.building_price]
                })
              }
      
              setOptionsLocation(array)
              setLoadedOptions(true)
            }
          } catch (error) {
            console.log(error)
          }
        }
        onGetDataMaster()
        // eslint-disable-next-line
      }, []);


      const columns: TableColumn<any>[] = [
        {
            name: 'No',
            width: '60px', 
            cell: (row, index) => (
                <p>
                { page > 1 ? 
                    <>
                    {index + 1 + (limit * (page-1))}
                    </>
                    :
                    <> 
                    {index + 1}
                    </>
                }
                </p>
            )
        },
        {
            name: 'Agent',
            width: '150px',
            selector: row => row.agent === "" ? "N/A" : row.agent
        },
        {
            name: 'No Agent',
            width: '170px',
            selector: row => row.telephone === "" ? "N/A" : row.telephone
        },
        {
            name: 'Location',
            width: '170px',
            selector: row => row.location
        },
        {
            name: 'Title',
            width: '300px',
            selector: row => <Box><a href={row.link} rel="noopener noreferrer" target="_blank">{row.title}</a></Box>
        },
        {
            name: 'Price',
            width: '120px',
            selector: row => <Box fontWeight={600} >{row.price_title}</Box>
        },
        {
            name: 'Status',
            width: '150px',
            selector: row => <Box><Chip size="small" label={row.status} color={row.status === "DONE" ? "primary" : row.status === "REJECT" ? "error" : row.status === "PROCEED" ? "info" : "secondary"} /></Box>
        },
        {
            name: 'ACTION',
            width: '300px',
            cell: (row ) => (
                <Stack direction="row" spacing={2}>
                    <Button 
                        variant="outlined" size="small"
                        onClick={() => {
                            setHistoryDialog({
                                open: true ,
                                data : row.history_status
                            })
                        }}
                    >
                     History
                    </Button>
                    <Button 
                        variant="outlined" size="small"
                        onClick={() => {
                            setDataDetail(row)
                            handleClickOpen("Proceed")
                        }}
                    >
                     Update
                    </Button>
                    <Button 
                        variant="outlined" color="error" size="small"
                        onClick={() => {
                            setDataDetail(row)
                            handleClickOpen("Detail")
                        }}
                    >
                     Detail
                    </Button>
                </Stack>
            ),
        },
    ];
    
    return (
        <div>
            <Box pb={2}>
                <BreadCrumbs
                    current="Scraping House Proceed"
                    isPage={false}
                />
            </Box>
            <Stack direction="row" justifyContent="space-between" spacing={2}>
                <h3>Scraping House Proceed</h3>
            </Stack>

            <Box pt={2}>Total Data Found <strong>{dataAllScraping.length.toLocaleString()}</strong></Box>

            <Box sx={{ pt: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xl={2} lg={2} md={2} sm={6} xs={12} >
                        <Select 
                            options={optionsSource} 
                            value={valueSource.data}
                            onChange={handleChangeSource}
                            placeholder="Filter by source"
                        />
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={6} xs={12} >
                        <Select 
                            options={loadedOptions && optionsLocation} 
                            value={valueLocation.data}
                            onChange={handleChangeLocation}
                            placeholder="Filter by location"
                        />
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={6} xs={12} >
                        <Select 
                            options={agentData} 
                            value={valueAgent.data}
                            onChange={handleChangeAgent}
                            placeholder={`Filter by Agent (${agentData.length})`}
                        />
                    </Grid>
                    <Grid item xl={2} lg={2} md={2} sm={6} xs={12} >
                        <Select 
                            options={optionsPrice} 
                            value={valuePrice.data}
                            onChange={handleChangePrice}
                            placeholder="Sort by price"
                        />
                    </Grid>

                    <Grid item xl={2} lg={2} md={2} sm={6} xs={12} >
                    <Button
                        variant="contained" 
                        color="secondary"
                        fullWidth
                        onClick={() => onClickSubmit()}
                    >
                        Clear Filter 
                    </Button>
                    </Grid>
                </Grid>
            </Box>

            <Stack flexDirection="row" alignItems="center" >
                <Box>
                    { dataAllScraping.length === 0 ? null  :
                    <DownloadExcelStock 
                        data={dataAllScraping}
                    /> }
                </Box>
            </Stack>
            <Box pt={2}>
                <TableData 
                    columns={columns}
                    data={dataAllScraping && dataAllScraping}
                    progressPending={loadingData}
                    pagination
                    onChangePage={(value) => setPage(value)}
                    onChangeRowsPerPage={(value) => setLimit(value)}
                    paginationPerPage={limit}
                />
            </Box>

            <Dialog 
                open={open.open} 
                fullWidth={true}
                maxWidth="sm"
            >
                { open.name === "Proceed" ?
                <Box>
                    <form onSubmit={onClickUpdateStatus}>
                    <DialogTitle>Proceed this Property</DialogTitle>
                    <DialogContent sx={{ minHeight: 300 }}>
                        <Box>
                            <Select 
                                options={optionsStatus} 
                                value={valueStatus.data}
                                onChange={handleChangeStatus}
                                id="select-style-proceed"
                                placeholder="Select Status"
                            />
                        </Box>
                        <Box pt={2}>
                            <TextField 
                                label="Note (optional)"
                                multiline
                                rows={4}
                                fullWidth
                                onChange={(e) => setUpdateNote(e.target.value)}
                                value={updateNote}
                            />
                        </Box>
                        { valueStatus.data.length !== 0 && valueStatus.data.value === "DONE" ? 
                        <Box>
                            <Box pt={2}>
                                <TextField 
                                    label="Comission in %"
                                    fullWidth
                                    onChange={(e : any) => { 
                                        setValueComission({ comission : e.target.value}) 
                                        setFinalCommision({...finalCommision, price_comission :  ( parseInt(dataDetail.price_value) * (parseInt(e.target.value) / 100) )})
                                    }}
                                    type="number"
                                    size="small"
                                />
                            </Box>
                            <Box pt={2}>
                                <TextField 
                                    label="Sharing Groperti in %"
                                    fullWidth
                                    onChange={(e) => { 
                                        setValueComission({ 
                                            sharing : e.target.value,
                                            comission : valueComission.comission
                                        })
                                        setFinalCommision({...finalCommision, final_comission :  ( parseInt(finalCommision.price_comission) * (parseInt(e.target.value) / 100) )})
                                    }}
                                    disabled={finalCommision.price_comission === null ? true : false}
                                    type="number"
                                    size="small"
                                />
                            </Box>
                            <Box pt={2}>
                            Price House :  Rp. {dataDetail.price_value.toLocaleString()}
                            </Box>
                            { finalCommision.price_comission === null ? null : 
                            <Box pt={2}>
                            Price Comission :  Rp. {finalCommision.price_comission.toLocaleString()}
                            </Box> }
                            { finalCommision.final_comission === null ? null : 
                            <Box pt={2}>
                            Total Comission Groperti :  Rp. {finalCommision.final_comission.toLocaleString()}
                            </Box> }
                        </Box> : null }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="error" variant="outlined">Close</Button>
                        <Button 
                            type="submit" color="primary" variant="contained"
                            disabled={valueStatus.data.length === 0 ? true : false}
                            >
                            { store_scraping_house.loading_update ? "Loading..." : "Submit" }
                        </Button>
                    </DialogActions>
                    </form>
                </Box>
                :
                <Box>
                    <DialogTitle>{dataDetail.title}</DialogTitle>
                    <DialogContent>
                        { dataDetail.title === undefined  ? null :
                        <Stack flexDirection="column" justifyContent="center" alignItems="center">
                            <Box pb={2}><img src={dataDetail.images} alt="img" width="300px" /></Box>
                            <Box>Price : Rp. {parseInt(dataDetail.price_value).toLocaleString()}</Box>
                            <Box>
                                <Stack flexDirection="row" alignItems="center" >
                                    <Box>Fairprice : </Box>
                                    <Box pl={1}>{!dataDetail.potential ?  <CheckCircleIcon color="primary"/> : <HighlightOffIcon color="error"/>}</Box>
                                    <Box pl={1} color={!dataDetail.potential ? "#0a449b" : "red"} fontWeight={600}>
                                        Rp. {dataDetail.fairprice.toLocaleString()}
                                    </Box>
                                </Stack>
                            </Box>
                            <Box>Location : {dataDetail.address}</Box>
                            <Box>Agent : {dataDetail.agent}</Box>
                            <Box>Phone : {dataDetail.telephone}</Box>
                            <Box>Facility : {dataDetail.bedroom} KT, {dataDetail.bathroom} KM </Box>
                            <Box>Land Area : {dataDetail.land_area}</Box>
                            <Box>Building Area : {dataDetail.building_area}</Box>
                            <Box><a href={dataDetail.link} target="_blank" rel="noopener noreferrer">Link Property</a></Box>
                            <Box pt={2}>  Detail Comission :</Box>
                            <Box>Comission : {dataDetail.commission} %</Box>
                            <Box>Groperti Sharing : {dataDetail.groperti_share} %</Box>
                            <Box>Price Commision Sharing : Rp. {dataDetail.total_comission.toLocaleString()}</Box>
                        </Stack> }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="error" variant="outlined">Close</Button>
                    </DialogActions>
                </Box> }
            </Dialog>

            <Dialog 
                open={historyDialog.open} 
                fullWidth={true}
                maxWidth="md"
            >
                <Box>
                    <DialogTitle>History Status</DialogTitle>
                    <DialogContent>
                        { historyDialog.data.length ?
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                <TableRow>
                                    <TableCell width="50px">No</TableCell>
                                    <TableCell width="90px">Status</TableCell>
                                    <TableCell>Note</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {historyDialog.data.map((row : any, i : any) => (
                                    <TableRow
                                        key={i}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                    <TableCell component="th" scope="row">
                                        { i + 1}
                                    </TableCell>
                                    <TableCell>{row.status}</TableCell>
                                    <TableCell>{row.note}</TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer> : null }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseHistory} color="error" variant="outlined">Close</Button>
                    </DialogActions>
                </Box> 
            </Dialog>

        </div>
    )   
}

export default FollowUpHouse
