import { createSlice } from '@reduxjs/toolkit';
import swal from 'sweetalert';
import { getAllBiChecking, updateBiChecking, } from './reducers/carikanPropertiReducers';


const initialState: any = {
  data: [],
  loading : false,
  update : false,
  loading_update : false
};

export const biCheckingSlice = createSlice({
  name: 'bi-checking',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllBiChecking.pending, (state) => {
        state.loading = true
        state.create = false
        state.update = false
      })
      .addCase(getAllBiChecking.fulfilled, (state, action:any) => {
        state.loading = false
        state.data = action.payload
      })
      .addCase(getAllBiChecking.rejected, (state, action : any) => {
        state.loading = false
        swal('Error', `${action.payload}`, 'error')
      })

      .addCase(updateBiChecking.pending, (state) => {
        state.loading_update = true
      })
      .addCase(updateBiChecking.fulfilled, (state, action:any) => {
        state.loading_update = false
        state.update = true
        swal('Success', `${action.payload.message}`, 'success')
      })
      .addCase(updateBiChecking.rejected, (state, action : any) => {
        state.loading_update = false
        swal('Error', `${action.payload}`, 'error')
      })
  },
});

export default biCheckingSlice.reducer;
