import React, { useState, useEffect } from 'react';
import { Box, Grid, Button, DialogContent } from '@mui/material';
import Stack from '@mui/material/Stack';
import BreadCrumbs from '../../../../components/BreadCrumbs';
import TableData from '../../../../components/TableData'
import { TableColumn } from 'react-data-table-component';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import {  useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../app/store';
import { getAllScrapingHouse, postProceedHouse } from './reducers/scrapingLandReducers';
import Select from 'react-select'
import Axios from 'axios'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
// @ts-ignore
import ReactExport from "react-export-excel";
import moment from 'moment';
import { userCredentials } from '../../../../utilities/config';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const optionsPrice : any = [
    { value: 'lowest_price', label: 'Lowest Land' },
    { value: 'highest_price', label: 'Highest Land' },
]
  
const optionsSource : any = [
    { value: '99.co', label: '99.co' },
    { value: 'rumah123', label: 'rumah123' },
]

const dateNow = new Date()
  
const DownloadExcelStock : React.FC<any> = ({ data }) => {
    return (
        <Box pt={2}>
            <ExcelFile 
                filename={`Data Scraping Rumah - by ${userCredentials.name} on ${moment(dateNow).format('ll')}`}  
                element={(
                <Button
                    variant="contained"
                    color="success" 
                    size="small"
                >Export Excel</Button>
                )}>
                <ExcelSheet data={data} name="scraping">
                    <ExcelColumn label="Title" value="title" style={{ alignment: {wrapText: true, horizontal: 'center', vertical: 'top'} }}/>
                    <ExcelColumn label="Agent" value="agent"/>
                    <ExcelColumn label="No Agent" value="telephone"/>
                    <ExcelColumn label="Price"
                        value={(col:any) => parseInt(col.price_value).toLocaleString()}/> 
                    <ExcelColumn label="Price per M2"
                        value={(col:any) => col.price_m2.toLocaleString()}/>
                    <ExcelColumn label="Location" value="location"/>
                    <ExcelColumn label="Indikator Land"
                        value={(col:any) => col.indikator_location[0].toLocaleString()}/>
                    <ExcelColumn label="Land Area" value="land_area"/>
                    <ExcelColumn label="Url Image" value="images"/>
                    <ExcelColumn label="Url Link" value="link"/>
                </ExcelSheet>
                
            </ExcelFile>
        </Box>
    );
}

function ScrapingLand() {
    const dispatch = useDispatch()
    const store_scraping_house = useSelector((state : RootState) => state.scraping_house)

    const [valueInput, setValueInput] = useState<any>({
        name : ""
    });

    const [defaultDataScraping, setDefaultDataScraping] = useState<any>([]);
    const [dataAllScraping, setDataAllScraping] = useState<any>([]);
    const [loadingData, setLoadingData] = useState(false);
    const [agentData, setAgentData] = useState<any>([]);
    const [dataDetail, setDataDetail] = useState<any>({});

    const [selectedRows, setSelectedRows] = useState<any>({});
    
    const onChangeSelect = (e :any) => {
        setSelectedRows(e)
    } 

    const [limit, setLimit] = useState(15);
    const [page, setPage] = useState(1);

    const [open, setOpen] = useState({
        open : false,
        name : ""
    });

    const handleClickOpen = (name : string) => {
        setOpen({...open, open : true, name : name });
    };

    const handleClose = () => {
        setOpen({...open, open : false, name : "" });
        setValueInput({...valueInput, name : "" });
    };

    const onClickProceed = () : void => {
        const body = []
        for(let k of selectedRows.selectedRows) {
            body.push({
                address: k.address,
                agent: k.agent,
                bathroom: parseInt(k.bathroom),
                bedroom: parseInt(k.bedroom),
                building_area: k.building_area,
                images: k.images,
                land_area: k.land_area,
                link: k.link,
                location: k.location,
                price_title: k.price_title,
                price_value: parseInt(k.price_value),
                property_type: k.property_type,
                source: k.source,
                telephone: k.telephone,
                title: k.title,
                _id: k._id,
                note : "",
                history_status : [
                    { 
                        "status" : "PROCEED",
                        "note" : "Proceed House Property"
                    }
                ]
            })
        }
        dispatch(postProceedHouse(body))
    }

    const [optionsLocation, setOptionsLocation] = useState<any>([]);
    const [loadedOptions, setLoadedOptions] = useState(false);

    const [valueSource, setValueSource] = useState<any>({
        data: []
    })

    const [valuePrice, setValuePrice] = useState<any>({
        data: []
    })

    const [valueAgent, setValueAgent] = useState<any>({
        data: []
    })

    const [valueLocation, setValueLocation] = useState<any>({
        data: []
    })

    const handleChangeSource = async (value:any) => {
        setValueSource({...valueSource, 
            data: value
        });    
    }; 

    const handleChangeLocation = async (value:any) => {
        setValueLocation({...valueLocation, 
            data: value
        });    
    }; 

    const handleChangePrice = (value:any) => {
        setValuePrice({...valuePrice, 
            data: value
        });    
    };
    
    const handleChangeAgent = (value:any) => {
        setValueAgent({...valueAgent, 
            data: value
        });    
    }; 
    
    const onClickSubmit = () => {
        setValueSource({...valueSource, data : []})
        setValueLocation({...valueLocation, data : []})
        setValuePrice({...valuePrice, data : []})
        setValueAgent({...valueAgent, data : []})
        setDataAllScraping(defaultDataScraping)
    }


    useEffect(() => {
        let copyData = [...defaultDataScraping]
        let source = valueSource.data.length === 0 ? null : valueSource.data.value
        let location = valueLocation.data.length === 0 ? null : valueLocation.data.label
        let price = valuePrice.data.length === 0 ? null : valuePrice.data.value
        let agent = valueAgent.data.length === 0 ? null : valueAgent.data.value
        if(source !== null) {
            if(location !== null) {
                if(agent !== null) {
                    if(price !== null) {
                        let agent_value =  agent === "N/A" ? "" : agent
                        let result = copyData.filter(function(item, i) {
                            return (( 
                                item.source === source && item.location === location && item.agent === agent_value
                            ));
                        })
                        if(price === 'lowest_price') {
                            const filterPrice = result.sort((aValue, bValue) => parseInt(aValue.price_value) - parseInt(bValue.price_value))
                            setDataAllScraping(filterPrice)   
                        } else {
                            const filterPrice = result.sort((aValue, bValue) => parseInt(bValue.price_value) - parseInt(aValue.price_value))
                            setDataAllScraping(filterPrice)
                        }
                    } else {
                        let agent_value =  agent === "N/A" ? "" : agent
                        let result = copyData.filter(function(item, i) {
                            return (( 
                                item.source === source && item.location === location && item.agent === agent_value
                            ));
                        })
                        setDataAllScraping(result)
                    }
                } else {
                    if(price !== null) {
                        let result = copyData.filter(function(item, i) {
                            return (( 
                                item.source === source && item.location === location
                            ));
                        })
                        if(price === 'lowest_price') {
                            const filterPrice = result.sort((aValue, bValue) => parseInt(aValue.price_value) - parseInt(bValue.price_value))
                            setDataAllScraping(filterPrice)   
                        } else {
                            const filterPrice = result.sort((aValue, bValue) => parseInt(bValue.price_value) - parseInt(aValue.price_value))
                            setDataAllScraping(filterPrice)
                        }
                    } else {
                        let result = copyData.filter(function(item, i) {
                            return (( 
                                item.source === source && item.location === location
                            ));
                        })
                        setDataAllScraping(result)
                    }
                }
            } else {
                if(price !== null) {
                    let result = copyData.filter(function(item, i) {
                        return (( 
                            item.source === source
                        ));
                    })
                    if(price === 'lowest_price') {
                        const filterPrice = result.sort((aValue, bValue) => parseInt(aValue.price_value) - parseInt(bValue.price_value))
                        setDataAllScraping(filterPrice)   
                    } else {
                        const filterPrice = result.sort((aValue, bValue) => parseInt(bValue.price_value) - parseInt(aValue.price_value))
                        setDataAllScraping(filterPrice)
                    }
                } else {
                    let result = copyData.filter(function(item, i) {
                        return (( 
                            item.source === source 
                        ));
                    })
                    setDataAllScraping(result)
                }
                
            }
        } else if (location !== null) {
            if(agent !== null) {
                if(price !== null) {
                    let agent_value =  agent === "N/A" ? "" : agent
                    let result = copyData.filter(function(item, i) {
                        return (( 
                            item.location === location && item.agent === agent_value
                        ));
                    })
                    if(price === 'lowest_price') {
                        const filterPrice = result.sort((aValue, bValue) => parseInt(aValue.land_area_number) - parseInt(bValue.land_area_number))
                        setDataAllScraping(filterPrice)   
                    } else {
                        const filterPrice = result.sort((aValue, bValue) => parseInt(bValue.land_area_number) - parseInt(aValue.land_area_number))
                        setDataAllScraping(filterPrice)
                    }
                } else {
                    let agent_value =  agent === "N/A" ? "" : agent
                    let result = copyData.filter(function(item, i) {
                        return (( 
                            item.location === location && item.agent === agent_value
                        ));
                    })
                    setDataAllScraping(result)
                }
            } else {
                if(price !== null) {
                    let result = copyData.filter(function(item, i) {
                        return (( 
                            item.location === location
                        ));
                    })
                    if(price === 'lowest_price') {
                        const filterPrice = result.sort((aValue, bValue) => parseInt(aValue.land_area_number) - parseInt(bValue.land_area_number))
                        setDataAllScraping(filterPrice)   
                    } else {
                        const filterPrice = result.sort((aValue, bValue) => parseInt(bValue.land_area_number) - parseInt(aValue.land_area_number))
                        setDataAllScraping(filterPrice)
                    }
                } else {
                    let result = copyData.filter(function(item, i) {
                        return (( 
                            item.location === location 
                        ));
                    })
                    setDataAllScraping(result)
                }
               
            }
        } else if (agent !== null) {
            if(price !== null) {
                let agent_value =  agent === "N/A" ? "" : agent
                let result = copyData.filter(function(item, i) {
                    return (( 
                        item.agent === agent_value
                    ));
                })
                if(price === 'lowest_price') {
                    const filterPrice = result.sort((aValue, bValue) => parseInt(aValue.land_area_number) - parseInt(bValue.land_area_number))
                    setDataAllScraping(filterPrice)   
                } else {
                    const filterPrice = result.sort((aValue, bValue) => parseInt(bValue.land_area_number) - parseInt(aValue.land_area_number))
                    setDataAllScraping(filterPrice)
                }
            } else {
                let agent_value =  agent === "N/A" ? "" : agent
                let result = copyData.filter(function(item, i) {
                    return (( 
                        item.agent === agent_value
                    ));
                })
                setDataAllScraping(result)
            } 
        } else if (price !== null) {
            if(price === 'lowest_price') {
                const filterPrice = copyData.sort((aValue, bValue) => parseInt(aValue.land_area_number) - parseInt(bValue.land_area_number))
                setDataAllScraping(filterPrice)   
            } else {
                const filterPrice = copyData.sort((aValue, bValue) => parseInt(bValue.land_area_number) - parseInt(aValue.land_area_number))
                setDataAllScraping(filterPrice)
            }
        }
        // eslint-disable-next-line
    }, [valueSource.data, valueLocation.data, valuePrice.data, valueAgent.data]);

   
    useEffect(() => {
        setLoadingData(true)
        dispatch(getAllScrapingHouse())
        // eslint-disable-next-line
    },  []);

    const checkIndikatorPotential = (row:any, valueIndikator: any) => {
        let indikator = false
        let original_price = parseInt(row.price_value)
        let land_area = parseInt(row.land_area.split('m2')[0])
        let building_area =  row.building_area === undefined ? 0 : parseInt(row.building_area.split('m2')[0])
        let land_area_price = valueIndikator[0]
        let building_area_price = valueIndikator[1]
      
        let estimate_price = (land_area * land_area_price) + (building_area * building_area_price)
      
        if(original_price > estimate_price) {
            indikator = false
        } else {
            indikator = true
        }
      
        return indikator
    }

    const renderPricePrediction = (row : any, valueIndikator: any) => {
        let land_area = parseInt(row.land_area.split('m2')[0])
        let building_area =  row.building_area === undefined ? 0 : parseInt(row.building_area.split('m2')[0])
        let land_area_price = valueIndikator[0]
        let building_area_price = valueIndikator[1]
        let estimate_price = (land_area * land_area_price) + (building_area * building_area_price)
        return estimate_price
    }

    const renderPricem2 = (row : any, valueIndikator: any) => {
        let land_area = parseInt(row.land_area.split('m2')[0])
        const price = parseInt(row.price_value)
        const price_m2 = price / land_area 
        return Math.floor(price_m2)
    }

    const renderLandNumber = (row : any, valueIndikator: any) => {
        let land_area = parseInt(row.land_area.split('m2')[0])
        return land_area
    }


    const renderDataProperty = (copy : any) => {
        if(loadedOptions) {
            let data_property = [...copy]
            let data_location = [...optionsLocation]
            let data_array : any = []
            for(let item of data_property) {
                for(let element of data_location) {
                    if(item.location === element.label) {
                        data_array.push({...item, 
                            indikator_location : element.value,
                            potential : checkIndikatorPotential(item, element.value),
                            fairprice : renderPricePrediction(item, element.value),
                            price_m2 : renderPricem2(item, element.value),
                            land_area_number : renderLandNumber(item, element.value),
                        })
                    }
                }
            }
            let sortBy = data_array.sort((a:any,b:any) => {
                if(a.potential < b.potential) return -1
                return 1
            })
            setDefaultDataScraping(sortBy)
            setDataAllScraping(sortBy)
            setLoadingData(false)
        }
    }

    

    const renderDataAgent =  (copy : any) => {
        const array_agent : any = []
        for (let element of copy) {
            array_agent.push({
                value : element.agent === "" ? "N/A" : element.agent,
                label : element.agent === "" ? "N/A" : element.agent
            })
        }
        const uniqueValuesSet = new Set();
        const filteredArr = array_agent.filter((obj:any) => {
            const isPresentInSet = uniqueValuesSet.has(obj.value);
            uniqueValuesSet.add(obj.value);
            return !isPresentInSet;
        });
        let sortBy = filteredArr.sort((a:any,b:any) => {
            if(a.label < b.label) return -1
            return 1
        })
        setAgentData(sortBy)
    }


    useEffect(() => {
        if(store_scraping_house.proceed) {
            handleClose()
            setLoadingData(true)
            dispatch(getAllScrapingHouse())
        }
        // eslint-disable-next-line
    }, [store_scraping_house.proceed]);

    useEffect(() => {
        if(store_scraping_house.data_loaded) {
            // copy array data from server
            const copy = [...store_scraping_house.data]

            // render data agent
            renderDataAgent(copy)

            // filter data property by lowest price
            const filterPrice = copy.sort((aValue, bValue) => parseInt(aValue.price_value) - parseInt(bValue.price_value))
            renderDataProperty(filterPrice)

            // set loading to false
        }
        // eslint-disable-next-line
    },  [store_scraping_house.data_loaded, loadedOptions]);

    useEffect(() => {
        const onGetDataMaster = async () => {
          try {
            const response : any = await Axios.get(`${process.env.REACT_APP_API_DEV}/area-master`)
            if(response) {
              let array = []
      
              for(let el of response.data) {
                array.push({
                  label : el.name,
                  value : [el.land_price, el.building_price]
                })
              }
      
              setOptionsLocation(array)
              setLoadedOptions(true)
            }
          } catch (error) {
            console.log(error)
          }
        }
        onGetDataMaster()
        // eslint-disable-next-line
      }, []);


      const columns: TableColumn<any>[] = [
        {
            name: 'No',
            width: '60px', 
            cell: (row, index) => (
                <p>
                { page > 1 ? 
                    <>
                    {index + 1 + (limit * (page-1))}
                    </>
                    :
                    <> 
                    {index + 1}
                    </>
                }
                </p>
            )
        },
        {
            name: 'Title',
            width: '300px',
            selector: row => <Box><a href={row.link} rel="noopener noreferrer" target="_blank">{row.title}</a></Box>
        },
        {
            name: 'Price',
            width: '120px',
            selector: row => <Box fontWeight={600} >{row.price_title}</Box>
        },
        {
            name: 'Price / m2',
            width: '185px',
            cell: (row) => (
                <Stack flexDirection="row" alignItems="center">
                    <Box pl={1} >
                        Rp. {row.price_m2.toLocaleString()}
                    </Box>
                </Stack>
            )
        },
        {
            name: 'Agent',
            width: '150px',
            selector: row => row.agent === "" ? "N/A" : row.agent
        },
        {
            name: 'No Agent',
            width: '170px',
            selector: row => row.telephone === "" ? "N/A" : row.telephone
        },
        {
            name: 'Location',
            width: '170px',
            selector: row => row.location
        },
        {
            name: 'Land',
            width: '90px',
            selector: row => row.land_area === "" ? "N/A" : row.land_area
        },
        {
            name: 'Source',
            width: '150px',
            selector: row => row.source
        },
        {
            name: 'ACTION',
            width: '140px',
            cell: (row ) => (
                <Stack direction="row" spacing={2}>
                    <Button 
                        variant="contained" color="primary" size="small"
                        onClick={() => {
                            setDataDetail(row)
                            handleClickOpen("Detail")
                        }}
                    >
                     Detail
                    </Button>
                    {/* <Button 
                        variant="outlined" color="primary" size="small"
                        onClick={() => {
                            setValueInput({...valueInput, value : row.value})
                            handleClickOpen("Edit")
                        }}
                    >
                     Edit
                    </Button> */}
                </Stack>
            ),
        },
    ];
    
    return (
        <div>
            <Box pb={2}>
                <BreadCrumbs
                    current="Scraping Land"
                    isPage={false}
                />
            </Box>
            <Stack direction="row" justifyContent="space-between" spacing={2}>
                <h3>Scraping Land</h3>
            </Stack>

            <Box pt={2}>Total Data Found <strong>{dataAllScraping.length.toLocaleString()}</strong></Box>

            <Box sx={{ pt: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xl={2} lg={2} md={2} sm={6} xs={12} >
                        <Select 
                            options={optionsSource} 
                            value={valueSource.data}
                            onChange={handleChangeSource}
                            placeholder="Filter by source"
                        />
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={6} xs={12} >
                        <Select 
                            options={loadedOptions && optionsLocation} 
                            value={valueLocation.data}
                            onChange={handleChangeLocation}
                            placeholder="Filter by location"
                        />
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={6} xs={12} >
                        <Select 
                            options={agentData} 
                            value={valueAgent.data}
                            onChange={handleChangeAgent}
                            placeholder={`Filter by Agent (${agentData.length})`}
                        />
                    </Grid>
                    <Grid item xl={2} lg={2} md={2} sm={6} xs={12} >
                        <Select 
                            options={optionsPrice} 
                            value={valuePrice.data}
                            onChange={handleChangePrice}
                            placeholder="Sort by Land"
                        />
                    </Grid>

                    <Grid item xl={2} lg={2} md={2} sm={6} xs={12} >
                    <Button
                        variant="contained" 
                        color="secondary"
                        fullWidth
                        onClick={() => onClickSubmit()}
                    >
                        Clear Filter 
                    </Button>
                    </Grid>
                </Grid>
            </Box>

            <Stack flexDirection="row" alignItems="center" >
                <Box pt={2} pr={3}>
                    <Button 
                        variant='contained'
                        color="primary"
                        size="small"
                        disabled={selectedRows.selectedCount === 0 || selectedRows.selectedCount === undefined ? true : false }
                        onClick={() => onClickProceed()}
                    >
                        { store_scraping_house.loading_proceed ? "Loading.."  : "Mark as Proceed"}
                    </Button>
                </Box>
                <Box>
                    { dataAllScraping.length === 0 ? null  :
                    <DownloadExcelStock 
                        data={dataAllScraping}
                    /> }
                </Box>
            </Stack>
            <Box pt={2}>
                <TableData 
                    columns={columns}
                    data={dataAllScraping && dataAllScraping}
                    progressPending={loadingData}
                    pagination
                    onChangePage={(value) => setPage(value)}
                    onChangeRowsPerPage={(value) => setLimit(value)}
                    paginationPerPage={limit}
                    selectableRows
                    onSelectedRowsChange={(e) => onChangeSelect(e)}
                />
            </Box>

            <Dialog 
                open={open.open} 
                fullWidth={true}
                maxWidth="sm"
            >
                <Box>
                    <DialogTitle>{dataDetail.title}</DialogTitle>
                    
                    <DialogContent>
                        { dataDetail.title === undefined  ? null :
                        <Stack flexDirection="column" justifyContent="center" alignItems="center">
                            <Box pb={2}><img src={dataDetail.images} alt="img" width="300px" /></Box>
                            <Box>Price : Rp. {parseInt(dataDetail.price_value).toLocaleString()}</Box>
                            <Box>
                                <Stack flexDirection="row" alignItems="center" >
                                    <Box>Fairprice : </Box>
                                    <Box pl={1}>{!dataDetail.potential ?  <CheckCircleIcon color="primary"/> : <HighlightOffIcon color="error"/>}</Box>
                                    <Box pl={1} color={!dataDetail.potential ? "#0a449b" : "red"} fontWeight={600}>
                                        Rp. {dataDetail.fairprice.toLocaleString()}
                                    </Box>
                                </Stack>
                            </Box>
                            <Box>Location : {dataDetail.address}</Box>
                            <Box>Agent : {dataDetail.agent}</Box>
                            <Box>Phone : {dataDetail.telephone}</Box>
                            <Box>Facility : {dataDetail.bedroom} KT, {dataDetail.bathroom} KM </Box>
                            <Box>Land Area : {dataDetail.land_area}</Box>
                            <Box>Building Area : {dataDetail.building_area}</Box>
                            <Box><a href={dataDetail.link} target="_blank" rel="noopener noreferrer">Link Property</a></Box>

                        </Stack> }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="error" variant="outlined">Close</Button>
                    </DialogActions>
                </Box>
            </Dialog>

        </div>
    )   
}

export default ScrapingLand
