import { createSlice } from '@reduxjs/toolkit';
import {  getStatisticData, getStatisticDataArea,  } from './reducers/statisticReducers';
import swal from 'sweetalert'
// import { getAllUsersTeams } from './reducers/productsReducers';

const initialState: any = {
  data: [], 
  count : 0,
  loading : false,
  data_area : [],
  loading_area : false,
};

export const statisticSlice = createSlice({
  name: 'statistic',
  initialState, 
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get all users
      .addCase(getStatisticData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStatisticData.fulfilled, (state, action:any) => {
        state.loading = false;
        state.data = Object.entries(action.payload.data.per_area).map(([key, value]) => ({key,value}));
        state.count = action.payload.data.total_data;
        
      })
      .addCase(getStatisticData.rejected, (state, action : any) => {
        state.loading = false;
        swal("Error", `${action.payload}`, 'error')
      })

      // get all users
      .addCase(getStatisticDataArea.pending, (state) => {
        state.loading_area = true;
      })
      .addCase(getStatisticDataArea.fulfilled, (state, action:any) => {
        state.loading_area = false;
        state.data_area = action.payload.data;
        
      })
      .addCase(getStatisticDataArea.rejected, (state, action : any) => {
        state.loading_area = false;
        swal("Error", `${action.payload}`, 'error')
      })
      
  },
});


export default statisticSlice.reducer;
