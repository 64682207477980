import { 
    Box,
    Stack,
    Skeleton,
} from '@mui/material';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { uploadFileToS3 } from '../../../../../utilities/helper';
import React from 'react'

const Input = styled('input')({
  display: 'none',
});

const ImagesItems : React.FC<any> = ({
    imagesItems,
    setImagesItems,
}) => {

    const [loading, setLoading] = useState(false);
    const [index, setIndex] = useState("");

    const onChangeValue = (index : any, value : any) => {
        let copy_array = [...imagesItems]
        const newData = copy_array.map((obj : any, i : any) => {
            if(i === index)
            return {
                ...obj,
                [value.target.name]: value.target.value,
            }
            return obj
        });
        setImagesItems(newData)
    }

    const onChangeValueFile = async (index : any, e : any) => {
        setLoading(true)
        setIndex(index)
        const copy_array = [...imagesItems]

        const url =  await uploadFileToS3(e.target.files[0], 'images')
        if(url !== undefined) {
            const newData = copy_array.map((obj : any, i : any) => {
                if(i === index)
                return {
                    ...obj,
                    image : url,
                }
                return obj
            });
            setImagesItems(newData)
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const onClickAddNewField = () => {
        let copy_item = [...imagesItems]
        let new_object = {
            image: "",
        }
        copy_item.push(new_object)
        setImagesItems(copy_item)
    }

  return (
    <Stack flexDirection="row" alignItems="bottom">
        { imagesItems.map((row :any, i : any) => (
        <Box ml={1} mr={1} key={i}>
            { loading && i === index ? 
            <Skeleton 
                variant="rectangular" 
                width={50} height={50} 
                sx={{ borderRadius: '4px' }}
            /> :
            <Box>
                { row.image === "" ?
                <Box pt={1}>
                    <label htmlFor="icon-button-files" style={{ border: '1px solid #ccc', padding: '10px', }}>
                        <Input accept="image/*" id="icon-button-files" type="file"  onChange={(e:any) => onChangeValueFile(i,e)} />
                            <IconButton color="primary" aria-label="upload picture" component="span">
                        <PhotoCamera />
                        </IconButton>
                    </label>
                </Box>
                :
                <Stack flexDirection="column"  >
                    <Box 
                        component="img"
                        src={row.image}
                        sx={{
                            width: '100px',
                            borderRadius: '4px',
                            border: '1px solid #ccc',
                            padding: '5px'
                        }}
                    />
                    <Box
                        sx={{
                            color: 'blue',
                            cursor: 'pointer',
                            fontSize: 12,
                            pt: 1
                        }}
                        onClick={() => onChangeValue(i,{target : {value : "", name : "image"}})}
                    >Change</Box>
                </Stack> }
            </Box> 
            }
        </Box>
        ))}
        <Box ml={1} mr={1}>
            <Box mt={1} sx={{border: '1px solid #ccc', padding: '8px', cursor: 'pointer'}}>
                <Box fontSize={15} onClick={onClickAddNewField}>
                    Add More
                </Box>
            </Box> 
        </Box>
    </Stack>
  );
}

export default ImagesItems