import {
    Container,
    CssBaseline,
    Box,
    TextField,
    Button, 
    Grid, 
    Link,
    CircularProgress
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { sendEmail } from './forgotSlice';
import React from 'react'
import { useState } from 'react';

const Forgot = () => {

  const dispatch = useDispatch()
  const forgot = useSelector((state : RootState) => state.forgot)

  const [email, setEmail] = useState("");

  const onSubmit = (event: any): void => {
    event.preventDefault()
    dispatch(sendEmail({email}))
  }

    return (
    <>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center'
            }}
          > 
            <Box>
              <h1>Lupa Password?</h1>
              <p>Silahkan masukkan email kamu untuk reset password.</p>
            </Box>

            <Box sx={{ mt: 1, width: '100%' }}>
            <form onSubmit={onSubmit}>
              <TextField
                required
                margin="normal"
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                data-testid="submit-forgot"
              >
                Submit
                {
                  /* istanbul ignore next */
                  forgot.loading && <CircularProgress  size={30} color="inherit" style={{marginLeft: 10}} /> 
                }
              </Button>
              <Grid container>
                <Grid item>
                  <Link href="/">
                    Masuk
                  </Link>
                </Grid>
              </Grid>
            </form>
            </Box>
          </Box>
        </Container>
    </>
    )
}

export default Forgot;
