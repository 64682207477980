import React from 'react'

function Footer() {

    return (
        <div className="footer-container">
            <div className="container-content">
                <div className="copyright">
                    <span>© {new Date().getFullYear()}. Groperti </span>
                </div>
            </div>
        </div>
    )
}

export default Footer
