import React, { useState, useEffect }  from 'react'
// @ts-ignore
import { CKEditor } from '@ckeditor/ckeditor5-react';
// @ts-ignore
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Box } from '@mui/material';

function TextAreaEditor({
    value, 
    setValue,
    error, 
    setError,
    placeholder,
    loaded
} : any) {

    const [data, setData] = useState("");

    const handleChangeEditor= (event:any, editor:any) => {
        const data = editor.getData();
        if(data.length === 0) {
            setError(false)
            setValue(data)
        } else {
            setError(false)
            setValue(data)
        }
    }

    useEffect(() => {
       if(loaded && value !== undefined) {
           setData(value)
       } 
    }, [loaded, value]);
 
    return (
        
        <Box className={error ? 'editor-custom-error' : 'editor-custom'} >
            { data === undefined ? null :
            <CKEditor
                editor={ ClassicEditor }
                config={{
                    placeholder : placeholder,
                    toolbar: [ 'bold', 'italic', 'bulletedList', 'numberedList', 'heading', 'undo', 'redo', 'blockQuote'],
                }}
                data={value}
                onChange={handleChangeEditor}
            /> }
            { error ?
            <Box pt={1} sx={{color:'red'}}><p>Long Description is required!</p></Box> : null }
           
        </Box>
    )
}

export default TextAreaEditor
