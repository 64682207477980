import React from 'react'
import { useEffect, useState } from 'react';
import { Avatar, Button,  Stack } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/system'
import { TableColumn } from 'react-data-table-component';
import BreadCrumbs from '../../../components/BreadCrumbs'
import DataTableBase from '../../../components/TableData'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { useHistory } from 'react-router-dom';
import { createFilterArea, getAllFilterArea, getAllPropertyScraping } from './reducers/productsReducers';
import Select from 'react-select'
import LoaderBackdrop from '../../../components/LoaderBackdrop';
import { getAllMasterIndikator } from '../Scraping/master_indikator/reducers/masterIndikatorReducers';
import AddressFormVillage from '../../../components/AddressFormVillage';

const ProductsAutoPost = () => {

    const history = useHistory()
    const dispatch = useDispatch()
    const { 
        data_scraping, data_count, loading_scraping, 
        filter_area, loading_filter_area, create_filter_area 
    } = useSelector((state : RootState) => state.products)
    const { data } = useSelector((state : RootState) => state.indikator)


    const [limit, setLimit] = useState(15);
    const [page, setPage] = useState(1);

    const [optionsFilterArea, setOptionsFilterArea] = useState<any[]>([]);
    const [selectedFilterArea, setSelectedFilterArea] = useState<any>([]);

    const [optionsMasterArea, setOptionsMasterArea] = useState<any[]>([]);
    const [selectedMasterArea, setSelectedMasterArea] = useState<any>([]);

    const [valueAddress, setValueAddress] = useState({
        province : "",
        province_id : "",
        error_province : false,
        city : "",
        city_id : "",
        village_mongo_id : "",
        error_city : false,
        subdistrict : "",
        subdistrict_id : "",
        error_subdistrict : false,
        village : "",
        village_id : "",
        error_village : false,
    });

    const [openDialog, setOpenDialog] = useState(false);
    const handleOpenDialog = () => {
        setOpenDialog(true);
      };
    
    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedFilterArea([])
    };

    const onChangePerPage = (page:any) => {
        setPage(page)
    }


    const handleChangeFilterArea = (value: any) : void => {
        setSelectedFilterArea(value)
    }

    const handleChangeMasterArea = (value: any) : void => {
        setSelectedMasterArea(value)
    }

    const onClickSubmit = (e : any) => {
        e.preventDefault()
        const data = {
            id : selectedMasterArea.value,
            body : {
                village_id : valueAddress.village_mongo_id,
            }
        }
        dispatch(createFilterArea(data))
        
    }

    useEffect(() => {
        if(selectedFilterArea.length !== 0) {
            if(selectedFilterArea.value === "Tambah Area") {
                handleOpenDialog()
            }
        }
    }, [selectedFilterArea]);

 
    const onClickSearch = () => {
        const data = {
            limit,
            page,
            area_id : selectedFilterArea.value,
        }
        localStorage.setItem('filter_area', JSON.stringify(data))
        dispatch(getAllPropertyScraping(data))
    }

    useEffect(() => {
        dispatch(getAllMasterIndikator(""))
        dispatch(getAllFilterArea())
        // eslint-disable-next-line
    }, []);


    useEffect(() => { 
        const area_id :any= localStorage.getItem('filter_area')
        if(area_id !== null) {
            const data = {
                limit,
                page,
                area_id : JSON.parse(area_id).area_id,
            }
            dispatch(getAllPropertyScraping(data))
        }
        // eslint-disable-next-line
    }, [limit, page]);

    useEffect(() => {
        if(filter_area.length !== 0) {
            let data_area : any = []
            for(let k of filter_area) {
                data_area.push({
                    label : k.name,
                    value : k._id
                })
            }
            data_area.push({
                label : "Tambah Area",
                value : "Tambah Area"
            })
            setOptionsFilterArea(data_area)
        } else {
            let data_area : any = []
            data_area.push({
                label : "Tambah Area",
                value : "Tambah Area"
            })
            setOptionsFilterArea(data_area)
        }
        // eslint-disable-next-line
    }, [filter_area]);

    useEffect(() => {
        if(data.length !== 0) {
            let data_area : any = []
            for(let k of data) {
                data_area.push({
                    label : k.name,
                    value : k._id
                })
            }
            setOptionsMasterArea(data_area)
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if(create_filter_area) {
            handleCloseDialog()
            dispatch(getAllFilterArea())
        }
        // eslint-disable-next-line
    }, [create_filter_area]);

    const onClickPost = (data: any) => {
        history.push({
            pathname : `/dashboard/products`,
            search: `?page=post`, 
            state : {
                data : data,
            }
        })
    }


    const columns: TableColumn<any>[] = [
        {
            name: 'NO',
            width: '70px',
            cell: (row, index) => (
                <p>
                { page > 1 ? 
                    <>
                    {index + 1 + (limit * (page-1))}
                    </>
                    :
                    <> 
                    {index + 1}
                    </>
                }
                </p>
            )
        },
        {
            name: 'IMAGE',
            width: '80px',
            cell: (row) => (
                <Stack flexDirection="row" alignContent="center" >
                    <Box>
                        <Avatar
                            alt={row.title}
                            src={row.images}
                            sx={{ width: 35, height: 35 }}
                            variant="square"
                        />
                    </Box>
                </Stack>
            )
        },
        {
            name: 'Title',
            width: '350px',
            cell: (row) => (
                <Stack flexDirection="row" alignContent="center" >
                    { row.title.length > 70 ? 
                    <Box pl={1} pt={1}>
                       {row.short_description.substring(0,70)} ..
                    </Box>
                    :
                    <Box pl={1} pt={1}>
                       {row.short_description ? row.short_description : "-"}
                    </Box>
                    }
                </Stack>
            )
        },
        {
            name: 'PRICE',
            cell: (row) => (
                <p>{row.price.title}</p>
            )
        },
        {
            name: 'Land Area',
            cell: (row) => (
                <p>{row.facilities.land_area}</p>
            )
        },
        {
            name: 'Building Area',
            cell: (row) => (
                <p>{row.facilities.building_area}</p>
            )
        },
        // {
        //     name: 'Bedroom',
        //     cell: (row) => (
        //         <p>{row.facilities.bedroom} </p>
        //     )
        // },
        // {
        //     name: 'Bathroom',
        //     cell: (row) => (
        //         <p>{row.facilities.bathroom}</p>
        //     )
        // },
        {
            name: 'City',
            cell: (row) => (
                <p>Pantai Indah Kapuk</p>
            )
        },
        {
            name: 'STATUS',
            cell: (row) => (
                <p>Draft</p>
            )
        },
        {
            name: 'ACTION',
            cell: (row) => (
                <Stack direction="row" spacing={2}>
                    <Button 
                        variant="contained" color="primary" size="small"
                        onClick={() => onClickPost(row)}
                    >
                        Edit
                    </Button>
                </Stack>
            ),
        },
    ];

    return (
        <Box sx={{pt:2, pl:3, pr:3}}>
            <BreadCrumbs
                isPage={false}
                current="Property Listing"
            />
            <Stack direction="row" justifyContent="space-between" pt={3} >
                <Box>
                    <h3>Property Listing Draft</h3>
                </Box>
            </Stack>

            <Box sx={{pt:3}}>
                { loading_filter_area ? <LoaderBackdrop loading={loading_filter_area} />  :
                    <Stack direction="row" justifyContent="space-between" sx={{pb:2}}>
                        {/* <Box>Pilih Area</Box> */}
                        <Box sx={{width : '100%'}}>
                            <Select
                                placeholder="Pilih Area"
                                value={selectedFilterArea}
                                isSearchable={true}
                                options={optionsFilterArea && optionsFilterArea}
                                onChange={handleChangeFilterArea}
                                id="select-style-type"
                            /> 
                        </Box>

                        

                        <Box pl={2}>
                            <Button variant="contained" onClick={onClickSearch} disabled={selectedFilterArea.length === 0 ? true : false}>Cari</Button>
                        </Box>
                    </Stack>
                }

                { loading_scraping ? <LoaderBackdrop loading={loading_scraping} />  :
                    <Box>
                        { data_scraping.length === 0 ?
                        <Box pt={4}>
                            Please select area first to view data!
                        </Box>
                        :
                        <>
                        <Box pb={1}>
                            <p>Total data ditemukan : {data_count.toLocaleString()}</p>
                        </Box>
                        <DataTableBase 
                            columns={columns} 
                            data={data_scraping}
                            progressPending={loading_scraping}
                            pagination
                            paginationServer
                            onChangePage={(value) => onChangePerPage(value)}
                            onChangeRowsPerPage={(value) => setLimit(value)}
                            paginationTotalRows={data_count}
                            paginationPerPage={limit}
                            paginationDefaultPage={page}
                        /> 
                        </>
                        }
                    </Box>
                }
            </Box>

            <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth={true}>
                <form onSubmit={onClickSubmit}>
                    <DialogTitle>Tambahkan Area Filter</DialogTitle>
                    <DialogContent sx={{ minHeight: 600 }}>
                        <Select
                            placeholder="Pilih Master Area"
                            value={selectedMasterArea}
                            isSearchable={true}
                            options={optionsMasterArea && optionsMasterArea}
                            onChange={handleChangeMasterArea}
                            id="select-style-top"
                        /> 
                        <Box pt={2} pb={2}>
                            Please select address
                        </Box>

                        <Box>
                            <AddressFormVillage 
                                setValueAddress={setValueAddress}
                                valueAddres={valueAddress}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog} color="error">Cancel</Button>
                        <Button type="submit" variant="contained">
                            {/* { loading ? <CircularProgress color='inherit' size={20} /> : "Submit" } */}
                            Submit
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

        </Box>
    )
}

export default ProductsAutoPost
