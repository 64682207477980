import { createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios'

export const getAllLeads = createAsyncThunk(
    'Lead/fetch', 
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await Axios.get(`${process.env.REACT_APP_API_DEV}/request-cust`)
            if(response.status === 200) { 
                return response.data.data
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})

export const createNewLead = createAsyncThunk(
    'Lead/post', 
    async (param : any, { rejectWithValue }) => {

        let body = {
            ...param,
            requestCusts  : [param.requestCusts]
        }
        try {
            const response : any = await Axios.post(`${process.env.REACT_APP_API_DEV}/request-cust`, body)
            if(response.status === 201) { 
                return {data :true, message : "Success Added Lead"}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})

export const getMessageByCustomer = createAsyncThunk(
    'customer-msg/update', 
    async (id : string, { rejectWithValue }) => {
        try {
            const response : any = await Axios.get(`${process.env.REACT_APP_API_DEV}/message-request/request-id/${id}`)
            if(response.status === 200) { 
                return response.data.data
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})
