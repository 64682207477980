import { createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios'

export const getAllBlogs = createAsyncThunk(
    'blog/fetch', 
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await Axios.get(`${process.env.REACT_APP_API_DEV}/blog`)
            if(response.data.errors === null) { 
                return {data : response.data.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})

export const createNewBlog = createAsyncThunk(
    'blog/post', 
    async (body : any, { rejectWithValue }) => {
        try {
            const response : any = await Axios.post(`${process.env.REACT_APP_API_DEV}/blog`, body)
            if(response.data.errors === null) { 
                return {data : response.data.data, message : "Success Added Blog"}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})

export const updateBlog = createAsyncThunk(
    'blog/update', 
    async (value : any, { rejectWithValue }) => {
        try {
            const response : any = await Axios.put(`${process.env.REACT_APP_API_DEV}/blog/${value.id}`, value.body)
            if(response.data.errors === null) { 
                return {data : response.data.data, message : "Success update Blog"}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})

export const removeBlog = createAsyncThunk(
    'blog/remove', 
    async (value : any, { rejectWithValue }) => {
        try {
            const response : any = await Axios.delete(`${process.env.REACT_APP_API_DEV}/blog/${value}`)
            if(response.data.errors === null) { 
                return {data : response.data.data, message : "Success delete Blog"}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})