import { createSlice } from '@reduxjs/toolkit';
import swal from 'sweetalert';
import { getAllMasterIndikator, getMasterIndikator, postMasterIndikator, removeMasterIndikator, updateMasterIndikator } from './reducers/masterIndikatorReducers';


const initialState: any = {
  data: [],
  area : [],
  loading_area : false,
  loading : false,
  create : false,
  loading_create : false,
  update : false,
  loading_update : false,
  remove: false,
  loading_remove : false
};

export const masterIndikatorSlice = createSlice({
  name: 'master-indikator',
  initialState,
  reducers: {},
  extraReducers: {
    //   fetch indikator
    [getAllMasterIndikator.pending.type] : (state) => {
        state.loading = true
        state.create = false
        state.update = false
        state.remove = false
    },
    [getAllMasterIndikator.fulfilled.type] : (state, action) => {
        state.loading = false
        state.data = action.payload.data
    },
    [getAllMasterIndikator.rejected.type] : (state, action) => {
        state.loading = false
        swal('Error', `${action.payload}`, 'error')
    },

     //   fetch indikator
     [getMasterIndikator.pending.type] : (state) => {
      state.loading_area = true
    },
    [getMasterIndikator.fulfilled.type] : (state, action) => {
        state.loading_area = false
        state.area = action.payload
    },
    [getMasterIndikator.rejected.type] : (state, action) => {
        state.loading_area = false
        swal('Error', `${action.payload}`, 'error')
    },
    // create indikator
    [postMasterIndikator.pending.type] : (state) => {
        state.loading_create = true
    },
    [postMasterIndikator.fulfilled.type] : (state, action) => {
        state.loading_create = false
        state.create = true
        swal('Success', `${action.payload.message}`, 'success')
    },
    [postMasterIndikator.rejected.type] : (state, action) => {
        state.loading_create = false
        swal('Error', `${action.payload}`, 'error')
    },
    // create indikator
    [updateMasterIndikator.pending.type] : (state) => {
      state.loading_update = true
    },
    [updateMasterIndikator.fulfilled.type] : (state, action) => {
        state.loading_update = false
        state.update = true
        swal('Success', `${action.payload.message}`, 'success')
    },
    [updateMasterIndikator.rejected.type] : (state, action) => { 
        state.loading_update = false
        swal('Error', `${action.payload}`, 'error')
    },
    // remove indikator
    [removeMasterIndikator.pending.type] : (state) => {
      state.loading_remove = true
    },
    [removeMasterIndikator.fulfilled.type] : (state, action) => {
        state.loading_remove = false
        state.remove = true
        swal('Success', `${action.payload.message}`, 'success')
    },
    [removeMasterIndikator.rejected.type] : (state, action) => {
        state.loading_remove = false
        swal('Error', `${action.payload}`, 'error')
    },
  }
});

export default masterIndikatorSlice.reducer;
