import { Paper, Skeleton } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { IStatistic } from './cardStatisticTypes'

const CardStatistic : React.FC<IStatistic> = ({
    label, value, currency, background, status, loading
}) => {
    return (
        <div>
            <Paper elevation={1} sx={{ backgroundColor: background ? background : '#fff', borderRadius: 3 }}>
                { status ?
                <Box pl={2} pr={2} className="card-statistic-status">
                    <div><h3>{label}</h3></div>
                    { loading ? 
                    <div><Skeleton height={30} width={50}/></div> :
                    <div><h1>{value}</h1></div> }
                </Box>
                :
                <Box p={3} className="card-statistic">
                    <h4>{label}</h4>
                    {loading ? 
                    <div><Skeleton height={30} width={50}/></div> 
                    :
                    <>
                    {!currency ? 
                    <h3>{value}</h3> :
                    <h3>Rp. {value.toLocaleString()}</h3>
                    }
                    </> }
                </Box>
                }
            </Paper>
        </div>
    )
}

export default CardStatistic
