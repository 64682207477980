import React, { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import Stack from '@mui/material/Stack';
import BreadCrumbs from '../../../components/BreadCrumbs';
import TableData from '../../../components/TableData'
import { TableColumn } from 'react-data-table-component';
import {  useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../app/store';
import { getAllSurvey } from './reducers/surveyReducers';
import moment from 'moment';
    
function Survey() {
    const dispatch = useDispatch()
    const { data, loading } = useSelector((state : RootState) => state.survey)
    

    const [limit, setLimit] = useState(15);
    const [page, setPage] = useState(1);

    const columns: TableColumn<any>[] = [
        {
            name: 'NO',
            width: '80px',
            cell: (row, index) => (
                <p>
                { page > 1 ? 
                    <>
                    {index + 1 + (limit * (page-1))}
                    </>
                    :
                    <> 
                    {index + 1}
                    </>
                }
                </p>
            )
        },
        {
            name: 'Nama',
            cell: (row) => (
                <div>
                    {row.name}
                </div>
            )
        },
        {
            name: 'No HP',
            cell: (row) => (
                <div>{row.phone_number}</div>
            )
        },
        {
            name: 'Email',
            cell: (row) => (
                <div>{row.email}</div>
            )
        },{
            name: 'Date',
            cell: (row) => (
                <div>{moment(row.created_at).format('LLL')}</div>
            )
        },

    ];
    
    useEffect(() => {
        dispatch(getAllSurvey())
        // dispatch(getAllHouseTitle())
        // eslint-disable-next-line
    },  []);



    return (
        <div>
            <Box pb={2}>
                <BreadCrumbs
                    current="Survey"
                    isPage={false}
                />
            </Box>
            <Stack direction="row" justifyContent="space-between" spacing={2}>
                <h3>Survey</h3>
                
            </Stack>
            <Box pt={4}>
                <TableData 
                    columns={columns}
                    data={data}
                    progressPending={loading}
                    pagination
                    onChangePage={(value:any) => setPage(value)}
                    onChangeRowsPerPage={(value:any) => setLimit(value)}
                    paginationPerPage={limit}
                />
            </Box>
        </div>
    )   
}

export default Survey
