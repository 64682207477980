import { createSlice } from '@reduxjs/toolkit';
import swal from 'sweetalert';
import { getAllScrapingApartment } from './reducers/scrapingApartmentReducers';


const initialState: any = {
  data: [],
  data_default : [],
  data_loaded: false,
  loading : false,
  create : false,
  loading_create : false,
};

export const scrapingApartmentSlice = createSlice({
  name: 'apartment-scraping',
  initialState,
  reducers: {},
  extraReducers: {
    //   fetch title
    [getAllScrapingApartment.pending.type] : (state) => {
        state.loading = true
        state.create = false
        state.data_loaded = false
    },
    [getAllScrapingApartment.fulfilled.type] : (state, action) => {
        state.loading = false
        state.data = action.payload.data
        state.data_default = action.payload.data
        state.data_loaded = true
    },
    [getAllScrapingApartment.rejected.type] : (state, action) => {
        state.loading = false
        swal('Error', `${action.payload}`, 'error')
    }
  }
});

export default scrapingApartmentSlice.reducer;
