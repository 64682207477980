import { createSlice } from '@reduxjs/toolkit';
import swal from 'sweetalert';
import { getAllScrapingApartmentProceed, updateStatusApartment } from './reducers/followUpApartmentReducers';


const initialState: any = {
  data: [],
  data_default : [],
  data_loaded: false,
  loading : false,
  update : false,
  loading_update : false,
};

export const followUpApartmentSlice = createSlice({
  name: 'apartment-followup',
  initialState,
  reducers: {},
  extraReducers: {
    //   fetch title
    [getAllScrapingApartmentProceed.pending.type] : (state) => {
        state.loading = true
        state.update = false
        state.data_loaded = false
    },
    [getAllScrapingApartmentProceed.fulfilled.type] : (state, action) => {
        state.loading = false
        state.data = action.payload.data
        state.data_default = action.payload.data
        state.data_loaded = true
    },
    [getAllScrapingApartmentProceed.rejected.type] : (state, action) => {
        state.loading = false
        swal('Error', `${action.payload}`, 'error')
    },

    //   update house
    [updateStatusApartment.pending.type] : (state) => {
        state.loading_update = true
    },
    [updateStatusApartment.fulfilled.type] : (state, action) => {
        state.loading_update = false
        state.update = action.payload.data
        swal('Success', `${action.payload.message}`, 'success')
    },
    [updateStatusApartment.rejected.type] : (state, action) => {
        state.loading_update = false
        swal('Error', `${action.payload}`, 'error')
    }
  }
});

export default followUpApartmentSlice.reducer;
