import { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import BreadCrumbs from '../../../../components/BreadCrumbs';
import TableData from '../../../../components/TableData'
import { TableColumn } from 'react-data-table-component';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import {  useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../app/store';
import { getUserAgents } from './reducers/userAgentssReducers';
import moment from 'moment'
import React from 'react'
    
function UserAgents() {
    const dispatch = useDispatch()
    const store_user_agents = useSelector((state : RootState) => state.user_agents)

    
    const [valueInput, setValueInput] = useState<any>({
        name : ""
    });

    const [limit, setLimit] = useState(15);
    const [page, setPage] = useState(1);

    const [open, setOpen] = useState({
        open : false,
        name : ""
    });


    const handleClose = () => {
        setOpen({...open, open : false, name : "" });
        setValueInput({...valueInput, name : "" });
    };

    const onSubmit = (e: any): void => {
        e.preventDefault()
        // dispatch(postHouseTitle(valueInput.name))
    }

    const columns: TableColumn<any>[] = [
        {
            name: 'NO',
            width: '80px',
            cell: (row, index) => (
                <p>
                { page > 1 ? 
                    <>
                    {index + 1 + (limit * (page-1))}
                    </>
                    :
                    <> 
                    {index + 1}
                    </>
                }
                </p>
            )
        },
        {
            name: 'NAME',
            cell: (row) => (
                <div>{row.name}</div>
            )
        },
        {
            name: 'EMAIL',
            cell: (row) => (
                <div>{row.email}</div>
            )
        },
        {
            name: 'PHONE',
            cell: (row) => (
                <div>{row.profile.phone_number || "-"}</div>
            )
        },
        {
            name: 'AREA',
            cell: (row) => (
                <div>{row.profile.area.city || "-"}</div>
            )
        },
        {
            name: 'STATUS',
            cell: (row) => (
                <div style={{ backgroundColor: 'green', padding : '5px 10px', borderRadius: '5px', color : '#fff' }}>{row.status}</div>
            )
        },
        {
            name: 'LAST LOGIN',
            cell: (row) => (
                <div>
                    { row.last_login_time === undefined ? "-" :
                    <div>
                        {moment(row.last_login_time).fromNow()}
                    </div> }
                </div>
            )
        },
        // {
        //     name: 'ACTION',
        //     width: '200px',
        //     cell: (row ) => (
        //         <Stack direction="row" spacing={2}>
        //             <Button 
        //                 variant="outlined" color="primary" size="small"
        //                 onClick={() => {
        //                     setValueInput({...valueInput, value : row.value})
        //                     handleClickOpen("Edit")
        //                 }}
        //             >
        //              Edit
        //             </Button>
        //         </Stack>
        //     ),
        // },
    ];
    
    useEffect(() => {
        handleClose()
        dispatch(getUserAgents())
        // eslint-disable-next-line
    },  [store_user_agents.create]);



    return (
        <div>
            <Box pb={2}>
                <BreadCrumbs
                    current="User Agents"
                    isPage={false}
                />
            </Box>
            <Stack direction="row" justifyContent="space-between" spacing={2}>
                <h3>User Agents</h3>
                {/* <Button 
                    variant="contained" color="primary" size="small" 
                    onClick={() => {
                        handleClickOpen("Create")
                    }}
                >
                    Create New
                </Button>  */} 
            </Stack>
            <Box pt={4}>
                <TableData 
                    columns={columns}
                    data={store_user_agents.data}
                    progressPending={store_user_agents.loading}
                    pagination
                    onChangePage={(value) => setPage(value)}
                    onChangeRowsPerPage={(value) => setLimit(value)}
                    paginationPerPage={limit}
                />
            </Box>

            <Dialog 
                open={open.open} 
                fullWidth={true}
                maxWidth="sm"
                
            >
                <form onSubmit={onSubmit} >
                    <DialogTitle>{open.name} Title</DialogTitle>
                    <div className="box-modal-create">
                        <TextField
                            margin="normal"
                            size="small"
                            fullWidth
                            id="name"
                            label="Name"
                            name="name"
                            type="text"
                            value={valueInput.name}
                            onChange={(e) => setValueInput({...valueInput, name : e.target.value})}
                        />
                    </div>
                    <DialogActions>
                        <Button onClick={handleClose} color="warning">Cancel</Button>
                        <Button type="submit" variant="outlined">{ store_user_agents.loading_create ? "Loading.." : "Save" }</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    )   
}

export default UserAgents
