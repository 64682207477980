import { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import BreadCrumbs from '../../../components/BreadCrumbs';
import TableData from '../../../components/TableData'
import { TableColumn } from 'react-data-table-component';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import {  useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../app/store';
import { getAllBiChecking, updateBiChecking } from './reducers/biCheckingReducers';
import React from 'react'

function BIChecking() {
    const dispatch = useDispatch()
    const { data, loading,  update, loading_update } = useSelector((state : RootState) => state.bichecking)

    const [dataDetail, setDataDetail] = useState<any>({});
    const [open, setOpen] = useState(false);
    const [score, setScore] = useState("");

    const [limit, setLimit] = useState(15);
    const [page, setPage] = useState(1);

    const handleClose = () => {
       setOpen(false)
    };

    const onSubmit = (e: any): void => {
        e.preventDefault()

        let data_send = {
            id : dataDetail?._id,
            body : {
                score : score,
                status : 'done'
            }
        }
        dispatch(updateBiChecking(data_send))
    }

    const columns: TableColumn<any>[] = [
        {
            name: 'NO',
            width: '80px',
            cell: (row, index) => (
                <p>
                { page > 1 ? 
                    <>
                    {index + 1 + (limit * (page-1))}
                    </>
                    :
                    <> 
                    {index + 1}
                    </>
                }
                </p>
            )
        },
        {
            name: 'Nama',
            cell: (row) => (
                <div>
                    <a href={row.url_blog} target="_blank" rel="noopener noreferrer">
                    {row.name}
                    </a>
                </div>
            )
        },
        {
            name: 'KTP',
            cell: (row) => (
                <div><a href={row.ktp} target='_blank' rel='noopener noreferrer'>Download</a></div>
            )
        },
        {
            name: 'NPWP',
            cell: (row) => (
                <div><a href={row.npwp} target='_blank' rel='noopener noreferrer'>Download</a></div>
            )
        },
        {
            name: 'Agent',
            cell: (row) => (
                <div>{row.agent_name}</div>
            )
        },
        {
            name: 'Status',
            cell: (row) => (
                <div>{row.status}</div>
            )
        },
        {
            name: 'Score',
            cell: (row) => (
                <div>{row.score}</div>
            )
        },
        {
            name: 'ACTION',
            width: '200px',
            cell: (row ) => (
                <Stack direction="row" spacing={2}>
                    <Button 
                        variant="outlined" color="primary" size="small"
                        onClick={() => {
                            setDataDetail(row)
                            setOpen(true)
                        }}
                    >
                     Edit
                    </Button>
                </Stack>
            ),
        },
    ];
    
    useEffect(() => {
       if(update) {
        handleClose()
        dispatch(getAllBiChecking())
       }
        // eslint-disable-next-line
    },  [update]);

    useEffect(() => {
         dispatch(getAllBiChecking())
         // eslint-disable-next-line
     },  []);



    return (
        <div>
            <Box pb={2}>
                <BreadCrumbs
                    current="Pengajuan BI Checking"
                    isPage={false}
                />
            </Box>
                <h3>Pengajuan BI Checking</h3>
            <Box pt={2}>
                <TableData 
                    columns={columns}
                    data={data}
                    progressPending={loading}
                    pagination
                    onChangePage={(value:any) => setPage(value)}
                    onChangeRowsPerPage={(value:any) => setLimit(value)}
                    paginationPerPage={limit}
                />
            </Box>

            <Dialog 
                open={open} 
                fullWidth={true}
                maxWidth="sm"
            >
                <form onSubmit={onSubmit} >
                    <DialogTitle>Update Progres Pengajuan BI Checking <strong>{dataDetail && dataDetail?.name}</strong></DialogTitle>
                    <Box p={2}>
                        <Box>Update Skor 1-5</Box>
                        <TextField
                            margin="dense"
                            size="small"
                            fullWidth
                            id="name"
                            placeholder='Hasil Skor'
                            name="title"
                            type="number"
                            value={score}
                            onChange={(e:any) => setScore(e.target.value)}
                            required
                        />
                    </Box>
                    <DialogActions>
                        <Button onClick={handleClose} color="warning">Cancel</Button>
        
                        <Button type="submit" variant="outlined">{ loading_update ? "Loading.." : "Save" }</Button> 
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    )   
}

export default BIChecking
