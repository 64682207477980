/* istanbul ignore file */
import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { 
  Route, 
  Redirect,
  useLocation
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../app/store';
import NavDashboard from '../components/NavDashboard'


const  DashboardLayout : React.FC<any> = ({ component : Component, ...rest }) => {

  const location = useLocation()
  const auth = useSelector((state:RootState) => state.login.login)

  const isAuthenticated = () => {
    if(auth) {
        return auth
    } else {
        return false
    }
  }

  function getStylePage () {
    switch(location.pathname) {
      // case "/dashboard/products": { 
      //   switch(location.search) { 
      //     case "?page=create" : {
      //       return { 
      //         display: 'flex',
      //         minHeight: '100vh',
      //         backgroundColor: '#f5f5f5'
      //       }
      //     }
      //     case "" : {
      //       return { 
      //         display: 'flex'
      //       }
      //     }
      //   }
      //   break;
      // }
      case "/dashboard/statistic" : {
        return { 
          display: 'flex',
          minHeight: '100vh',
          backgroundColor: '#f5f5f5'
        }
      }
      case "/dashboard" : {
        return { 
          display: 'flex',
          minHeight: '100vh',
          backgroundColor: '#f5f5f5'
        }
      }
      default: {
        return { 
          display: 'flex',
        }
      }
    }      
  }

  return (

    <Route {...rest} 
        render={props => {
            if(isAuthenticated()){
                return (
                  <Box sx={getStylePage()}>
                    <NavDashboard />
                    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                      <Toolbar />
                      <Component {...props} />
                    </Box>
                  </Box>
                )
            }
            else {
                return (
                    <Redirect 
                        to={{
                            pathname: "/",
                            state: { from: props.location }
                        }}
                    />
                )
            }
        }}
    />
    
  );
}

export default DashboardLayout;