import { createSlice } from '@reduxjs/toolkit';
import swal from 'sweetalert';
import { createNewBlog, getAllBlogs,removeBlog,updateBlog, } from './reducers/blogReducers';


const initialState: any = {
  data: [],
  loading : false,
  create : false,
  loading_create : false,
  update : false,
  loading_update : false,
  remove : false,
  loading_remove : false,
};

export const propertyTitleSlice = createSlice({
  name: 'house-title',
  initialState,
  reducers: {},
  extraReducers: {
    //   fetch title
    [getAllBlogs.pending.type] : (state) => {
        state.loading = true
        state.create = false
        state.update = false
    },
    [getAllBlogs.fulfilled.type] : (state, action) => {
        state.loading = false
        state.data = action.payload.data
    },
    [getAllBlogs.rejected.type] : (state, action) => {
        state.loading = false
        swal('Error', `${action.payload}`, 'error')
    },
    // create title
    [createNewBlog.pending.type] : (state) => {
        state.loading_create = true
    },
    [createNewBlog.fulfilled.type] : (state, action) => {
        state.loading_create = false
        state.create = true
        swal('Success', `${action.payload.message}`, 'success')
    },
    [createNewBlog.rejected.type] : (state, action) => {
        state.loading_create = false
        swal('Error', `${action.payload}`, 'error')
    },
    // update data
    [updateBlog.pending.type] : (state) => {
      state.loading_update = true
    },
    [updateBlog.fulfilled.type] : (state, action) => {
        state.loading_update = false
        state.update = true
        swal('Success', `${action.payload.message}`, 'success')
    },
    [updateBlog.rejected.type] : (state, action) => {
        state.loading_update = false
        swal('Error', `${action.payload}`, 'error')
    },
    // remove data
    [removeBlog.pending.type] : (state) => {
      state.loading_remove = true
    },
    [removeBlog.fulfilled.type] : (state, action) => {
        state.loading_remove = false
        state.remove = true
        swal('Success', `${action.payload.message}`, 'success')
    },
    [removeBlog.rejected.type] : (state, action) => {
        state.loading_remove = false
        swal('Error', `${action.payload}`, 'error')
    },
  }
});

export default propertyTitleSlice.reducer;
