import { createSlice } from '@reduxjs/toolkit';
import swal from 'sweetalert';
import { getAllSurvey} from './reducers/surveyReducers';


const initialState: any = {
  data: [],
  loading : false,
};

export const surveyReducers = createSlice({
  name: 'survey',
  initialState,
  reducers: {},
  extraReducers: {
    //   fetch title
    [getAllSurvey.pending.type] : (state) => {
        state.loading = true
        state.create = false
        state.update = false
    },
    [getAllSurvey.fulfilled.type] : (state, action) => {
        state.loading = false
        state.data = action.payload.data
    },
    [getAllSurvey.rejected.type] : (state, action) => {
        state.loading = false
        swal('Error', `${action.payload}`, 'error')
    },
    
  }
});

export default surveyReducers.reducer;
