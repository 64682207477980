import { createSlice } from '@reduxjs/toolkit';
import swal from 'sweetalert';
import { getAllWithdraw, updateWithdraw } from './reducers/withdrawReducers';


const initialState: any = {
  data: [],
  loading : false,
  update : false,
  loading_update : false,
};

export const withdrawSlice = createSlice({
  name: 'withdraw',
  initialState,
  reducers: {},
  extraReducers: {
    //   fetch type
    [getAllWithdraw.pending.type] : (state) => {
        state.loading = true
        state.update = false
    },
    [getAllWithdraw.fulfilled.type] : (state, action) => {
        state.loading = false
        state.data = action.payload.data
    },
    [getAllWithdraw.rejected.type] : (state, action) => {
        state.loading = false
        swal('Error', `${action.payload}`, 'error')
    },
    // update data
    [updateWithdraw.pending.type] : (state) => {
      state.loading_update = true
    },
    [updateWithdraw.fulfilled.type] : (state, action) => {
        state.loading_update = false
        state.update = true
        swal('Success', `${action.payload.message}`, 'success')
    },
    [updateWithdraw.rejected.type] : (state, action) => {
        state.loading_update = false
        swal('Error', `${action.payload}`, 'error')
    },
  }
});

export default withdrawSlice.reducer;
