import { createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios'

export const getAllHouseType = createAsyncThunk(
    'house-type/fetch', 
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await Axios.get(`${process.env.REACT_APP_API_DEV}/house-type`)
            if(response.data.errors === null) { 
                return {data : response.data.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})

export const postHouseType = createAsyncThunk(
    'house-type/post', 
    async (name : string, { rejectWithValue }) => {
        try {
            let body = {
                name : name
            }
            const response : any = await Axios.post(`${process.env.REACT_APP_API_DEV}/house-type`, body)
            if(response.data.errors === null) { 
                return {data : response.data.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})

export const updateHouseType = createAsyncThunk(
    'house-type/update', 
    async (value : any, { rejectWithValue }) => {
        try {
            let body = {
                name : value.name,
            }
            const response : any = await Axios.put(`${process.env.REACT_APP_API_DEV}/house-type/${value.id}`, body)
            if(response.data.errors === null) { 
                return {data : response.data.data, message : "Success update Property Type"}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})