import { Stack } from '@mui/material'
import logo from '../../../assets/img/logo.png'
import React from 'react'

function NavbarApp() {

    return (
        <Stack pt={1} pb={1}>
            <Stack flexDirection="row" justifyContent="space-between">
                <div className='logo-groperti'>
                    <a href="/">
                        <img src={logo} alt="logo" />
                    </a>
               </div> 
            </Stack>
            
        </Stack>
    )
}

export default NavbarApp
