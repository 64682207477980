import { createSlice } from '@reduxjs/toolkit';
import swal from 'sweetalert';
import { getAllHouseTitle, postHouseTitle, updateHouseTitle } from './reducers/propertyTitleReducers';


const initialState: any = {
  data: [],
  loading : false,
  create : false,
  loading_create : false,
  update : false,
  loading_update : false,
};

export const propertyTitleSlice = createSlice({
  name: 'house-title',
  initialState,
  reducers: {},
  extraReducers: {
    //   fetch title
    [getAllHouseTitle.pending.type] : (state) => {
        state.loading = true
        state.create = false
        state.update = false
    },
    [getAllHouseTitle.fulfilled.type] : (state, action) => {
        state.loading = false
        state.data = action.payload.data
    },
    [getAllHouseTitle.rejected.type] : (state, action) => {
        state.loading = false
        swal('Error', `${action.payload}`, 'error')
    },
    // create title
    [postHouseTitle.pending.type] : (state) => {
        state.loading_create = true
    },
    [postHouseTitle.fulfilled.type] : (state, action) => {
        state.loading_create = false
        state.create = true
        swal('Success', `${action.payload.message}`, 'success')
    },
    [postHouseTitle.rejected.type] : (state, action) => {
        state.loading_create = false
        swal('Error', `${action.payload}`, 'error')
    },
    // update data
    [updateHouseTitle.pending.type] : (state) => {
      state.loading_update = true
    },
    [updateHouseTitle.fulfilled.type] : (state, action) => {
        state.loading_update = false
        state.update = true
        swal('Success', `${action.payload.message}`, 'success')
    },
    [updateHouseTitle.rejected.type] : (state, action) => {
        state.loading_update = false
        swal('Error', `${action.payload}`, 'error')
    },
  }
});

export default propertyTitleSlice.reducer;
