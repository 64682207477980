import { createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios'

export const getAllExtraFacilities = createAsyncThunk(
    'extra-facilities/fetch', 
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await Axios.get(`${process.env.REACT_APP_API_DEV}/extra-facility`)
            if(response.data.errors === null) { 
                return {data : response.data.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})

export const postExtraFacilities = createAsyncThunk(
    'extra-facility/post', 
    async (name : string, { rejectWithValue }) => {
        try {
            let body = {
                name : name
            }
            const response : any = await Axios.post(`${process.env.REACT_APP_API_DEV}/extra-facility`, body)
            if(response.data.errors === null) { 
                return {data : response.data.data, message : "Success added extra facilities"}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
}) 

export const updateExtraFacilities = createAsyncThunk(
    'extra-facility/update', 
    async (value : any, { rejectWithValue }) => {
        try {
            let body = {
                name : value.name,
            }
            const response : any = await Axios.put(`${process.env.REACT_APP_API_DEV}/extra-facility/${value.id}`, body)
            if(response.data.errors === null) { 
                return {data : response.data.data, message : "Success update extra facilities"}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})