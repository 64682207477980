import React from 'react'
import { Button, Grid, Paper, Stack } from '@mui/material'
import { Box } from '@mui/system'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactSelect from 'react-select'
import { RootState } from '../../../app/store'
import LoaderBackdrop from '../../../components/LoaderBackdrop'
import { getStatisticData } from './reducers/statisticReducers'

const harga_terendah : any = [
    { value: 0, label: '0' },
    { value: 100000000, label: '100 juta' },
    { value: 250000000, label: '250 juta' },
    { value: 500000000, label: '500 juta' },
    { value: 750000000, label: '750 juta' },
    { value: 1000000000, label: '1 mily' },
    { value: 2500000000, label: '2,5 mily' },
    { value: 5000000000, label: '5 mily' },
    { value: 10000000000, label: '10 mily ' },
    { value: 15000000000, label: '15 mily ' },
    { value: 20000000000, label: '20 mily ' },
    { value: 25000000000, label: '25 mily ' },
    { value: 30000000000, label: '30 mily ' },
    { value: 40000000000, label: '40 mily ' },
    { value: 50000000000, label: '50 mily ' },
    { value: 100000000000, label: '100 mily ' },
]

const harga_tertinggi : any = [
    { value: 100000000, label: '100 juta' },
    { value: 250000000, label: '250 juta' },
    { value: 500000000, label: '500 juta' },
    { value: 750000000, label: '750 juta' },
    { value: 1000000000, label: '1 mily' },
    { value: 2500000000, label: '2,5 mily' },
    { value: 5000000000, label: '5 mily' },
    { value: 10000000000, label: '10 mily ' },
    { value: 15000000000, label: '15 mily ' },
    { value: 20000000000, label: '20 mily ' },
    { value: 25000000000, label: '25 mily ' },
    { value: 30000000000, label: '30 mily ' },
    { value: 40000000000, label: '40 mily ' },
    { value: 50000000000, label: '50 mily ' },
    { value: 100000000000, label: '100 mily ' },
]

const sortby : any = [
    { value: 1, label: 'Harga Tertinggi' },
    { value: 0, label: 'Harga Terendah' }
]


const sortType : any = [
    { value: '', label: 'All' },
    { value: 'rumah', label: 'Rumah' },
    { value: 'apartemen', label: 'Apartemen' },
    { value: 'tanah', label: 'Tanah' },
]


function Statistic() {
    const dispatch = useDispatch()

    const {data, loading, count} = useSelector((state : RootState) => state.statistic)
    
    const [dataStatistic, setDataStatistic] = useState<any>([]);
    const [refetch, setRefetch] = useState(false);

    const [selectedLowPrice, setSelectedLowPrice] = useState<any>([]);
    const [selectedHighPrice, setSelectedHighPrice] = useState<any>([]);
    const [selectSortBy, setSelectSortBy] = useState<any>([]);
    const [selectSortType, setSelectSortType] = useState<any>([ { value: '', label: 'All' }]);


    const handleChangeSort = (value:any) => {
        setSelectSortBy(value)
        let data = [...dataStatistic]
        if(selectSortBy.value === 0){
            setDataStatistic(data.sort((a:any, b:any) => b.value - a.value))
        }else{
            setDataStatistic(data.sort((a:any, b:any) => a.value - b.value))
        }
    }

    const [errorLow, setErrorLow] = useState(false);
    const [errorHigh, setErrorHigh] = useState(false);

    const handleChangeLow = (value:any) => {
        setErrorLow(false)
        setSelectedLowPrice(value)
    }

    const handleChangeHigh = (value:any) => {
        setErrorHigh(false)
        setSelectedHighPrice(value)
    }

    const handleChangeType = (value:any) => {
        setSelectSortType(value)
    }

    const onClickFilter = () => {
        if(selectedLowPrice.length === 0) {
            setErrorLow(true)
        } else if(selectedHighPrice.length === 0) {
            setErrorHigh(true)
        } else {
            const dataFilter = {
                price_low : selectedLowPrice.value,
                price_high : selectedHighPrice.value,
            }
            dispatch(getStatisticData(dataFilter))
        }
    }

    useEffect(() => {
        let newdata = [...data]
        setDataStatistic(newdata.sort((a:any, b:any) => b.value - a.value))
    }, [data]);

    useEffect(() => {
        const dataFilter = {
            price_low : "",
            price_high : "",
            property_type : selectSortType.value
        }
        dispatch(getStatisticData(dataFilter))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetch, selectSortType]);

    return (
        <Box sx={{pl:3, pr:3, pt:2}}>
           <h3>Data Statistic Scraping</h3>

           <Stack direction="row" sx={{pb:2, pt:3}}>

                <Box sx={{width : '20%'}} pr={2}>
                    <Box pb={0.5} fontSize={13}>Filter By Harga</Box>
                    <ReactSelect
                        placeholder="Pilih"
                        value={selectedLowPrice}
                        isSearchable={true}
                        options={harga_terendah}
                        onChange={handleChangeLow}
                        id="select-style-type"
                    /> 
                    <Box pt={1}>
                        {errorLow && <span style={{color : 'red'}}>Pilih Harga Terendah</span>}
                    </Box>
                </Box>
                <Box sx={{width : '20%'}}>
                    <Box pb={0.5} fontSize={13}>Filter By Harga</Box>
                    <ReactSelect
                        placeholder="Pilih"
                        value={selectedHighPrice}
                        isSearchable={true}
                        options={harga_tertinggi}
                        onChange={handleChangeHigh}
                        id="select-style-type"
                    /> 
                    <Box pt={1}>
                        {errorHigh && <span style={{color : 'red'}}>Pilih Harga Tertinggi</span>}
                    </Box>
                </Box>

                <Box pl={2} pt={3}>
                    <Button 
                        variant="contained" 
                        onClick={onClickFilter} 
                        // disabled={selectedFilterArea.length === 0 ? true : false}
                    >Filter</Button>
                    <Button 
                        variant="contained" 
                        color='error'
                        onClick={() => setRefetch(!refetch)} 
                        sx={{ml:2}}
                        // disabled={selectedFilterArea.length === 0 ? true : false}
                    >Reset</Button>
                </Box>

                <Box sx={{width : '20%', pl: 2}}>
                    <Box pb={0.5} fontSize={13}>Sort By Harga</Box>
                    <ReactSelect
                        placeholder="Pilih "
                        value={selectSortBy}
                        isSearchable={true}
                        options={sortby}
                        onChange={handleChangeSort}
                        id="select-style-type"
                    /> 
                </Box>

                <Box sx={{width : '20%', pl: 2}}>
                    <Box pb={0.5} fontSize={13}>Sort By Type</Box>
                    <ReactSelect
                        placeholder="Sort By Type"
                        value={selectSortType}
                        isSearchable={true}
                        options={sortType}
                        onChange={handleChangeType}
                        id="select-style-type"
                    /> 
                </Box>
            </Stack>

            { loading ? 
            <LoaderBackdrop loading={loading} /> :
            <Box pt={2}>
                <Stack sx={{pb:2}}>
                    Total Data : {count.toLocaleString()}
                    {/* Total Data : 23123 */}
                </Stack>
                <Stack>
                    <Grid container spacing={3}>
                        { dataStatistic && dataStatistic.map((item:any, idx:any) => (
                            <Grid item xl={2} lg={2} sm={6} md={6} xs={6} key={idx}>
                                
                                <Paper>
                                    <Stack sx={{p:2}} flexDirection="row" justifyContent="space-between" alignItems="center">
                                        <Box fontSize={13} pr={1} >
                                            {item.key}
                                        </Box>
                                        <Box fontSize={30} color="#0091d6">
                                            {item.value.toLocaleString()}
                                        </Box>
                                    </Stack>
                                    <Stack sx={{px:2, pb:2}} flexDirection="row" flexWrap="wrap">
                                        <Box sx={{ 
                                            border: '1px solid #2d2a6d', 
                                            padding: '2px 5px', 
                                            fontSize: 12, 
                                            borderRadius: 4,
                                            margin: '3px',
                                            cursor: 'pointer' 
                                        }}>Lihat Data</Box>
                                        <a href={`/dashboard/statistic/${item.key}`}>
                                            <Box sx={{ 
                                                border: '1px solid #2d2a6d', 
                                                padding: '2px 5px', 
                                                fontSize: 12, 
                                                borderRadius: 4,
                                                margin: '3px',
                                                cursor : 'pointer' 
                                            }}>Lihat Statistik</Box>
                                        </a>
                                    </Stack>

                                </Paper>
                            </Grid>
                        )) }
                    </Grid>
                </Stack>
            </Box>
            }
        </Box>
    )
}

export default Statistic
