import React from 'react'
import { Grid, Stack } from '@mui/material'
import { Box } from '@mui/system'
import {userCredentials} from './../../../utilities/config'
import CardStatistic from './components/CardStatistic'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../app/store'
import { useEffect } from 'react'
import { getHomeStatistic } from './redux/homeReducers'
// import NewestOrders from './components/NewestOrders'

const username = userCredentials === null ? "" : userCredentials.name

function HomeDashboard() {

    const dispatch = useDispatch()
    const {statistic, loading} = useSelector((state : RootState) => state.home)

    function isEmpty (obj:any) {
        for(var key in obj) {
            if(obj.hasOwnProperty(key))
                return true;
        }
        return false;
      }


    useEffect(() => {
        dispatch(getHomeStatistic())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return (
        <Box sx={{pl:3, pr:3, pt:2}}>
           <h3>Welcome back, {username}</h3>
           <Box fontWeight="400">You log in as Admin Properti</Box>

            <Stack sx={{pt:3}} >
                <Grid container spacing={3}>
                    <Grid item xl={3} lg={3} sm={6} xs={12}>
                        <CardStatistic 
                            label="Properti Terlisting"
                            value={isEmpty(statistic) && statistic.property_listed.toLocaleString()}
                            currency={false}
                            background="#fff"
                            loading={loading}
                        />
                    </Grid>
                    <Grid item xl={3} lg={3} sm={6} xs={12}>
                        <CardStatistic 
                            label="Properti Perlu Review"
                            value={isEmpty(statistic) && statistic.property_need_to_review.toLocaleString()}
                            currency={false}
                            background="#fff"
                            loading={loading}
                        />
                    </Grid>
                    <Grid item xl={3} lg={3} sm={6} xs={12}>
                        <CardStatistic 
                            label="Property Terjual"
                            value={isEmpty(statistic) && statistic.property_sold.toLocaleString()}
                            currency={false}
                            background="#fff"
                            loading={loading}
                        />
                    </Grid>
                    <Grid item xl={3} lg={3} sm={6} xs={12}>
                        <CardStatistic 
                            label="Total Data Scarping"
                            value={isEmpty(statistic) && (statistic.house_scraped + statistic.apartment_scraped + statistic.land_scraped).toLocaleString()}
                            currency={false}
                            background="#fff"
                            loading={loading}
                        />
                    </Grid>
                    <Grid item xl={3} lg={3} sm={6} xs={12}>
                        <CardStatistic 
                            label="Scraping - Rumah"
                            value={isEmpty(statistic) && statistic.house_scraped.toLocaleString()}
                            currency={false}
                            background="#fff"
                            loading={loading}
                        />
                    </Grid>
                    <Grid item xl={3} lg={3} sm={6} xs={12}>
                        <CardStatistic 
                            label="Scraping - Apartemen"
                            value={isEmpty(statistic) && statistic.apartment_scraped.toLocaleString()}
                            currency={false}
                            background="#fff"
                            loading={loading}
                        />
                    </Grid>
                    <Grid item xl={3} lg={3} sm={6} xs={12}>
                        <CardStatistic 
                            label="Scraping - Tanah"
                            value={isEmpty(statistic) && statistic.land_scraped.toLocaleString()}
                            currency={false}
                            background="#fff"
                            loading={loading}
                        />
                    </Grid>
                    <Grid item xl={3} lg={3} sm={6} xs={12}>
                        <CardStatistic 
                            label="Scraping - Agent"
                            value={isEmpty(statistic) && statistic.agents_scraped.toLocaleString()}
                            currency={false}
                            background="#fff"
                            loading={loading}
                        />
                    </Grid>
                </Grid>
                
            </Stack>

            <Stack sx={{pt:5}} >
                {/* <NewestOrders /> */}
                {/* <Grid container spacing={4}>
                    <Grid item xl={8} lg={8} sm={6} xs={12}>
                    </Grid>
                </Grid> */}
            </Stack>
        </Box>
    )
}

export default HomeDashboard
