import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import loginReducer from '../features_app/auth/login/loginSlice';
import registerReducer from '../features_app/auth/register/registerSlice';
import stepFormReducer from '../features_app/dashboard/complete_register/components/stepFormSlice';
import forgotReducer from '../features_app/auth/forgot/forgotSlice';
import getProductsReducer from '../features_app/dashboard/products/productsSlice'
import getProfileReducer from '../features_app/dashboard/profile/profileSlice'
import getSettingCompany from '../features_app/dashboard/setting/settingSlice'
import completingRegisterReducer from '../features_app/dashboard/complete_register/completeRegisterrSlice'
import getPropertyTypeReducer from '../features_app/dashboard/MasterData/property_type/propertyTypeSlice'
import getPropertyTitleReducer from '../features_app/dashboard/MasterData/property_title/propertyTitleSlice'
import getExtraFacilitiesReducer from '../features_app/dashboard/MasterData/extra_facilities/extraFacilitiesSlice'
import getUserAgentsReducer from '../features_app/dashboard/DataUsers/agents/userAgentsSlice'
import getUserBuyersReducer from '../features_app/dashboard/DataUsers/buyers/userBuyersSlice'
import getUserAdminsReducer from '../features_app/dashboard/DataUsers/admins/userAdminssSlice'
import getUserReferralReducer from '../features_app/dashboard/DataUsers/referral/userReferralsSlice'
import getMasterIndikatorReducer from '../features_app/dashboard/Scraping/master_indikator/masterIndikatorSlice'
import getScrapingHouseReducer from '../features_app/dashboard/Scraping/scraping_house/scrapingHouseSlice'
import getScrapingLandReducer from '../features_app/dashboard/Scraping/scraping_land/scrapingLandSlice'
import getScrapingHouseProceedReducer from '../features_app/dashboard/Scraping/followup_house/followUpHouseSlice'
import getScrapinApartmentReducer from '../features_app/dashboard/Scraping/scraping_apartment/scrapingApartmentSlice'
import getScrapingApartmentProceedReducer from '../features_app/dashboard/Scraping/followup_apartment/followUpApartmentSlice'
import getUserScrapingReducer from '../features_app/dashboard/Scraping/agents/userAgentsSlice'
import getStatisticReducer from '../features_app/dashboard/statistic/statisticSlice'
import getWithdrawReducers from '../features_app/dashboard/withdraw/withdrawSlice'
import getBlogReducers from '../features_app/dashboard/Blog/blogSlice'
import getSurveyReducers from '../features_app/dashboard/Survey/surveySlice'
import getHomeStatisticReducer from '../features_app/dashboard/home/redux/homeSlices'
import getBicheckingReducer from '../features_app/dashboard/BiChecking/biCheckingSlice'
import getCarikanPropertiReducer from '../features_app/dashboard/CarikanProperti/carikanPropertiSlice'
import getLeads from '../features_app/dashboard/Leads/leadsSlice'


export const store = configureStore({
  reducer: {
    login: loginReducer,
    forgot : forgotReducer,
    register: registerReducer,
    step_state : stepFormReducer,
    products : getProductsReducer,
    profile : getProfileReducer,
    setting: getSettingCompany,
    completing_register : completingRegisterReducer,
    property_type : getPropertyTypeReducer,
    property_title : getPropertyTitleReducer,
    extra_facilities : getExtraFacilitiesReducer,
    user_agents : getUserAgentsReducer,
    user_buyers : getUserBuyersReducer,
    user_admins : getUserAdminsReducer,
    user_referral : getUserReferralReducer,
    indikator : getMasterIndikatorReducer,
    scraping_house : getScrapingHouseReducer,
    scraping_land : getScrapingLandReducer,
    proceed_house : getScrapingHouseProceedReducer,
    scraping_apartment : getScrapinApartmentReducer,
    proceed_apartment : getScrapingApartmentProceedReducer,
    user_scraping : getUserScrapingReducer,
    statistic : getStatisticReducer,
    withdraw : getWithdrawReducers,
    blogs : getBlogReducers,
    survey : getSurveyReducers,
    home : getHomeStatisticReducer,
    bichecking : getBicheckingReducer,
    leads : getLeads,
    carikan_properti : getCarikanPropertiReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
