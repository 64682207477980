import { createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios'
import { userCredentials } from '../../../../utilities/config';

export const getAllBiChecking = createAsyncThunk(
    'BiChecking-get/fetch', 
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await Axios.get(`${process.env.REACT_APP_API_DEV}/find-properties`, {
                headers : {
                    'Authorization' : `Bearer ${userCredentials.access_token}`,
                    'Secret': 'GitGr0perti2023@@'
                }
            })
            if(response.status === 200) { 
                return response.data.data
            } 
        } catch (error) {
            return rejectWithValue(error)
        }
})

export const updateBiChecking = createAsyncThunk(
    'BiChecking/update', 
    async (value : any, { rejectWithValue }) => {
        try {
            const response : any = await Axios.put(`${process.env.REACT_APP_API_DEV}/find-properties/status/${value.id}`, value.body, {
                headers : {
                    'Authorization' : `Bearer ${userCredentials.access_token}`,
                    'Secret': 'GitGr0perti2023@@'
                }
            })
            if(response.status === 200) { 
                return {data : true, message : "Success update BiChecking"}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})
