import { createSlice } from '@reduxjs/toolkit';
import {  createFilterArea, getAllFilterArea, getAllProperty,  getAllPropertyScraping,  getProductBySlug, postCreateProducts, removeProduct, updateProducts, updateSoldProducts, updateStatusProperty } from './reducers/productsReducers';
import swal from 'sweetalert'
// import { getAllUsersTeams } from './reducers/productsReducers';

const initialState: any = {
  data: [], 
  data_scraping: [],
  data_count : 0,
  count_listing: 0,
  loading_scraping: false,
  loading : false,
  loaded : false,
  success_create: false,
  loading_create: false,
  success_update: false,
  loading_update: false,
  success_remove: false,
  loading_remove: false,
  loading_sold : false,
  sold : false,
  product_detail: {},
  product_detail_exist : false,
  loading_product_detail : true,
  filter_area : [],
  loading_filter_area : false,
  create_filter_area : false,
  loading_create_filter_area : false,
};

export const getProductsReducer = createSlice({
  name: 'products',
  initialState, 
  reducers: {},
  extraReducers: (builder) => {
    builder

      // get all users
      .addCase(getAllPropertyScraping.pending, (state) => {
        state.loading_scraping = true;
        state.sold = false
      })
      .addCase(getAllPropertyScraping.fulfilled, (state, action:any) => {
        state.loading_scraping = false;
        state.data_scraping = action.payload.data;
        state.data_count = action.payload.count
      })
      .addCase(getAllPropertyScraping.rejected, (state, action : any) => {
        state.loading_scraping = false;
        swal("Error", `${action.payload}`, 'error')
      })
      
      // get all users
      .addCase(getAllProperty.pending, (state) => {
        state.loading = true;
        state.loaded = false
        state.sold = false
        state.success_remove = false
      })
      .addCase(getAllProperty.fulfilled, (state, action:any) => {
        state.loading = false;
        state.data = action.payload.data;
        state.count_listing = action.payload.count
        state.loaded = true
      })
      .addCase(getAllProperty.rejected, (state, action : any) => {
        state.loading = false;
        swal("Error", `${action.payload}`, 'error')
      })
      // get product by slug
      .addCase(getProductBySlug.pending, (state) => {
        state.loading_product_detail = true;
        state.product_detail_exist = false
        state.success_update_variant = false
        state.success_post_variant = false
      })
      .addCase(getProductBySlug.fulfilled, (state, action:any) => { 
        state.loading_product_detail = false;
        state.product_detail = action.payload.data;
        state.product_detail_exist = true
      })
      .addCase(getProductBySlug.rejected, (state, action : any) => {
        state.loading_product_detail = false;
        swal("Error", `${action.payload}`, 'error')
      })

     
      // create products
      .addCase(postCreateProducts.pending, (state) => {
        state.loading_create = true;
      })
      .addCase(postCreateProducts.fulfilled, (state, action:any) => {
        state.loading_create = false;
        state.success_create = action.payload.data;
        swal("Success", `${action.payload.message}`, 'success')
      })
      .addCase(postCreateProducts.rejected, (state, action : any) => {
        state.loading_create = false;
        swal("Error", `${action.payload}`, 'error')
      })

       // update products
      .addCase(updateProducts.pending, (state) => {
        state.loading_update = true;
      })
      .addCase(updateProducts.fulfilled, (state, action:any) => {
        state.loading_update = false;
        state.success_update = action.payload.data;
        swal("Success", `${action.payload.message}`, 'success')
      })
      .addCase(updateProducts.rejected, (state, action : any) => {
        state.loading_update = false;
        swal("Error", `${action.payload}`, 'error')
      })

       // update products sold
       .addCase(updateSoldProducts.pending, (state) => {
        state.loading_sold = true;
      })
      .addCase(updateSoldProducts.fulfilled, (state, action:any) => {
        state.loading_sold = false;
        state.sold = action.payload.data;
        swal("Success", `${action.payload.message}`, 'success')
      })
      .addCase(updateSoldProducts.rejected, (state, action : any) => {
        state.loading_sold = false;
        swal("Error", `${action.payload}`, 'error')
      })

       // update products
       .addCase(updateStatusProperty.pending, (state) => {
        state.loading_update = true;
        state.success_update = false
      })
      .addCase(updateStatusProperty.fulfilled, (state, action:any) => {
        state.loading_update = false;
        state.success_update = true;
        swal("Success", `${action.payload.message}`, 'success')
      })
      .addCase(updateStatusProperty.rejected, (state, action : any) => {
        state.loading_update = false;
        swal("Error", `${action.payload}`, 'error')
      })

     
       // remove products
       .addCase(removeProduct.pending, (state) => {
        state.loading_remove = true;
      })
      .addCase(removeProduct.fulfilled, (state, action:any) => {
        state.success_remove = true
        swal("Success", `${action.payload.message}`, 'success')
        state.loading_remove = false;
      })
      .addCase(removeProduct.rejected, (state, action : any) => {
        state.loading_remove = false;
        swal("Error", `${action.payload}`, 'error')
      })

      // get all filter area
      .addCase(getAllFilterArea.pending, (state) => {
        state.loading_filter_area = true;
      })
      .addCase(getAllFilterArea.fulfilled, (state, action:any) => {
        state.loading_filter_area = false;
        state.filter_area = action.payload.data;
      })
      .addCase(getAllFilterArea.rejected, (state, action : any) => {
        state.loading_filter_area = false;
        swal("Error", `${action.payload}`, 'error')
      })

      // /get create filter area
      .addCase(createFilterArea.pending, (state) => {
        state.loading_create_filter_area = true;
      })
      .addCase(createFilterArea.fulfilled, (state, action:any) => {
        state.loading_create_filter_area = false;
        state.create_filter_area = action.payload.data;
        swal("Success", `Success added new filter area!`, 'success')
      })
      .addCase(createFilterArea.rejected, (state, action : any) => {
        state.loading_create_filter_area = false;
        swal("Error", `${action.payload}`, 'error')
      })


  },
});


export default getProductsReducer.reducer;
