import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';


export const getAllProperty = createAsyncThunk(
    'get/property',
    async (data:any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_DEV}/house-list/all`, data.body, {
                params: {
                    perPage : data.params.limit,
                    page : data.params.page,
                    status : data.params.status,
                    agent_id : data.params.agent_id,
                    area : data.params.area,
                }
            })
            if(response.data.errors === null) {
                let message = response.data.message
                return {data : response.data.data.data, count : response.data.data.document_count, message : message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            if (!err.response) {
              throw err
            }
            return rejectWithValue(err.response)
        }
    }
);

export const getAllFilterArea = createAsyncThunk(
    'get/filter-area',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_DEV}/area-master/filter/auto-post`)
            if(response.data.errors === false) {
                let message = response.data.message
                return {data : response.data.data, message : message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            if (!err.response) {
              throw err
            }
            return rejectWithValue(err.response)
        }
    }
);

export const createFilterArea = createAsyncThunk(
    'post/filter-area',
    async (data : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_DEV}/area-master/${data.id}/filter/auto-post`, data.body)
            if(response.data.errors === false) {
                let message = response.data.message
                return {data : true, message : message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            if (!err.response) {
              throw err
            }
            return rejectWithValue(err.response)
        }
    }
);

export const getAllPropertyScraping = createAsyncThunk(
    'get/property-scraping',
    async (data:any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_DEV}/house-master/auto-post`, {
                params: { 
                    area_id : data.area_id,
                    perPage : data.limit,
                    page : data.page,
                    type : 'rumah'
                }}
            )
            if(response.data) { 
                return {data : response.data.data.data, count : response.data.data.document_count, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            if (!err.response) {
              throw err
            }
            return rejectWithValue(err.response)
        }
    }
);

export const getProductBySlug = createAsyncThunk(
    'product-slug/fetch', 
    async (slug: string, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_DEV}/house-list/by/${slug}`)
            if(response.data.errors === null) { 
                return {data : response.data.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})


export const postCreateProducts = createAsyncThunk(
    'product/post',
    async (body : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_DEV}/house-list`, body)
            if(response.data.errors === null) {
                return {data : response.data.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
            } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const updateProducts = createAsyncThunk(
    'property/update',
    async (value : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.put(`${process.env.REACT_APP_API_DEV}/house-list/${value.id}`, value.body)
            if(response.data.errors === null) {
                return {data : true , message : "Success updated property."}
            } else {
                return rejectWithValue(response.data.message)
            }
            } catch (err : any) {
            return rejectWithValue(err)
        } 
    }
);

export const updateSoldProducts = createAsyncThunk(
    'property-sold/update',
    async (value : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.put(`${process.env.REACT_APP_API_DEV}/house-list/${value.id}`, value.body)
            if(response.data.errors === null) {
                return {data : true , message : "Success updated sold property."}
            } else {
                return rejectWithValue(response.data.message)
            }
            } catch (err : any) {
            return rejectWithValue(err)
        } 
    }
);


export const updateStatusProperty = createAsyncThunk(
    'property-status/update',
    async (value : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.put(`${process.env.REACT_APP_API_DEV}/house-list/${value.id}`, value.body)
            if(response.data.errors === null) {
                return {data : response.data.data, message : "Success updated status property."}
            } else {
                return rejectWithValue(response.data.message)
            }
            } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const updateVerifyProperty = createAsyncThunk(
    'property-status/update',
    async (value : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.put(`${process.env.REACT_APP_API_DEV}/house-list/${value.id}`, value.body)
            if(response.data.errors === null) {
                return {data : response.data.data, message : "Success updated status property."}
            } else {
                return rejectWithValue(response.data.message)
            }
            } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const removeProduct = createAsyncThunk(
    'product/remove', 
    async (id: string, { rejectWithValue }) => { 
        try {
            const response : any = await axios.delete(`${process.env.REACT_APP_API_DEV}/house-list/${id}`)
            if(response.data.errors === null) { 
                return {data : true, message : "Success deleted property."}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})

