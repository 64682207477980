import axios from 'axios'
import swal from 'sweetalert'

export const uploadFileToS3 = async (file: File,  folder: string) => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('folder', folder)
    
    try {
        const response : any = await axios.post(`https://api-upload.groperti.com/upload`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
    if(response.data.error === null) {
        return response.data.publicUrl
    }
    } catch (error:any) {
        swal('Error', error, 'error')
    }
}