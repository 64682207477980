import { createSlice } from '@reduxjs/toolkit';
import swal from 'sweetalert';
import { getAllHouseType, postHouseType, updateHouseType } from './reducers/propertyTypeReducers';


const initialState: any = {
  data: [],
  loading : false,
  create : false,
  loading_create : false,
  
  update : false,
  loading_update : false,
};

export const propertyTypeSlice = createSlice({
  name: 'house-type',
  initialState,
  reducers: {},
  extraReducers: {
    //   fetch type
    [getAllHouseType.pending.type] : (state) => {
        state.loading = true
        state.create = false
        state.update = false
    },
    [getAllHouseType.fulfilled.type] : (state, action) => {
        state.loading = false
        state.data = action.payload.data
    },
    [getAllHouseType.rejected.type] : (state, action) => {
        state.loading = false
        swal('Error', `${action.payload}`, 'error')
    },
    // create type
    [postHouseType.pending.type] : (state) => {
        state.loading_create = true
    },
    [postHouseType.fulfilled.type] : (state, action) => {
        state.loading_create = false
        state.create = true
        swal('Success', `${action.payload.message}`, 'success')
    },
    [postHouseType.rejected.type] : (state, action) => {
        state.loading_create = false
        swal('Error', `${action.payload}`, 'error')
    },
    // update data
    [updateHouseType.pending.type] : (state) => {
      state.loading_update = true
    },
    [updateHouseType.fulfilled.type] : (state, action) => {
        state.loading_update = false
        state.update = true
        swal('Success', `${action.payload.message}`, 'success')
    },
    [updateHouseType.rejected.type] : (state, action) => {
        state.loading_update = false
        swal('Error', `${action.payload}`, 'error')
    },
  }
});

export default propertyTypeSlice.reducer;
