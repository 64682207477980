import { createSlice } from '@reduxjs/toolkit';
import swal from 'sweetalert'
import { getHomeStatistic } from './homeReducers';

const initialState: any = {
  stastics: {},
  loading : false,
};

export const homeStatisticSlice = createSlice({
  name: 'statistic',
  initialState, 
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get profile
      .addCase(getHomeStatistic.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHomeStatistic.fulfilled, (state, action:any) => {
        state.loading = false;
        state.statistic = action.payload;
      })
      .addCase(getHomeStatistic.rejected, (state, action : any) => {
        state.loading = false;
        swal("Error", `${action.payload}`, 'error')
      })
  },
});

export default homeStatisticSlice.reducer;
