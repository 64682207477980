import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../app/store'
import BreadCrumbs from '../../../components/BreadCrumbs'
import { getProfileCompany } from './reducers/profilesReducers'
import Box from '@mui/material/Box';
import UserInfo from './components/UserInfo'
import { Paper } from '@mui/material'


function ProfilePage() {

    const dispatch = useDispatch()
    const store_profile = useSelector((state : RootState) => state.profile)

    useEffect(() => {
        dispatch(getProfileCompany())
        // eslint-disable-next-line
    }, []);

    return (
        <Box sx={{pt:2, pl:3, pr:3}}>
            <BreadCrumbs 
                isPage={false}
                current="Profile Page"
            />
            <Box sx={{pt:2, pb:2}}>
                <h3>Profile Information</h3>
            </Box>

            <Box sx={{ width: '100%' }}>
               <Paper elevation={3}>
                <Box p={3}>
                <UserInfo
                        data={store_profile}
                    /> 
                </Box>
               </Paper>
            </Box>
        </Box>
    )
}

export default ProfilePage
