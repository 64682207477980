import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getHomeStatistic = createAsyncThunk(
    'statistic/get',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_DEV}/house-list/admin-statistic`)
            if(response.data.errors === null) {
                return response.data.data
            } 
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);
