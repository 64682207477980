import { createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios'

export const getAllScrapingApartment = createAsyncThunk(
    'apartment-scraping/fetch', 
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await Axios.get(`${process.env.REACT_APP_API_DEV}/house-master`, {
                params: {
                  property_type : "apartemen",
                }}
            )
            if(response.data) { 
                return {data : response.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})

