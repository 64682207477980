import { createAsyncThunk } from '@reduxjs/toolkit';
import crypto from 'crypto-js'; 
import Axios from 'axios'
import {
    InputState
} from '../loginTypes'

function checkRole (role:string, roles:any) {
    return roles.includes(role)
}

export const loginAction = createAsyncThunk(
    'auth/login',
    async (value : InputState , { rejectWithValue }) => {
 
        const body = {
            email : value.email,
            password : value.password,
            last_login_location  : ""
        }
        try {
            const response : any = await Axios.post(`${process.env.REACT_APP_API_AUTH}/api/login`, body)
            if(response.status === 200) {
                if(checkRole('gro_admin', response?.data?.flag)) {
                    let data = {
                        access_token : response.data.token,
                        id_token : response.data.token, 
                        email :response?.data?.email,
                        name : response?.data?.name, 
                        flag : response?.data?.flag, 
                        avatar : "",
                        auth_id : response?.data?.auth_id,
                        first_time : false,
                        login: true
                    }
                    const saveToLocalStorage = crypto.AES.encrypt(JSON.stringify(data), `${process.env.REACT_APP_CRYPTO_SECRET}`).toString();
                    localStorage.setItem('_?GROcredentials', saveToLocalStorage)
                    return data
                } else {
                    return rejectWithValue("You are not allowed, because your account is not ADMIN")
                }
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            if (!err.response) {
              throw err
            }
            return rejectWithValue(err.response.data.errors || "Internal server error")
        }
    }
  );
