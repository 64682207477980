import { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import BreadCrumbs from '../../../components/BreadCrumbs';
import TableData from '../../../components/TableData'
import { TableColumn } from 'react-data-table-component';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import {  useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../app/store';
import ImagesItems from '../products/create/components/ImagesItems';
import { createNewBlog, getAllBlogs, updateBlog } from './reducers/blogReducers';
import React from 'react'

let images_items = [
    {
        image : ""
    }
]

    
function Blogs() {
    const dispatch = useDispatch()
    const { data, loading, create, loading_create, update, loading_update, remove } = useSelector((state : RootState) => state.blogs)
    
    const [imagesItems, setImagesItems] = useState<any>(images_items);


    const [dataBlog, setdataBlog] = useState({
        open : false,
        name : "",
        id : "",
        title : "",
        url_blog : "",
        category : ""
    });

    const [limit, setLimit] = useState(15);
    const [page, setPage] = useState(1);

    const handleClickOpen = (name : string) => {
        setdataBlog({...dataBlog, open : true, name : name });
    };

    const handleClose = () => {
       setdataBlog({...dataBlog, 
            open : false, 
            name : "",
            id : "",
            title : "",
            url_blog : "",
            category : ""
        });
        setImagesItems(images_items);
    };

    const onSubmit = (e: any): void => {
        e.preventDefault()

        let data_send = {
            id : dataBlog.id,
            body : {
                title : dataBlog.title,
                url_blog : dataBlog.url_blog,
                category : dataBlog.category,
                images : imagesItems[0].image
            }
        }
       
        if(dataBlog.name === "Edit") {
            dispatch(updateBlog(data_send))
        } else {
            dispatch(createNewBlog(data_send.body))
        }
    }

    const columns: TableColumn<any>[] = [
        {
            name: 'NO',
            width: '80px',
            cell: (row, index) => (
                <p>
                { page > 1 ? 
                    <>
                    {index + 1 + (limit * (page-1))}
                    </>
                    :
                    <> 
                    {index + 1}
                    </>
                }
                </p>
            )
        },
        {
            name: 'Title',
            cell: (row) => (
                <div>
                    <a href={row.url_blog} target="_blank" rel="noopener noreferrer">
                    {row.title}
                    </a>
                </div>
            )
        },
        {
            name: 'Category',
            cell: (row) => (
                <div>{row.category}</div>
            )
        },
        {
            name: 'ACTION',
            width: '200px',
            cell: (row ) => (
                <Stack direction="row" spacing={2}>
                    <Button 
                        variant="outlined" color="primary" size="small"
                        onClick={() => {
                            setImagesItems([{image : row.images[0] || ""}])
                            setdataBlog({
                                ...dataBlog, 
                                open : true, name : "Edit", id : row._id, title : row.title, url_blog : row.url_blog, category : row.category
                            })
                        }}
                    >
                     Edit
                    </Button>
                </Stack>
            ),
        },
    ];
    
    useEffect(() => {
        handleClose()
        dispatch(getAllBlogs())
        // dispatch(getAllHouseTitle())
        // eslint-disable-next-line
    },  [create, update, remove]);



    return (
        <div>
            <Box pb={2}>
                <BreadCrumbs
                    current="Blogs"
                    isPage={false}
                />
            </Box>
            <Stack direction="row" justifyContent="space-between" spacing={2}>
                <h3>Blogs</h3>
                <Button 
                    variant="contained" color="primary" size="small" 
                    onClick={() => {
                        handleClickOpen("Create")
                    }}
                >
                    Create New
                </Button> 
            </Stack>
            <Box pt={4}>
                <TableData 
                    columns={columns}
                    data={data}
                    progressPending={loading}
                    pagination
                    onChangePage={(value:any) => setPage(value)}
                    onChangeRowsPerPage={(value:any) => setLimit(value)}
                    paginationPerPage={limit}
                />
            </Box>

            <Dialog 
                open={dataBlog.open} 
                fullWidth={true}
                maxWidth="sm"
            >
                <form onSubmit={onSubmit} >
                    <DialogTitle>{dataBlog.name} Blog</DialogTitle>
                    <div className="box-modal-create">
                        <Box >Title</Box>
                        <TextField
                            margin="dense"
                            size="small"
                            fullWidth
                            id="name"
                            placeholder='Title Blog'
                            name="title"
                            type="text"
                            value={dataBlog.title}
                            onChange={(e) => setdataBlog({...dataBlog, title : e.target.value})}
                        />
                        <Box pt={1}>Category</Box>
                        <TextField
                            margin="dense"
                            size="small"
                            fullWidth
                            id="name"
                            placeholder='Category'
                            name="category"
                            type="text"
                            value={dataBlog.category}
                            onChange={(e) => setdataBlog({...dataBlog, category : e.target.value})}
                        />
                        <Box pt={1}>Url Wordpress</Box>
                        <TextField
                            margin="dense"
                            size="small"
                            fullWidth
                            id="name"
                            placeholder='Url Wordpress'
                            name="url_blog"
                            type="text"
                            value={dataBlog.url_blog}
                            onChange={(e) => setdataBlog({...dataBlog, url_blog : e.target.value})}
                        />
                        <Box pt={2}>
                            <Box>Image Blog</Box>
                            <ImagesItems 
                                imagesItems={imagesItems}
                                setImagesItems={setImagesItems}
                                addmore={true}
                            />
                        </Box>
                    </div>
                    <DialogActions>
                        <Button onClick={handleClose} color="warning">Cancel</Button>
                        
                        { dataBlog.name === "Edit" ?
                        <Button type="submit" variant="outlined">{ loading_update ? "Loading.." : "Save" }</Button> :
                        <Button type="submit" variant="outlined">{ loading_create ? "Loading.." : "Submit" }</Button> }
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    )   
}

export default Blogs
