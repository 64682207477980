import { useEffect } from 'react';
import {
    Stack,
    Container,
    CssBaseline,
    Box,
    TextField,
    Button, 
    Grid, 
    Link, 
    CircularProgress 
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../app/store';
import { registerAction } from './reducers/registerReducers';
import { UserFormSubmit } from './registerTypes'
import { useState } from 'react';

const Register = () => {
  const dispatch = useDispatch()
  const register_store = useSelector((state : RootState) => state.register )
  const [data, setData] = useState({
    email: "",
    password : "",
    fullname : ""
  });
  
  
  const onSubmit = (e : any): void => {
    e.preventDefault()
    dispatch(registerAction(data))

  }

  function onChange(e:any) {
    setData({...data, [e.target.name] : e.target.value})
  }

   /* istanbul ignore next */
   useEffect(() => {
    if(register_store.register) {
      setTimeout(() => {
          window.location.href = "/"
        }, 1000);
    }
  }, [register_store.register]);

  
  return (
    <>
      
      <div>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 5,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box color="secondary">
              <Stack flexDirection="column" alignItems="center">
              <h1>Pre Register</h1>
              <p>Daftar sebagai agen properti di Groperti</p>
              </Stack>
            </Box>

            <Box sx={{ mt: 1 }}>
            <form onSubmit={onSubmit}>
              <TextField
                margin="normal"
                fullWidth
                id="fullname"
                label="Full Name"
                name="fullname"
                autoComplete="fullname"
                onChange={onChange}
                value={data.fullname}
                required
              />
              <TextField
                margin="normal"
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={onChange}
                value={data.email}
                required
              />
              <TextField
                margin="normal"
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                onChange={onChange}
                value={data.password}
                required
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                size="medium"
                color="primary"
              > 
                Daftar
                
                { /* istanbul ignore next */
                  register_store.loading && <CircularProgress  size={30} color="inherit" style={{marginLeft: 10}} /> }
              </Button>
              <Grid container justifyContent="space-between">
                <Grid item>
                  {"Sudah punya akun? "}
                  <Link href="/">
                    Masuk
                  </Link>
                </Grid>
              </Grid>
            </form>
            </Box>
          </Box>
        </Container>
      </div>
    </>
    )
}

export default Register;
