import { Grid, Paper, Stack } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootState } from '../../../app/store'
import BreadCrumbs from '../../../components/BreadCrumbs'
import LoaderBackdrop from '../../../components/LoaderBackdrop'
import { getStatisticDataArea } from './reducers/statisticReducers'


function StatisticDetail() {
    const dispatch = useDispatch()
    const params : any = useParams()

    const {data_area, loading_area} = useSelector((state : RootState) => state.statistic)

    useEffect(() => {
        const dataFilter = {
            location : params.name,
        }
        dispatch(getStatisticDataArea(dataFilter))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    return (
        <Box sx={{pl:3, pr:3, pt:2}}>
            <Box pb={2}>
                <BreadCrumbs 
                current='Detail Scraping'
                isPage={true}
                page="Statistic"
                link="/dashboard/statistic"
                />
            </Box>
           <h3>Detail Wilayah Scraping</h3>

            { loading_area ? 
            <LoaderBackdrop loading={loading_area} /> :
            <Box pt={2}>
                <Stack sx={{pb:2}}>
                    {params.name}
                </Stack>
                <Stack>
                    <Grid container spacing={3}>
                        { data_area && data_area.map((item:any, idx:any) => (
                            <Grid item xl={3} lg={3} sm={6} md={6} xs={6} key={idx}>
                                <Paper>
                                    <Stack sx={{p:2}} flexDirection="row" justifyContent="space-between" alignItems="center">
                                        <Box fontSize={13} pr={1} >
                                            {item.name}
                                        </Box>
                                        <Box fontSize={30} color="#0091d6">
                                            {item.value_fair_price.toLocaleString()} 
                                        </Box> 
                                        <Box fontSize={30} color="red">
                                            {item.value_over_price.toLocaleString()} 
                                        </Box> 
                                    </Stack>
                                </Paper>
                            </Grid>
                        )) }
                    </Grid>
                </Stack>
            </Box>
            }
        </Box>
    )
}

export default StatisticDetail
